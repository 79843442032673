import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const LegalMentionPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("meta.title.legal_mention")}</title>
        <meta name="title" content={t("meta.title.legal_mention")} />
        <meta
          name="description"
          content={t("meta.description.legal_mention")}
        />
      </Helmet>
      <div>
        <div className="page-header">
          <div className="container">
            <h1>{t("legal_mentions.title.1")}</h1>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="page-section">
              <h2>{t("legal_mentions.title.2")}</h2>

              <p>{t("legal_mentions.paragraph.1")}</p>

              <p>{t("legal_mentions.paragraph.2")}</p>

              <p>{t("legal_mentions.paragraph.3")}</p>

              <p>{t("legal_mentions.paragraph.4")}</p>

              <p>{t("legal_mentions.paragraph.5")}</p>

              <p>{t("legal_mentions.paragraph.6")}</p>

              <p>{t("legal_mentions.paragraph.7")}</p>

              <p>{t("legal_mentions.paragraph.8")}</p>

              <p>{t("legal_mentions.paragraph.9")}</p>

              <p>{t("legal_mentions.paragraph.10")}</p>
            </div>
            <div className="page-section">
              <h2>{t("legal_mentions.title.3")}</h2>

              <h3>{t("legal_mentions.title.4")}</h3>

              <p>{t("legal_mentions.paragraph.11")}</p>

              <h3>{t("legal_mentions.title.5")}</h3>

              <p>{t("legal_mentions.paragraph.12")}</p>

              <p>{t("legal_mentions.paragraph.13")}</p>

              <p>{t("legal_mentions.paragraph.14")}</p>

              <ul>
                <li>{t("legal_mentions.paragraph.forbidden.1")}</li>
                <li>{t("legal_mentions.paragraph.forbidden.2")}</li>
              </ul>

              <h3>{t("legal_mentions.title.6")}</h3>

              <p>{t("legal_mentions.paragraph.15")}</p>

              <p>{t("legal_mentions.paragraph.16")}</p>

              <p>{t("legal_mentions.paragraph.17")}</p>
            </div>

            <div className="page-section">
              <h2>{t("legal_mentions.title.7")}</h2>

              <h3>{t("legal_mentions.title.8")}</h3>

              <p>{t("legal_mentions.paragraph.18")}</p>

              <p>{t("legal_mentions.paragraph.19")}</p>

              <p>{t("legal_mentions.paragraph.20")}</p>

              <p>{t("legal_mentions.paragraph.21")}</p>

              <p>{t("legal_mentions.paragraph.22")}</p>

              <h3>{t("legal_mentions.title.9")}</h3>

              <p>{t("legal_mentions.paragraph.23")}</p>

              <p>{t("legal_mentions.paragraph.24")}</p>

              <h3>{t("legal_mentions.title.10")}</h3>

              <p>
                {t("legal_mentions.paragraph.illegal_content.1")}
                <NavLink
                  to={`/${i18n.language}/${t("routes.contact_us_route")}`}
                >
                  {t("legal_mentions.paragraph.illegal_content.2")}
                </NavLink>{" "}
                {t("legal_mentions.paragraph.illegal_content.3")}
              </p>

              <h3>{t("legal_mentions.title.11")}</h3>

              <p>
                {t("legal_mentions.paragraph.signal_content.1")}
                <NavLink
                  to={`/${i18n.language}/${t("routes.contact_us_route")}`}
                >
                  {t("legal_mentions.paragraph.signal_content.2")}
                </NavLink>{" "}
                {t("legal_mentions.paragraph.signal_content.3")}
              </p>
            </div>

            <div className="page-section">
              <h2>{t("legal_mentions.title.12")}</h2>

              <p>{t("legal_mentions.paragraph.25")}</p>

              <p>{t("legal_mentions.paragraph.26")}</p>

              <p>{t("legal_mentions.paragraph.27")}</p>

              <p>{t("legal_mentions.paragraph.28")}</p>

              <p>{t("legal_mentions.paragraph.29")}</p>

              <p>{t("legal_mentions.paragraph.30")}</p>

              <p>{t("legal_mentions.paragraph.31")}</p>

              <p>{t("legal_mentions.paragraph.32")}</p>

              <ul>
                <li>{t("legal_mentions.paragraph.dont_use_for.1")}</li>
                <li>{t("legal_mentions.paragraph.dont_use_for.2")}</li>
                <li>{t("legal_mentions.paragraph.dont_use_for.3")}</li>
                <li>{t("legal_mentions.paragraph.dont_use_for.4")}</li>
                <li>{t("legal_mentions.paragraph.dont_use_for.5")}</li>
                <li>{t("legal_mentions.paragraph.dont_use_for.6")}</li>
                <li>{t("legal_mentions.paragraph.dont_use_for.7")}</li>
                <li>{t("legal_mentions.paragraph.dont_use_for.8")}</li>
                <li>{t("legal_mentions.paragraph.dont_use_for.9")}</li>
                <li>{t("legal_mentions.paragraph.dont_use_for.10")}</li>
              </ul>
              <p>{t("legal_mentions.paragraph.33")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalMentionPage;
