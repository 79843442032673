import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import CookieConsent, {Cookies} from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import {COOKIE_CONSENT_KEY} from "../../core/Constants";
import {connect} from "react-redux";
import {setCookieIsAccepted} from "../../actions/installPromptActions";

const CookieOverlay = ({setCookieIsAccepted, cookieIsAccepted}) => {
    const {t, i18n} = useTranslation();
    const [cookieConsent, setCookieConsent] = useState(Cookies.get(COOKIE_CONSENT_KEY));

    useEffect(() => {
        if (cookieConsent !== cookieIsAccepted && cookieConsent) {
            setCookieIsAccepted(cookieConsent === 'true');
        }
    }, [cookieConsent]);

    const handleOnClick = (event) => {
        event.preventDefault();
        Cookies.set(COOKIE_CONSENT_KEY, true, {expires: 30});
        setCookieConsent(true);
        setCookieIsAccepted(true);
    };

    return (
        <>
            {!cookieConsent &&
            <CookieConsent containerClasses="cookie-overlay" buttonStyle={{display: 'none'}} disableStyles={true}
                           disableButtonStyles={true}
                           contentClasses={"container"}>
                <div className="cookie-overlay-content">
                    <h2>{t('cookie_banner.title')}</h2>
                    <p>
                        {t('cookie_banner.text.1')}
                        <NavLink
                            to={`/${i18n.language}/${t('routes.privacy_route')}`}>{t('cookie_banner.link.1')}</NavLink>
                        {t('cookie_banner.text.2')}
                        <NavLink
                            to={`/${i18n.language}/${t('routes.legal_mention_route')}`}>{t('cookie_banner.link.2')}.</NavLink>
                    </p>
                </div>
                <button onClick={handleOnClick}>{t('cookie_banner.button.text')}</button>
            </CookieConsent>}
        </>
    );
};

const mapStateToProps = state => ({cookieIsAccepted: state.installPrompt.cookieIsAccepted});

const mapDispatchToProps = {setCookieIsAccepted}

export default connect(mapStateToProps, mapDispatchToProps)(CookieOverlay);