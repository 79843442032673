import React from 'react';
import {Enums} from "../../core";
import {useTranslation} from "react-i18next";

const DecodeurContent = () => {
    const {t} = useTranslation();
    return (
        <>
            <p>
                <a target="_blank" rel="noopener noreferrer" href="https://www.lemonde.fr/les-decodeurs/">
                    {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.content.1`)}
                </a>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.content.2`)}
                <a target="_blank" rel="noopener noreferrer" href="https://fr.wikipedia.org/wiki/Le_Monde">
                    {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.content.3`)}
                </a>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.content.4`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.content.5`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.content.6`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.content.7`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.content.8`)}
            </p>
            <p>
                <small>
                    (1) <a href="https://fr.wikipedia.org/wiki/Les_D%C3%A9codeurs" target="_blank"
                           rel="noopener noreferrer">Wikipedia https://fr.wikipedia.org/wiki/Les_D%C3%A9codeurs</a>
                </small>
                <small>
                    (2) <a
                    href="https://www.lemonde.fr/les-decodeurs/article/2014/03/10/la-charte-des-decodeurs_4365106_4355770.html"
                    target="_blank"
                    rel="noopener noreferrer">https://www.lemonde.fr/les-decodeurs/article/2014/03/10/la-charte-des-decodeurs_4365106_4355770.html</a>
                </small>
            </p>
        </>
    );
};

export default DecodeurContent;