import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Loader} from "../common";
import {isEdge, isFirefox, isIE, isIOS} from "react-device-detect";

const SearchFieldArticleForm = ({
                                    handleOnclick,
                                    text,
                                    handleOnChange,
                                    className,
                                    errors,
                                    handleKeyDown,
                                    loading,
                                    handleOnClickClipboard,
                                    disableSearch
                                }) => {
    const {t} = useTranslation();
    const moveCaretAtEnd = (e) => {
        var temp_value = e.target.value
        e.target.value = ''
        e.target.value = temp_value
    };

    return (
        <form id="article-form" className={className}>
            <div className="form-label">
                <label>{t("search_field.label")}</label>
                {(!isIOS && !isFirefox && !isIE && !isEdge) &&
                <button className="button-link" onClick={handleOnClickClipboard}>
                    <span>{t("search_field.copy_url")}</span>
                </button>}
            </div>
            <div className="form-field">
                <textarea
                    id="search-field"
                    value={text}
                    name="text"
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                    placeholder={t("search_field.placeholder")}
                    autoFocus
                    onFocus={moveCaretAtEnd}/>
            </div>
            <div className="form-actions">
                <div className="errors">
                    <span className="error">{errors}</span>
                </div>
                <input
                    type="submit"
                    value={t("search_field.button.text")}
                    onClick={handleOnclick}
                    className={disableSearch ? "disabled" : ""}
                />
            </div>
            <Loader loading={loading}/>
        </form>
    );
};

SearchFieldArticleForm.propTypes = {
    handleOnclick: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    handleOnChange: PropTypes.func.isRequired,
    handleOnClickClipboard: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    errors: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    disableSearch: PropTypes.bool
};

export default SearchFieldArticleForm;
