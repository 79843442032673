import React from 'react';
import PropTypes from 'prop-types';
import {SubjectListRow} from "./";
import {CSSTransition, TransitionGroup} from "react-transition-group";

const SubjectList = ({subjects, source}) => {
    return (
        <div className="articles">
            <TransitionGroup component={null}>
                {subjects && subjects.map((subject, index) =>
                    <CSSTransition
                        key={subject.id}
                        classNames="fade"
                        timeout={{enter: 500, exit: 0}}>
                        <SubjectListRow key={index} subject={subject} source={source}/>
                    </CSSTransition>)}
            </TransitionGroup>
        </div>
    );
};

SubjectList.propTypes = {
    subjects: PropTypes.array.isRequired
};

export default SubjectList;