import * as types from './actionTypes';
import {SubjectApi} from "../api";
import {getSubjectsSearchTagsSuccess} from "./tagActions";
import {Enums} from "../core";
import {StatisticsApi} from '../api';
import {setSearchButtonDisabled} from './importSubjectActions';
import {ErrorService} from "../services";
import * as ReactGA from "react-ga";

export const loadSubjectsSuccess = (subjects, scope, subjectsCount, page = 1) => {
    return {
        type: types.GET_SUBJECTS_SUCCESS,
        payload: {
            elements: subjects,
            searchName: scope,
            page: page,
            elementsCount: subjectsCount
        }
    };
};

export const beginGetSubjects = (scope, page = 1) => {
    return {
        type: types.BEGIN_GET_SUBJECTS,
        payload: {
            searchName: scope,
            page: page
        }
    };
};


export const searchSubjects = (request) => {
    return dispatch => {
        const _scope = request.text !== '' ? Enums.Scopes.SearchText + '_' + request.text : Enums.Scopes.SearchUrl + '_' + request.url;
        dispatch(beginGetSubjects(_scope, request.searchOptions.pageNumber));
        return SubjectApi.searchSubjects(request).then(result => {
            if (result.success) {
                let target = request.text === '' ? request.url : request.text,
                    action = request.text === '' ? 'Search by URL' : 'Search by text',
                    type = request.text === '' ? 'URL' : 'Text'
                    , subjectsIds = result.subjects.map(a => a.id);

                StatisticsApi.addStatistic(type, action, target, subjectsIds);

                dispatch(getSubjectsSearchTagsSuccess(result.tags));
                result.tags.forEach((keyword) => {
                    ReactGA.event({
                        category: 'keyword',
                        action: 'search',
                        label: keyword
                    });
                });


                dispatch(loadSubjectsSuccess(result.subjects, _scope, result.subjectsCount, request.searchOptions.pageNumber));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.GET_SUBJECTS_ERROR, _scope, request.searchOptions.pageNumber);
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.GET_SUBJECTS_ERROR, _scope, request.searchOptions.pageNumber);
            throw(error);
        });
    };
};

export const getLatestSubjects = () => {
    return dispatch => {
        let _scope = Enums.Scopes.SearchLatest;
        dispatch(beginGetSubjects(_scope));
        return SubjectApi.getLatestSubject().then(result => {
            if (result.success) {
                dispatch(loadSubjectsSuccess(result.subjects, _scope, result.subjects.length));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.GET_SUBJECTS_ERROR, _scope);
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.GET_SUBJECTS_ERROR, _scope);
            throw(error);
        });
    };
};

export const getSubjectDetails = (id) => {
    return dispatch => {
        let _scope = Enums.Scopes.SearchDetail + '_' + id;
        dispatch(beginGetSubjects(_scope, 1));
        return SubjectApi.getSubjectDetails(id).then(result => {
            if (result.success) {
                let _subjects = [];
                _subjects.push(result.subject);
                dispatch(loadSubjectsSuccess(_subjects, _scope, 1));
                dispatch(getSimilarSubjects(id, {
                    tags: [...result.subject.tags],
                    searchOptions: {pageSize: 3, pageNumber: 1}
                }));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.GET_SUBJECTS_ERROR, _scope);
            }
            dispatch(setSearchButtonDisabled(false));
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.GET_SUBJECTS_ERROR, _scope);
            throw(error);
        });
    };
};

export const getSimilarSubjects = (id, request) => {
    return dispatch => {
        let _scope = Enums.Scopes.SearchSimilar + '_' + id;
        dispatch(beginGetSubjects(_scope, request.searchOptions.pageNumber));
        return SubjectApi.getSimilarSubjects(id, request).then(result => {
            if (result.success) {
                dispatch(loadSubjectsSuccess(result.subjects, _scope, result.subjectsCount, request.searchOptions.pageNumber));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.GET_SUBJECTS_ERROR, _scope, request.searchOptions.pageNumber);
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.GET_SUBJECTS_ERROR, _scope, request.searchOptions.pageNumber);
            throw(error);
        });
    };
};

export const cleanSearchFields = () => {
    return {
        type: types.CLEAN_SEARCH_FIELDS,
    };
};
