import React from "react";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const HowDoesItWorkPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("meta.title.how_does_it_work")}</title>
        <meta name="title" content={t("meta.title.how_does_it_work")} />
        <meta
          name="description"
          content={t("meta.description.how_does_it_work")}
        />
      </Helmet>
      <div>
        <div className="page-header">
          <div className="container">
            <h1>{t("how_works.title.1")}</h1>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="page-section">
              <p>{t("how_works.paragraph.1")}</p>
            </div>
            <div className="page-section">
              <h2>{t("how_works.title.2")}</h2>
              <p>{t("how_works.paragraph.2")}</p>
              <p>{t("how_works.paragraph.3")}</p>
              <p>
                {t("how_works.paragraph.4")}
                <NavLink
                  to={`/${i18n.language}/${t(
                    "routes.how_does_it_work_images_route"
                  )}`}
                >
                  https://www.faky.be/{i18n.language}/
                  {t("routes.how_does_it_work_images_route")}
                </NavLink>
              </p>
              <p>{t("how_works.paragraph.5")}</p>
            </div>
            <div className="page-section">
              <h2>{t("how_works.title.3")}</h2>
              <p>{t("how_works.paragraph.6")}</p>
              <p>{t("how_works.paragraph.7")}</p>
              <p>{t("how_works.paragraph.8")}</p>
              <p>{t("how_works.paragraph.9")}</p>
            </div>
            <div className="page-section">
              <h2>{t("how_works.title.4")}</h2>
              <p>{t("how_works.paragraph.10")}</p>
            </div>
            <div className="page-section">
              <h3>{t("how_works.title.5")}</h3>
              <NavLink
                to={`/${i18n.language}/${t(
                  "routes.our_sources_route"
                )}#decodex`}
              >
                {t("how_works.nav.1")}
              </NavLink>
              <p>{t("how_works.paragraph.11")}</p>
            </div>
            <div className="page-section">
              <h3>{t("how_works.title.6")}</h3>
              <NavLink
                to={`/${i18n.language}/${t(
                  "routes.our_sources_route"
                )}#decodeur`}
              >
                {t("how_works.nav.2")}
              </NavLink>
              <p>{t("how_works.paragraph.12")}</p>
            </div>
            <div className="page-section">
              <h3>{t("how_works.title.7")}</h3>
              <NavLink
                to={`/${i18n.language}/${t(
                  "routes.our_sources_route"
                )}#detecteurdesinformation`}
              >
                {t("how_works.nav.3")}
              </NavLink>
              <p>{t("how_works.paragraph.13")}</p>
            </div>
            <div className="page-section">
              <h3>{t("how_works.title.8")}</h3>
              <NavLink
                to={`/${i18n.language}/${t(
                  "routes.our_sources_route"
                )}#textgain`}
              >
                {t("how_works.nav.4")}
              </NavLink>
              <p>{t("how_works.paragraph.14")}</p>
            </div>

            <div className="page-section">
              <h3>{t("how_works.title.9")}</h3>
              <NavLink
                to={`/${i18n.language}/${t(
                  "routes.our_sources_route"
                )}#neutral`}
              >
                {t("how_works.nav.5")}
              </NavLink>
              <p>{t("how_works.paragraph.15")}</p>
            </div>

            <div className="page-section">
              <h2>{t("how_works.title.10")}</h2>
              <p>{t("how_works.paragraph.16")}</p>
              <p>{t("how_works.paragraph.17")}</p>
              <ul>
                <li>{t("how_works.paragraph.reasons.1")}</li>
                <li>{t("how_works.paragraph.reasons.2")}</li>
                <li>{t("how_works.paragraph.reasons.3")}</li>
                <li>{t("how_works.paragraph.reasons.4")}</li>
              </ul>
              <p>{t("how_works.paragraph.18")}</p>
              <ul>
                <li>{t("how_works.paragraph.irrelevant.1")}</li>
                <li>{t("how_works.paragraph.irrelevant.2")}</li>
              </ul>
              <p>
                <Trans i18nKey="how_works.paragraph.irrelevant.3" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowDoesItWorkPage;
