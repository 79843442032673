import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import {Loader} from "./index";
import {useTranslation} from "react-i18next";
import {DeviceService, GoogleService} from "../../services";
import {useEventListener} from "../../hooks";
import {isFirefox, isIOS} from "react-device-detect";
import {isVideo} from "../../utils/file-utils";
import {ListHelpers} from "../../helpers";
import {GOOGLE_RECAPTCHA_ACTION_NAME_IMAGE} from "../../core/Constants";

const activeStyle = {
    borderColor: '#575757'
};

const acceptStyle = {
    borderColor: '#58b8d0'
};

const rejectStyle = {
    borderColor: '#e52029'
};

const ImageVideoUploader = ({
                                imageExtensions, maximumSizeFile, handleUploadImage,
                                handleOnClick, uploadedImage, percentCompleted,
                                handleUploadImageFromUrl, handleOnClickClipboard,
                                loading, disableSearch, handleResetUploadImage, dispatch,
                                displaySubmitButton, displayTitle, setLoading, setErrors,
                                hasVideo = false
                            }) => {

    const setParentLoading = value => {
        if (!setLoading) return;
        setLoading(value);
    }

    const setParentErrors = value => {
        if (!setErrors) return;
        setErrors(value);
    };

    const [values, setValues] = useState({
        loading: false,
        error: "",
        isMobile: DeviceService.isMobile(),
        fileRejections: []
    });

    const {t} = useTranslation();
    useEffect(() => {
        if (uploadedImage.correlationId !== 0 || uploadedImage.isFailed) {
            setParentLoading(false);
            setParentErrors(uploadedImage.isFailed ? (errorTranslationKey) : '');

            setValues({
                ...values,
                loading: false,
                error: uploadedImage.isFailed ? (errorTranslationKey) : '',
                fileRejections: []
            });
        }
    }, [uploadedImage]);

    const handleResize = useCallback(
        () => {
            setValues({...values, isMobile: DeviceService.isMobile()});
        },
        [setValues]
    );

    const errorTranslationKey = hasVideo ? t('image_uploader.error.rejected_file_contact_form.common') : t('image_uploader.error.rejected_file');

    // Add event listener using our hook
    useEventListener('resize', handleResize);

    const onDrop = (acceptedFiles) => {

        setValues({...values, loading: true, error: "", fileRejections: []});

        setParentLoading(true);
        setParentErrors("");

        if (acceptedFiles.length > 0) {
            const files = Array.from(acceptedFiles);
            const formData = new FormData();

            files.forEach((file, i) => {
                formData.append("File", file);
            });

            GoogleService.getToken(GOOGLE_RECAPTCHA_ACTION_NAME_IMAGE, (token) => {
                handleUploadImage(formData, files[0], token);
            }, dispatch);
        } else {
            setValues({...values, loading: false});
            setParentLoading(false);
        }
    };

    const handleOnClickVerifyButton = (event) => {
        event.preventDefault();
        if (uploadedImage.correlationId !== 0) {
            handleOnClick(event);
        } else {
            setValues({...values, error: t('image_uploader.error.no_image')});
        }
    };

    const handleOnClickOpen = (event) => {
        event.preventDefault();
        setValues({...values, error: "", fileRejections: []});
        open();
    };

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        open
    } = useDropzone({
        accept: imageExtensions,
        maxSize: maximumSizeFile,
        noClick: true,
        noKeyboard: true,
        multiple: false,
        onDrop
    });

    const style = useMemo(() => ({
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject
    ]);

    const formImageLoading = (
        <div className="form-field-img-loading">
            <Loader loading={values.loading} isLoadMore={false}/>
            <div className="form-field-progressbar" style={{width: `${percentCompleted}%`}}/>
            <p>{t('image_uploader.downloading')}</p>
        </div>
    );

    const formImageUploaded = (
        <div className="form-field-uploaded" id="image-search-field">
            <button className="button-reset-upload" onClick={handleResetUploadImage}>Close</button>
            <div className="form-field-thumb">
                <p>{t(`image_uploader.${hasVideo ? "uploaded_image_video" : "uploaded_image"}`)}</p>
                <div className="form-field-thumb-img">
                    {!isVideo(acceptedFiles[0]) &&
                    <img src={uploadedImage.url} alt={acceptedFiles.length > 0 ? acceptedFiles[0].name : ""}/>}

                    {(isVideo(acceptedFiles[0]) && acceptedFiles[0].type !== "video/avi") &&
                    <video width="100%" controls>
                        <source src={uploadedImage.url}
                                type={acceptedFiles.length > 0 ? acceptedFiles[0].type : ""}/>
                    </video>
                    }
                    
                    {(isVideo(acceptedFiles[0]) && acceptedFiles[0].type === "video/avi") &&
                    <div className="image-no-preview"/>}

                </div>
            </div>
            <div className="form-field-info">
                <p>{t('image_uploader.information')}</p>
                <div className="form-field-info-wrapper">
                    <ul>
                        <li>
                            <span>{t('image_uploader.file_name')}</span>
                            {(uploadedImage.correlationId !== 0) &&
                            <span key={uploadedImage.name}>{uploadedImage.name}</span>}
                        </li>
                        <li>
                            <span>{t('image_uploader.file_size')}</span>
                            {(uploadedImage.correlationId !== 0) &&
                            <span key={uploadedImage.name}>{Math.round(uploadedImage.size / 1024)} KB</span>}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );

    const manageDataTransferItems = (ev) => {
            setValues({...values, loading: true, error: "", fileRejections: []});
            setParentLoading(true);
            setParentErrors("");
            let data = ev.dataTransfer.items;
            for (let i = 0; i < data.length; i += 1) {
                if ((data[i].kind === 'string') &&
                    (data[i].type.match('^text/plain'))) {
                    // This item is the target node
                    data[i].getAsString(function (s) {
                        GoogleService.getToken(GOOGLE_RECAPTCHA_ACTION_NAME_IMAGE, (token) => {
                            handleUploadImageFromUrl(s, token)
                        }, dispatch);
                    });
                }
            }
        }
    ;

    const handleClipboard = (event) => {
        setValues({...values, loading: true, error: "", fileRejections: []});
        handleOnClickClipboard(event);
    };

    const formImageReady = (
        <>
            {values.isMobile ?
                <>
                    {values.loading ? formImageLoading :
                        <input {...getInputProps()} />}
                    {!values.loading &&
                    <div className="form-field-ready form-field-solid">
                        <div className="form-field-upload-image"/>
                        <p>
                            <span onClick={handleOnClickOpen}>
                                {t('image_uploader.download_image.1')}
                            </span>
                            {t('image_uploader.download_image.mobile_2')}
                        </p>
                    </div>}
                </> :
                <>
                    {values.loading ? formImageLoading :
                        <div className="form-field-ready form-field-dashed" {...getRootProps({
                            style,
                            onDrop: event => manageDataTransferItems(event)
                        })}>
                            <input {...getInputProps()} />
                            <p>{t(`image_uploader.${hasVideo ? "drop_title_video" : "drop_title"}`)}</p>
                        </div>
                    }
                    <div className="form-field-ready form-field-solid">
                        <div className="form-field-upload-image"/>
                        <p>
                            <span
                                onClick={handleOnClickOpen}>{t('image_uploader.download_image.1')}</span>{t('image_uploader.download_image.2')}
                        </p>
                    </div>
                </>}
        </>
    );

    if (fileRejections.length > 0 && (values.fileRejections.length === 0 || ListHelpers.getDifferences(values.fileRejections, fileRejections).length > 0)) {
        let error = errorTranslationKey;
        if (hasVideo && fileRejections[0] && fileRejections[0].errors[0]) {
            error = t(`image_uploader.error.rejected_file_contact_form.${fileRejections[0].errors[0].code}`);
        }
        setValues({...values, error: error, fileRejections: [...fileRejections]});
        setParentErrors((errorTranslationKey));
    }

    return (
        <>
            {displayTitle && <div className="form-label">
                <label>{t("image_uploader.label")}</label>
                {(!isIOS && !isFirefox) && <button className="button-link" onClick={handleClipboard}>
                    <span>{t("image_uploader.copy_url")}</span>
                </button>}
            </div>}
            <div className="form-field">
                <>
                    {(uploadedImage.correlationId !== 0) ?
                        formImageUploaded : formImageReady
                    }
                </>
            </div>
            {(displaySubmitButton || values.error) && <div className="form-actions error-container">
                <div className="errors">
                    <span className="error">{values.error}</span>
                </div>
                {displaySubmitButton && <input
                    type="submit"
                    value={t("image_uploader.button.label")}
                    onClick={handleOnClickVerifyButton}
                    className={disableSearch ? "disabled" : ""}/>}
            </div>}
            <Loader loading={loading}/>
        </>
    );
};

ImageVideoUploader.propTypes = {
    imageExtensions: PropTypes.object.isRequired,
    maximumSizeFile: PropTypes.number.isRequired,
    handleUploadImage: PropTypes.func.isRequired,
    handleUploadImageFromUrl: PropTypes.func.isRequired,
    handleOnClick: PropTypes.func,
    uploadedImage: PropTypes.object.isRequired,
    percentCompleted: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
};

export default ImageVideoUploader;
