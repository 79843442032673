import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import * as ReactGA from "react-ga";

const TooManyRequest = ({handleRedirectKeywordButton, handleRetryOnClick}) => {
    const {t, i18n} = useTranslation();
    useEffect(() => {
        ReactGA.event({
            category: 'ImportUrl',
            action: 'Result',
            label: 'Too many request'
        });
    }, []);

    return (
        <form id="request-form">
            <div className="form-label">
                <label>{t('too_many_request.title')}</label>
            </div>
            <div className="form-field">
                <p>{t('too_many_request.text.1')}</p>
                <p><strong>{t('too_many_request.text.2')}</strong>{t('too_many_request.text.3')}</p>
            </div>
            <div className="form-actions">
                <div className="redirect-button">
                    <a href={`/${i18n.language}`} onClick={handleRetryOnClick}>{t('too_many_request.button.1')}</a>
                </div>
                <div className="redirect-button">
                    <a href={`/${i18n.language}`}
                       onClick={handleRedirectKeywordButton}>{t('too_many_request.button.2')}</a>
                </div>
            </div>
        </form>
    );
};

TooManyRequest.propTypes = {
    handleRedirectKeywordButton: PropTypes.func.isRequired,
    handleRetryOnClick: PropTypes.func.isRequired,
};

export default TooManyRequest;