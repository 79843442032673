import {Enums} from "../core";
import {fetchError, networkError, tooManyRequestError} from "../actions/ErrorActions";

const ErrorService = {
    isNetworkError(error) {
        return error.hasOwnProperty('message') && error.message === Enums.Errors.Network;
    },
    isTooManyRequest(error) {
        return error.hasOwnProperty('response') && error.response.hasOwnProperty('status') && error.response.status === Enums.StatusCode.TooManyRequest;
    },
    dispatchError(dispatch, error, type, scope, page) {
        if (ErrorService.isNetworkError(error)) {
            dispatch(networkError());
        } else if (ErrorService.isTooManyRequest(error)) {
            dispatch(tooManyRequestError());
        } else {
            dispatch(fetchError(error, type, scope, page))
        }
    }
};

export default ErrorService;