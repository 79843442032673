import React from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {NoImage} from "../common";

const SearchResultUrl = ({url, link}) => {
    const {t} = useTranslation();
    return (
        <article className="article-result url-result">
            <header className="article-header">
                {(link && link.title && link.domain) ?
                    <div className="article-domain">
                        {link.favIco && <img src={link.favIco} alt=""/>}
                        {link.domain && <span>{link.domain}</span>}
                    </div> :
                    <>
                        {t('search_result_url.title')}
                        <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                    </>}
            </header>
            {(link && link.title && link.domain) && <div className="article-content">
                <div className="article-image">
                    {link.image ? <img src={link.image} alt=""/> : <NoImage/>}
                </div>
                <div className="article-intro">
                    <h3>{link.title}</h3>
                    <p>{link.description}</p>
                    <a href={link.url} className="more-link" target="_blank"
                       rel="noopener noreferrer">{t('search_result_url.read_more', link.domain)}</a>
                </div>
            </div>}
        </article>
    );
};

SearchResultUrl.propTypes = {
    subject: PropTypes.object,
    url: PropTypes.string.isRequired,
};

export default SearchResultUrl;
