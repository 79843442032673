import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {NoImage} from "../common";

const ImportImagePreview = ({url}) => {
    const {t} = useTranslation();
    return (
        <div className="analyzed-image-thumb">
            <p>{t('image_uploader.uploaded_image')}</p>
            <div className="analyzed-image-thumb-img">
                {url ? <img
                        src={url}
                        alt={url}/> :
                    <NoImage/>}
                {/*<div className="analyzed-image-thumb-overlay"/>*/}
            </div>
        </div>
    );
};

ImportImagePreview.propTypes = {
    url: PropTypes.string.isRequired,
};

export default ImportImagePreview;
