import React from 'react';
import {Enums} from "../../core";
import {useTranslation} from "react-i18next";

const DDContent = () => {
    const {t} = useTranslation();
    return (
        <>
            <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.content.1`)}</p>
            <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.content.2`)}</p>
            <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.content.3`)}</p>
            <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.content.4`)}</p>
            <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.content.5`)}</p>
            <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.content.6`)}</p>
            <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.content.7`)}</p>
            <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.content.8`)}</p>
            <p>
                <small>
                    (1) <a href="https://www.rtbf.be/entreprise/a-propos" target="_blank"
                       rel="noopener noreferrer">https://www.rtbf.be/entreprise/a-propos</a>
                </small>
            </p>
        </>
    );
};

export default DDContent;