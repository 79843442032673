import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Enums} from "../../core";
import {useTranslation} from "react-i18next";
import {DecodexModal} from "./index";
import * as ReactGA from "react-ga";

const DecodexAnalysisContentListRow = ({isDescriptionOpen, description, mediaLinks, url}) => {
    const {t, i18n} = useTranslation();

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = event => {
        event.preventDefault();
        setModalIsOpen(true);
        ReactGA.modalview(`${i18n.language}/${t('routes.our_sources_route')}/${Enums.AnalysisOrigin.Decodex}`);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleOnClick = (event) => {
        ReactGA.event({
            category: 'SubjectDetails',
            action: `Click on ${Enums.AnalysisOrigin.Decodex} read more button`,
            label: event.target.href
        });
    };

    return (
        <div className="collapsible" style={isDescriptionOpen ? {display: 'block'} : {}}>
            <div className="article-content">
                <div className="article-column left">
                    <div className="article-abstract">
                        <h6 className="heading-article">
                        {t(`our_partners`)}
                        </h6>
                        <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.sub_title_logo`)}</p>
                        <a href="#openModal" onClick={openModal}
                           className="more-link">{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.read_more`)}</a>
                    </div>
                </div>

                <div className="article-column right">
                    <h6 className="heading-article">
                        {t(`detail_analysis_text`)}
                    </h6>
                    {mediaLinks.length === 1 ?
                        <>
                            <div className="article-intro">
                                <div className="article-domain">
                                    <strong>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.identified_domain`)}</strong>
                                    <span className="category unreliable color">{mediaLinks[0].domain}</span>
                                </div>
                                {mediaLinks[0].analysisUrl &&
                                <a href={mediaLinks[0].analysisUrl} target="_blank" onClick={handleOnClick}
                                   rel="noopener noreferrer">{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.link`)}</a>}
                            </div>
                        </>
                        :
                        <>
                            <div className="article-intro">
                                <p>{description}</p>
                                {url &&
                                <a href={url} target="_blank" onClick={handleOnClick}
                                   rel="noopener noreferrer">{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.link`)}</a>}
                            </div>
                        </>
                    }
                </div>
            </div>
            <DecodexModal closeModal={closeModal} modalIsOpen={modalIsOpen}/>
        </div>
    );
};

DecodexAnalysisContentListRow.propTypes = {
    isDescriptionOpen: PropTypes.bool.isRequired,
    url: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    mediaLinks: PropTypes.array,
};

export default DecodexAnalysisContentListRow;