import React from 'react';
import {useTranslation} from "react-i18next";
import {Enums} from "../../core";

const TextGainContent = () => {
    const {t} = useTranslation();
    return (
        <>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.TextGain}.content.1`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.TextGain}.content.2`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.TextGain}.content.3`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.TextGain}.content.4`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.TextGain}.content.5`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.TextGain}.content.6`)}
            </p>
        </>
    );
};

export default TextGainContent;
