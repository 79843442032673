import React from "react";
import {MailApi} from "../../api";
import {withTranslation} from "react-i18next";
import {GoogleService, RouteService} from "../../services";
import {Enums} from "../../core";
import {withRouter} from "../../hooks/useWithRouter";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {UrlHelpers} from "../../helpers";
import {AppConfig} from "../../config";
import ImageVideoUploader from "../common/ImageVideoUploader";
import {bindActionCreators} from "redux";
import {updateImportImage, uploadFromContactForm, uploadImageFromUrl} from "../../actions/importImageActions";

class ContactUsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mail: '',
            message: '',
            object: Enums.ContactUsObjectType.GeneralQuestion,
            link: '',
            optIn: "false",
            loading: false,
            errors: {},
            percentCompleted: 0
        };
        this.updateField = this.updateField.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleUploadImage = this.handleUploadImage.bind(this);
        this.handleResetUploadImage = this.handleResetUploadImage.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.setErrors = this.setErrors.bind(this);
    }

    componentWillUnmount() {
        this.props.updateImportImage({uploadedImage: {correlationId: 0}});
    }

    componentDidMount() {
        this.props.updateImportImage({uploadedImage: {correlationId: 0}});
    }

    updateField = event => {
        this.setState({
            ...this.state, [event.target.name]: event.target.value,
            errors: {...this.state.errors, [event.target.name]: ''}
        });
        this.addOrRemoveErrorClassOnWrapper(event.target.name, false);
    };

    handleOnSubmit = event => {
        event.preventDefault();
        if (!this.contactUsFromIsValid()) return;

        this.setState({loading: true});

        const {message, mail, link, object} = this.state;
        const {i18n, router, t, uploadedImage} = this.props;
        let that = this;

        GoogleService.getToken('contactUs', (token) => {
            MailApi.sendEmail({
                message: message,
                email: mail,
                link: link,
                objectType: object,
                imageUrl: uploadedImage.correlationId !== 0 ? uploadedImage.url : "",
                recaptchaToken: token
            }).then(result => {
                that.setState({loading: false});
                if (result.success) {
                    this.props.updateImportImage({uploadedImage: {correlationId: 0}});
                    RouteService.redirect(Enums.Routes.ContactUsThankYou, "", i18n.language, router.navigate, t);
                } else {
                    that.setState({...that.state, errors: {generic: t('contact_us_page.errors.generic_error')}});
                }
            }).catch(error => {
                that.setState({
                    ...that.state,
                    errors: {generic: t('contact_us_page.errors.generic_error')},
                    loading: false
                });
            });
        }, this.props.dispatch);
    };

    addOrRemoveErrorClassOnWrapper = (elementName, isAdding = true) => {
        let wrapper = this.closest(document.getElementsByName(elementName)[0], function (el) {
            return el.className.includes('form-field');
        });

        isAdding ? wrapper.classList.add("error") : wrapper.classList.remove("error");
    };

    contactUsFromIsValid = () => {
        let formIsValid = true;
        let _errors = {};
        const {mail, message, link, button, errors} = this.state;
        const {t} = this.props;

        if (errors.image) {
            _errors.image = errors.image;
            formIsValid = false;
        }

        if (!this.validateEmail(mail)) {
            _errors.mail = t('contact_us_page.errors.error_mail');
            formIsValid = false;
            this.addOrRemoveErrorClassOnWrapper("mail");
        }

        if (message.length < 5) {
            _errors.message = t('contact_us_page.errors.error_message');
            formIsValid = false;
            this.addOrRemoveErrorClassOnWrapper("message");
        }

        if (!this.validateUrl(link)) {
            _errors.link = t('contact_us_page.errors.error_link')
            formIsValid = false;
            this.addOrRemoveErrorClassOnWrapper("link");
        }
        _errors.mandatoryFieldError = t('contact_us_page.errors.error_mandatory');


        this.setState({...this.state, errors: _errors});
        return formIsValid;
    };

    validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    validateUrl = url => {
        return url === '' || UrlHelpers.validUrl(url);
    };

    handleOnFocus = event => {
        let wrapper = this.closest(event.target, function (el) {
            return el.className.includes('form-field');
        });
        wrapper.classList.add("active");
    };

    handleOnFocusOut = event => {
        let wrapper = this.closest(event.target, function (el) {
            return el.className.includes('form-field');
        });

        if (event.target.value) {
            wrapper.classList.add('full');
            wrapper.classList.remove('active');
        } else {
            wrapper.classList.remove('full', 'active');
        }
    };

    closest = (el, fn) => {
        return el && (fn(el) ? el : this.closest(el.parentNode, fn));
    };

    handleOnChange = value => {
        const fieldName = 'recaptcha';
        this.setState({...this.state, [fieldName]: value, errors: {...this.state.errors, [fieldName]: ''}});
    };

    handleUploadImage = (formData, file, token) => {
        this.props.uploadFromContactForm(formData, file, token, (percentCompleted) => {
            this.setState({percentCompleted});
        });
    };

    handleUploadImageFromUrl = (url, token) => {
        this.props.uploadImageFromUrl(url, token, (percentCompleted) => {
            this.setState({percentCompleted});
        });
    };

    handleResetUploadImage = () => {
        this.props.updateImportImage({uploadedImage: {correlationId: 0}});
    }

    setLoading = value => {
        this.setState({loading: value});
    }

    setErrors = value => {
        this.setState({errors: {...this.state.errors, image: value}});
    }

    render() {
        const {mail, errors, message, link, object, optIn, percentCompleted, loading} = this.state;
        const {t, uploadedImage} = this.props;
        return (
            <>
                <Helmet>
                    <title>{t('meta.title.contact_us')}</title>
                    <meta name="title" content={t('meta.title.contact_us')}/>
                    <meta name="description" content={t('meta.description.contact_us')}/>
                </Helmet>
                <div id="contact-us">
                    <div className="page-header">
                        <div className="container">
                            <h1>{t('contact_us_page.title')}</h1>
                            <p>{t('contact_us_page.subtitle')}</p>
                        </div>
                    </div>
                    <div className="page-content">
                        <div className="container">
                            <form onSubmit={this.handleOnSubmit}>
                                <div className="form-field">
                                    <label>{t('contact_us_page.your_mail')}</label>
                                    <input type="text" value={mail} name="mail" onChange={this.updateField}
                                           onFocus={this.handleOnFocus} onBlur={this.handleOnFocusOut}/>
                                    {errors.mail &&
                                    <span className="error">{errors.mail}</span>}
                                </div>
                                <div className="form-field">
                                    <label
                                        style={{position: "relative"}}>{t('contact_us_page.your_message_object')}</label>
                                    <div className="container-select">
                                        <select name="object" value={object} onChange={this.updateField}>
                                            <option
                                                value={Enums.ContactUsObjectType.GeneralQuestion}>{t('contact_us_page.select.option.1')}</option>
                                            <option
                                                value={Enums.ContactUsObjectType.ArticleAnalysisQuestion}>{t('contact_us_page.select.option.3')}</option>
                                            <option
                                                value={Enums.ContactUsObjectType.ReportFakeNews}>{t('contact_us_page.select.option.2')}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-field">
                                    <label>{t('contact_us_page.your_message')}</label>
                                    <textarea value={message} name="message" onChange={this.updateField}
                                              onFocus={this.handleOnFocus} onBlur={this.handleOnFocusOut}/>
                                    {errors.message &&
                                    <span className="error">{errors.message}</span>}
                                </div>
                                <div className="form-field">
                                    <label>{t('contact_us_page.your_link')}</label>
                                    <input type="text" value={link} name="link" onChange={this.updateField}
                                           onFocus={this.handleOnFocus} onBlur={this.handleOnFocusOut}/>
                                    {errors.link &&
                                    <span className="error">{errors.link}</span>}
                                </div>
                                <div className="form-field" id="image-form">
                                    <label>{t("contact_us_page.your_image")}</label>
                                    <ImageVideoUploader
                                        imageExtensions={{...AppConfig.ALLOWED_IMAGE_EXTENSIONS, ...AppConfig.ALLOWED_VIDEO_EXTENSIONS}}
                                        maximumSizeFile={AppConfig.MAXIMUM_SIZE_FILE}
                                        handleUploadImage={this.handleUploadImage}
                                        handleUploadImageFromUrl={this.handleUploadImageFromUrl}
                                        uploadedImage={uploadedImage}
                                        percentCompleted={percentCompleted}
                                        loading={false}
                                        handleResetUploadImage={this.handleResetUploadImage}
                                        disableSearch={false}
                                        dispatch={this.props.dispatch}
                                        setLoading={this.setLoading}
                                        setErrors={this.setErrors}
                                        hasVideo/>
                                </div>
                                <div className="form-field">
                                    <div className="container-checkbox">
                                        <input type="checkbox" id="optIn"
                                               name="optIn"
                                               onChange={this.updateField}
                                               checked={optIn === "true"}
                                               value={optIn !== "true"}/>
                                        <label htmlFor="optIn">{t('contact_us_page.optIn')}</label>
                                    </div>
                                    {errors.generic &&
                                    <span className="error">{errors.generic}</span>}
                                </div>
                                <div className="form-actions form-actions-right">
                                    <input type="submit" value={t('contact_us_page.button.text')}
                                           className={loading ? "disabled btn-width-xs" : "btn-width-xs"}/>
                                </div>
                                {(errors.mail || errors.message) &&
                                <div className="validation-container form-actions-right">
                                    <span className="error">{errors.mandatoryFieldError}</span>
                                </div>}
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFromContactForm: bindActionCreators(uploadFromContactForm, dispatch),
        uploadImageFromUrl: bindActionCreators(uploadImageFromUrl, dispatch),
        updateImportImage: bindActionCreators(updateImportImage, dispatch),
        dispatch
    };
};

const mapStateToProps = (state) => {
    const {uploadedImage} = state.importImage;

    return {
        uploadedImage
    };
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactUsPage)));