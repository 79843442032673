import React from 'react';
import {useTranslation} from "react-i18next";
import {Enums} from "../../core";

const DecodexContent = () => {
    const {t} = useTranslation();
    return (
        <>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.1`)}
                <u>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.2`)}</u>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.3`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.4`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.5`)}
            </p>
            <ul>
                <li>
                    <strong>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.6`)}</strong>
                    {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.7`)}
                </li>
                <li>
                    <strong>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.8`)}</strong>
                    {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.9`)}
                </li>
                <li>
                    <strong>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.10`)}</strong>
                    {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.11`)}
                </li>
                <li>
                    <strong>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.12`)}</strong>
                    {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.13`)}
                </li>
                <li>
                    <strong>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.14`)}</strong>
                    {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.15`)}
                </li>
            </ul>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.16`)}
            </p>
            <ul>
                <li>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.17`)}</li>
                <li>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.18`)}</li>
                <li>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.19`)}</li>
            </ul>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.20`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.21`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.22`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.23`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.content.24`)}
            </p>
            <p>
                <small>
                    (1) <a
                    href="https://www.lemonde.fr/les-decodeurs/article/2017/01/23/decodex-pourquoi-il-est-important-de-verifier-une-information-avant-de-la-partager_5067720_4355770.html"
                    target="_blank"
                    rel="noopener noreferrer">https://www.lemonde.fr/les-decodeurs/article/2017/01/23/decodex-pourquoi-il-est-important-de-verifier-une-information-avant-de-la-partager_5067720_4355770.html</a>
                </small>
                <small>
                    (2) <a href="https://fr.wikipedia.org/wiki/Les_D%C3%A9codeurs" target="_blank"
                           rel="noopener noreferrer">https://fr.wikipedia.org/wiki/Les_D%C3%A9codeurs</a>
                </small>
                <small>
                    (3) <a href="https://fr.wikipedia.org/wiki/Les_D%C3%A9codeurs#cite_note-:1-7" target="_blank"
                           rel="noopener noreferrer">https://fr.wikipedia.org/wiki/Les_D%C3%A9codeurs#cite_note-:1-7</a>
                </small>
                <small>
                    (4) <a href="https://fr.wikipedia.org/wiki/Les_D%C3%A9codeurs#cite_note-9" target="_blank"
                           rel="noopener noreferrer">https://fr.wikipedia.org/wiki/Les_D%C3%A9codeurs#cite_note-9</a>
                </small>
                <small>
                    (5) <a
                    href="https://www.lemonde.fr/les-decodeurs/article/2017/03/16/le-decodex-en-10-questions_5095621_4355770.html"
                    target="_blank"
                    rel="noopener noreferrer">https://www.lemonde.fr/les-decodeurs/article/2017/03/16/le-decodex-en-10-questions_5095621_4355770.html</a>
                </small>
                <small>
                    (6) <a
                    href="https://www.lemonde.fr/les-decodeurs/article/2017/03/16/les-mille-et-une-ruses-de-l-industrie-de-la-desinformation_5095635_4355770.html"
                    target="_blank"
                    rel="noopener noreferrer">https://www.lemonde.fr/les-decodeurs/article/2017/03/16/les-mille-et-une-ruses-de-l-industrie-de-la-desinformation_5095635_4355770.html</a>
                </small>
                <small>
                    (7) <a
                    href="https://abonnes.lemonde.fr/le-blog-du-decodex/article/2017/03/16/decodex-a-l-heure-de-la-post-verite-se-battre-pour-les-faits_5095624_5095029.html"
                    target="_blank"
                    rel="noopener noreferrer">https://abonnes.lemonde.fr/le-blog-du-decodex/article/2017/03/16/decodex-a-l-heure-de-la-post-verite-se-battre-pour-les-faits_5095624_5095029.html</a>
                </small>
            </p>
        </>
    );
};

export default DecodexContent;