import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function searchesReducer(state = initialState.searches, action) {
    switch (action.type) {
        case types.BEGIN_GET_SUBJECTS:
        case types.BEGIN_GET_LINKS:
            return {
                ...state,
                [action.payload.searchName]: {
                    ...state[action.payload.searchName],
                    [action.payload.page]: {
                        elements: [],
                        isSuccess: false,
                        isFetching: true,
                        isFailed: false
                    },
                    elementsCount: 0
                }
            };
        case types.GET_SUBJECTS_SUCCESS:
        case types.GET_LINKS_SUCCESS:
            return {
                ...state,
                [action.payload.searchName]: {
                    ...state[action.payload.searchName],
                    [action.payload.page]: {
                        elements: action.payload.elements.map(element => element.id),
                        isSuccess: true,
                        isFetching: false,
                        isFailed: false
                    },
                    elementsCount: action.payload.elementsCount
                }
            };
        case types.GET_SUBJECTS_ERROR:
        case types.GET_LINKS_ERROR:
            return {
                ...state,
                [action.payload.searchName]: {
                    ...state[action.payload.searchName],
                    [action.payload.page]: {
                        elements: [],
                        isSuccess: false,
                        isFetching: false,
                        isFailed: true
                    },
                    elementsCount: 0
                }
            };
        case types.CLEAN_SEARCH_FIELDS:
            return {};
        default:
            return state;
    }
}
