import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const HowDoesItWorkImagesPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("meta.title.how_does_it_work_images")}</title>
        <meta name="title" content={t("meta.title.how_does_it_work_images")} />
        <meta
          name="description"
          content={t("meta.description.how_does_it_work_images")}
        />
      </Helmet>
      <div>
        <div className="page-header">
          <div className="container">
            <h1>{t("how_images.title.1")}</h1>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="page-section">
              <h2>{t("how_images.title.2")}</h2>
              <p>{t("how_images.paragraph.1")}</p>
            </div>

            <div className="page-section">
              <h2>{t("how_images.title.3")}</h2>
              <p>
                {t("how_images.paragraph.how.1")}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cloud.google.com/vision/"
                >
                  {t("how_images.paragraph.how.2")}
                </a>
                {t("how_images.paragraph.how.3")}
                <NavLink
                  to={`/${i18n.language}/${t(
                    "routes.our_sources_route"
                  )}#decodex`}
                >
                  {t("how_images.paragraph.how.4")}
                </NavLink>
                {t("how_images.paragraph.how.5")}
              </p>
            </div>

            <div className="page-section">
              <h2>{t("how_images.title.4")}</h2>
              <p>{t("how_images.paragraph.2")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowDoesItWorkImagesPage;
