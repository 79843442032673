import {Enums} from "../core";
import * as types from "./actionTypes";
import {LinkApi} from "../api";
import {ErrorService} from "../services";

export const beginGetLinks = scope => {
    return {
        type: types.BEGIN_GET_LINKS,
        payload: {
            searchName: scope,
            page: 1
        }
    };
};

export const getLinksSuccess = (scope, links, linksCount) => {
    return {
        type: types.GET_LINKS_SUCCESS,
        payload: {
            searchName: scope,
            page: 1,
            elements: links,
            elementsCount: linksCount
        }
    };
};

export const getEmbed = (url) => {
    return dispatch => {
        let _scope = Enums.Scopes.SearchLink + '_' + url;
        dispatch(beginGetLinks(_scope));
        return LinkApi.getEmbed(url).then(result => {
            if (result.success) {
                let _array = [];
                if (result.linkInformation) _array.push(result.linkInformation);
                dispatch(getLinksSuccess(_scope, _array, 1))
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.GET_LINKS_ERROR, _scope);
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.GET_LINKS_ERROR, _scope);
            throw(error);
        });
    }
};