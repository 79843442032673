import React from 'react';
import PropTypes from 'prop-types';
import {Enums} from "../../core";
import {useTranslation} from "react-i18next";
import {CssClassHelpers} from "../../helpers";
import logoDecodeur from "../../assets/images/logo-decodeurs.png";
import logoDecodex from '../../assets/images/logo-decodex-refactoring.png';
import logoDD from '../../assets/images/logo-dd-refactoring.png';
import logoLynx from '../../assets/images/logo-lynx.png';
import logoTextGain from '../../assets/images/logo-textgain.png';
import logoNeutral from '../../assets/images/logo-neutral.svg';

const AnalysisHeaderListRow = ({scoring, origin, isDescriptionOpen, handleOnClick, mediaLinks}) => {
    const {t} = useTranslation();

    let imgSrc = '';
    switch(origin.toLowerCase()) {
        case Enums.AnalysisOrigin.Decodeur: imgSrc = logoDecodeur; break;
        case Enums.AnalysisOrigin.Decodex: imgSrc = logoDecodex; break;
        case Enums.AnalysisOrigin.Neutral: imgSrc = logoNeutral; break;
        case Enums.AnalysisOrigin.DD: imgSrc = logoDD; break;
        case Enums.AnalysisOrigin.TextGain: imgSrc = logoTextGain; break;
        case Enums.AnalysisOrigin.Lynx: imgSrc = logoLynx; break;
    }

    let url = ''
        , className = ''
        , verdictText = ''
        ;
    switch (origin.toLowerCase()) {
        case Enums.AnalysisOrigin.Decodeur :
            url = 'https://www.lemonde.fr/les-decodeurs/';
            verdictText = (scoring || scoring === 0) ? t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.scoring.withScoring`)
                : t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.scoring.withoutScoring`);
            break;
        case Enums.AnalysisOrigin.Decodex :
            url = 'https://www.lemonde.fr/verification/';
            className = 'square-img';
            verdictText = (scoring || scoring === 0) ? t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.scoring.${scoring}`)
                : t(`analysis_list_row.${Enums.AnalysisOrigin.Decodex}.scoring.withoutScoring`);
            break;
        case Enums.AnalysisOrigin.DD :
            url = '';
            verdictText = (scoring || scoring === 0) ? t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.scoring.${scoring}`)
            : t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.scoring.withoutScoring`);
            break;
        case Enums.AnalysisOrigin.TextGain :
            url = 'https://www.textgain.com';
            verdictText = (scoring || scoring === 0) ? t(`analysis_list_row.${Enums.AnalysisOrigin.TextGain}.scoring.${scoring}`)
            : t(`analysis_list_row.${Enums.AnalysisOrigin.TextGain}.scoring.withoutScoring`);
            break;
        case Enums.AnalysisOrigin.Neutral:
            url = 'https://neutralnews.fr/neutralnews/';
            className = 'square-img';
            verdictText = (scoring || scoring === 0) ? t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.scoring.${scoring}`)
            : t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.scoring.withoutScoring`);
            break;
        case Enums.AnalysisOrigin.Lynx:
            url = 'https://www.ebu.ch';
            className = 'square-img';
            verdictText = (scoring || scoring === 0) ? t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.scoring.${scoring}`)
            : t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.scoring.withoutScoring`);
            break;
        default:
            url = '';
            verdictText = '';
            break;
    }

    return (
        <header className="article-header">
            <div className="article-column left">
                <div className="partner-logo">
                    {url ? <a rel="noopener noreferrer">
                            <img
                                src={imgSrc}
                                alt=""
                                className={className}
                            />
                        </a> :
                        <img
                            src={imgSrc}
                            className={className}
                            alt=""/>}
                </div>
            </div>
            <div className="article-column right">
                <div className="article-verdict">
                    <span className={`category ${CssClassHelpers.mapScoringToCssClass(scoring)} color`}>
                        {verdictText}
                    </span>
                </div>
            </div>
            <button
                className={isDescriptionOpen ? "button-toggle-description description-open" : "button-toggle-description"}
                onClick={handleOnClick} data-id={`${origin.toLowerCase()}DescriptionToggle`}>Toggle description
            </button>
        </header>
    );
};

AnalysisHeaderListRow.propTypes = {
    scoring: PropTypes.number,
    origin: PropTypes.string.isRequired,
    isDescriptionOpen: PropTypes.bool,
    handleOnClick: PropTypes.func.isRequired,
    mediaLinks: PropTypes.array,
};

export default AnalysisHeaderListRow;
