import initialState from "./initialState";
import * as types from '../actions/actionTypes';

export default function importImageReducer(state = initialState.importImage, action) {
    switch (action.type) {
        case types.BEGIN_UPLOAD_IMAGE:
            return {
                ...state,
                uploadedImage: {
                    ...state.uploadedImage,
                    isSuccess: false,
                    isFetching: true,
                    isFailed: false
                }
            };
        case types.UPLOAD_IMAGE_ERROR: {
            return {
                ...state,
                uploadedImage: {
                    ...state.uploadedImage,
                    isSuccess: false,
                    isFetching: false,
                    isFailed: true
                }
            };
        }
        case types.UPLOAD_IMAGE_FROM_URL_ERROR: {
            return {
                ...state,
                uploadedImage: {
                    ...state.uploadedImage,
                    isSuccess: false,
                    isFetching: false,
                    isFailed: true
                }
            };
        }
        case types.UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                uploadedImage: {
                    ...action.payload,
                    isSuccess: true,
                    isFetching: false,
                    isFailed: false
                }
            };
        case types.GET_IMAGE_STATUS_SUCCESS:
            return {
                ...state,
                status: [...action.payload]
            };
        case types.UPDATE_IMPORT_IMAGE_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        case types.TRIGGER_IMAGE_SUCCESS:
            return {
                ...state,
                triggeredImage: {...action.payload}
            };
        default:
            return state;
    }
}
