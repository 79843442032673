export const handleOnUpdate = (registration) => {
    showUpdateBar();

    // Make sure that any new version of a service worker will take over the page
    // and become activated immediately.
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", event => {
            if (event.target.state === "activated") {
                window.location.reload();
            }
        });
        waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
    }
};

function showUpdateBar() {
    let snackbar = document.getElementById('snackbar');
    if (snackbar && snackbar.className && snackbar.className.includes("show")) return;
    snackbar.className = 'snackbar show';
}

