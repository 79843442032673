import initialState from "./initialState";
import * as types from '../actions/actionTypes';

const actionTypeEndsInError = (type) => {
    return type.substring(type.length - 6) === '_ERROR';
};

const actionTypeAllowAlertMessage = (type) => {
    switch (type) {
        case types.UPLOAD_IMAGE_FROM_URL_ERROR :
        case types.UPLOAD_IMAGE_ERROR :
        case types.NETWORK_ERROR:
        case types.TOO_MANY_REQUEST_ERROR:
        case types.SET_TOO_MANY_REQUEST:
            return false;
        default :
            return true;
    }
};

const getInformationFromError = (error) => {
    let _error = {};
    if (error.response) {
        _error = {
            ..._error,
            responseURL: error.response.request.responseURL,
            statusCode: error.response.status,
            apiResponse: typeof (error.response.data) === 'object' ? JSON.stringify({...error.response.data}) : error.response.data,
            method: error.response.config.method,
            parameters: typeof (error.response.data) === 'object' && error.response.config.data ? {...JSON.parse(error.response.config.data)} : null
        }
    }
    return _error;
};

export default function errorsReducer(state = initialState.errors, action) {
    if (!actionTypeEndsInError(action.type) && action.type !== types.SET_TOO_MANY_REQUEST) return state;

    if (actionTypeAllowAlertMessage(action.type)) {
        let _errorInformation = getInformationFromError(action.payload.errors);
        let _error = {
            ..._errorInformation,
            errorMessage: action.payload.errors.message,
            error: JSON.stringify({...action.payload.errors}),
            datetime: new Date(),
            location: {...window.location},
            navigator: window.navigator.userAgent,
            status: 'ToProcessed'
        };
        return {
            ...state,
            elements: [...state.elements, _error]
        };
    } else if (action.type === types.TOO_MANY_REQUEST_ERROR) {
        return {
            ...state,
            isTooManyRequestError: true
        };
    } else if (action.type === types.SET_TOO_MANY_REQUEST) {
        return {
            ...state,
            isTooManyRequestError: action.payload
        };
    }
    return state;
}