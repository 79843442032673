import axios from 'axios';
import {AppConfig} from "../config";

class SubjectApi {

    static url = AppConfig.API_URL;

    static async searchSubjects(request) {
        return await axios.post(this.url + "/subjects", request).then(response => response.data).then(result => {
            return result;
        });
    }

    static async getLatestSubject() {
        return await axios.get(this.url + "/subjects?pageSize=12&pageNumber=1").then(response => response.data).then(result => {
            return result;
        });
    }

    static async getSubjectDetails(id) {
        return await axios.get(`${this.url}/subjects/${id}?includeEmptyAnalysis=true`).then(response => response.data).then(result => {
            return result;
        });
    }

    static async getSimilarSubjects(id, request) {
        return await axios.post(this.url + "/subjects/" + id + "/similars", request).then(response => response.data).then(result => {
            return result;
        });
    }

    static async triggerImportSubject(request) {
        return await axios.post(this.url + "/subjects/trigger", request).then(response => response.data).then(result => {
            return result;
        });
    }

    static async getStatus(correlationId) {
        return await axios.get(this.url + "/subjects/status?correlationId=" + correlationId).then(response => response.data).then(result => {
            return result;
        });
    }

    static async getImportSubject(id, correlationId, includeEmptyAnalysis) {
        return await axios.get(`${this.url}/subjects/${id}?includeEmptyAnalysis=${includeEmptyAnalysis}${correlationId ? `&correlationId=${correlationId}` : ''}`).then(response => response.data).then(result => {
            return result;
        });
    }
}

export default SubjectApi;