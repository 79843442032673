import {AppConfig} from "../config";
import axios from "axios";

class ImageApi {
    static url = AppConfig.API_URL;

    static async uploadImage(request, token, callback) {
        request.append('recaptchaToken', token);
        return await axios.post(this.url + "/image/upload", request, {
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                callback(percentCompleted);
            }
        }).then(response => response.data).then(result => {
            return result;
        });
    }

    static async uploadImageFromUrl(url, token, callback) {
        return await axios.post(this.url + "/image/uploadFromUrl", {
            url,
            recaptchaToken: token
        }, {
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                callback(percentCompleted);
            }
        }).then(response => response.data).then(result => {
            return result;
        });
    }

    static async triggerImage(correlationId) {
        return await axios.post(this.url + "/image/trigger", {correlationId}).then(response => response.data).then(result => {
            return result;
        });
    }

    static async getImportImageSubject(subjectId) {
        return await axios.get(this.url + `/image/${subjectId}`).then(response => response.data).then(result => {
            return result;
        });
    }

    static async uploadFromContactForm(request, token, callback) {
        request.append('recaptchaToken', token);
        return await axios.post(this.url + "/image/uploadFromContactForm", request, {
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                callback(percentCompleted);
            }
        }).then(response => response.data).then(result => {
            return result;
        });
    }
}

export default ImageApi;
