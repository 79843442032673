import React, {Suspense, useEffect, useState} from 'react';
import {Footer, Header, HeaderContactUs} from "./layout";
import {RouterContainer, Routes} from "../routes";
import {useTranslation} from 'react-i18next';
import {Navigate, useNavigate, useParams, useLocation} from 'react-router-dom';
import '../scss/style.scss';
import moment from 'moment';
import {SearchField} from "./searchField";
import {RouteService} from "../services";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {CookieOverlay, Snackbar} from "./common";
import log from 'loglevel';
import {OfflineContentPage} from "./offline";
import {updateImportImage} from "../actions/importImageActions";
import {setSearchButtonDisabled} from "../actions/importSubjectActions";
import {bindActionCreators} from "redux";
import {AppConfig, setupAxiosInterceptors} from "../config";
import {HealthCheckApi} from "../api";
import {updateNetwork} from "../actions/networkActions";

// Toastr
import toastr from 'toastr';
import WithContextInstallPrompt from "./InstallPrompt";


toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-bottom-full-width",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

const App = (props) => {
    const { errors, updateNetwork, installPromptClosed } = props;
    const {online} = props.network;
    const {i18n, t} = useTranslation();
    const [errorsCount, setErrorsCount] = useState(0);
    const location = useLocation();
    const { locale } = useParams();
    const navigate = useNavigate();
    const availableLanguages = i18n.options.whitelist;
    let interval = null;

    const insertRecaptchaScript = () => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js?render=6Ldiq7gUAAAAAPspu3DFAfox_gHgxrlslyyWH0Ci";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    };

    const hasRecaptcha = () => typeof window !== 'undefined'
        && typeof window.grecaptcha !== 'undefined'
        && typeof window.grecaptcha.render === 'function';

    const healthCheck = () => {
        HealthCheckApi.head()
            .then(() => {
                clearInterval(interval);
                updateNetwork(true);

                if (!hasRecaptcha()) {
                    insertRecaptchaScript();
                }
            });
    };

    useEffect(() => {
        if (!availableLanguages.includes(i18n.language)) navigate(`/${availableLanguages[0]}/404`);
    }, [])

    useEffect(() => {
        if (!online) {
            interval = setInterval(healthCheck, AppConfig.ONLINE_INTERVAL);
        }
    }, [online]);

    if (!locale) return <Navigate to={`/${availableLanguages[0]}`} replace={true} />;

    if (i18n.language !== locale) {
        i18n.changeLanguage(locale);
        moment.locale(locale);

        window.recaptchaOptions = {
            lang: locale,
        };
    }

    if (!AppConfig.IS_PRODUCTION) {
        setupAxiosInterceptors(i18n.language);
    }

    if (errors && errors.length && errorsCount !== errors.length) {
        setErrorsCount(errors.length);
        log.error(JSON.stringify(errors[errors.length - 1]));
        toastr.warning(t('error_message.text'));
    }

    const showSearchField = RouteService.showSearchField(location, i18n.language, t, props.searchParams);
    const {updateImportImage, setSearchButtonDisabled} = props;

    return (
        <>
            <Header updateImportImage={updateImportImage} setSearchButtonDisabled={setSearchButtonDisabled}/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t('meta.title.main')}</title>
                <meta name="title" content={t('meta.title.main')}/>
                <meta name="description" content={t('meta.description.main')}/>
                {(AppConfig.hasOwnProperty("IS_PRODUCTION") && !AppConfig.IS_PRODUCTION) &&
                <meta name="robots" content="noindex, nofollow"/>}
            </Helmet>
            <main id="main">
                <RouterContainer>
                    {online && <>
                        {showSearchField && <SearchField/>}
                        <Suspense fallback={<div/>}>
                            <Routes url={location.pathname} location={location}/>
                        </Suspense>
                    </>}
                    {!online && <OfflineContentPage/>}
                </RouterContainer>
            </main>
            <CookieOverlay/>
            <Snackbar/>
            <Footer/>
            {!installPromptClosed && <WithContextInstallPrompt/>}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        searchParams: state.searchParams,
        errors: state.errors.elements,
        network: state.network,
        installPromptClosed: state.installPrompt.isClosed
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateImportImage: bindActionCreators(updateImportImage, dispatch),
        setSearchButtonDisabled: bindActionCreators(setSearchButtonDisabled, dispatch),
        updateNetwork: bindActionCreators(updateNetwork, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);