import React from 'react';
import {useTranslation} from "react-i18next";

const OfflineContentPage = () => {
    const {t} = useTranslation();
    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <h1>{(t('offline_content_page.title'))}</h1>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="form-message">
                        <p>{t('offline_content_page.content')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OfflineContentPage;