import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {CssClassHelpers} from "../../helpers";
import moment from 'moment';
import {useNavigate} from "react-router-dom";
import { withRouter } from "../../hooks/useWithRouter";
import {connect} from "react-redux";
import {updateSearchParams} from "../../actions/searchParamsActions";
import {bindActionCreators} from "redux";
import {deburr, snakeCase} from "lodash";
import {Enums} from "../../core";
import {StatisticsApi} from '../../api';
import {RouteService} from "../../services";
import {NoImage} from "../common";
import * as ReactGA from "react-ga";

const SubjectListRow = ({subject, updateSearchParams, source}) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const defaultVerifiedText = t('subject_list_row.verified_on_faky');
    const [values, setValues] = useState({verifiedText: defaultVerifiedText, verifiedTextClass: ""});

    let processedTitle = deburr(subject.title);
    processedTitle = snakeCase(processedTitle);

    const handleClickSubjectRSS = (event) => {
        event.preventDefault();
        //TODO add condition
        ReactGA.event({
            category: source,
            action: 'Click',
            label: subject.analyses[0].rssUrl
        });
        StatisticsApi.addStatistic(source, 'Click on subject', subject.analyses[0].rssUrl, [subject.id]);
        window.open(
            subject.analyses[0].rssUrl,
            '_blank'
        );
    };

    const hasPartnerAnalysis = subject.analyses && subject.analyses[0] && (subject.analyses[0].origin.toLowerCase() === Enums.AnalysisOrigin.Rss || subject.analyses[0].origin.toLowerCase() === Enums.AnalysisOrigin.Decodeur) && subject.analyses[0].rssUrl;

    return (
        <article className="article-teaser">
            <div className="article-image">
                {subject.imageUrl ?
                    <img src={subject.imageUrl} alt="Featured article"/> :
                    <NoImage/>
                }
            </div>
            <div className="article-state">
                <p className="article-state-rss">{t('subject_list_row.analysed_by_partners')}</p>
            </div>
            <div className="article-date">
                {subject.publicationDate &&
                <span>{subject.creator &&
                <strong>{subject.creator}</strong>}{subject.creator ? ` | ${moment(subject.publicationDate).format("DD/MM/YYYY")}` : moment(subject.publicationDate).format("DD/MM/YYYY")}</span>
                }
            </div>
            <div className="article-intro">
                <h3>{subject.title}</h3>
                <p>
                    {subject.description.substring(0, 350)}
                    {subject.description.length > 350 && '...'}
                </p>
            </div>
            {hasPartnerAnalysis ?
                <a href={subject.analyses[0].rssUrl} target="_blank" rel="noreferrer noopener"
                   onClick={handleClickSubjectRSS}>rss link</a> :
                <></>
            }
        </article>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSearchParams: bindActionCreators(updateSearchParams, dispatch),
    }
};

SubjectListRow.propTypes = {
    subject: PropTypes.object.isRequired
};

export default withRouter(connect(null, mapDispatchToProps)(SubjectListRow));
