import * as types from '../actions/actionTypes';
import initialState from './initialState';
import {isEqual} from "lodash";

export default function subjectsReducer(state = initialState.subjects, action) {
    if (action.type === types.GET_SUBJECTS_SUCCESS) {
        let _subjectsById = {...state.byId};
        let _allIds = [...state.allIds];
        for (let subject of action.payload.elements) {
            if (_allIds.includes(subject.id) && isEqual(subject, _subjectsById[subject.id])) continue;
            _subjectsById = {
                ..._subjectsById,
                [subject.id]: subject
            };
            _allIds.push(subject.id);
        }
        return {
            ...state,
            byId: {..._subjectsById},
            allIds: [..._allIds]
        };
    } else if(action.type === types.CLEAN_SEARCH_FIELDS) {
        return {
            ...state,
            byId: { },
            allIds: [ ]
        };
    } else {
        return state;
    }
}
