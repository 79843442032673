import React from 'react';
import PropTypes from 'prop-types';
import {SubjectList} from "./index";
import {Loader} from "../common";
import {useTranslation} from "react-i18next";
import * as ReactGA from "react-ga";

const SubjectResult = ({subjects, disableButtonLoadMore, loading, handleOnClick, source, isSimilarSubjects = false}) => {
    const {t} = useTranslation();
    const handleButtonOnClick = (event) => {
        ReactGA.event({
            category: 'Search',
            action: 'ShowMore'
        });
        handleOnClick(event);
    };
    return (
        <aside id="related-topics">
            <h2>{t(isSimilarSubjects ? 'subject_result.similar_subjects.title' : 'subject_result.subjects.title')}</h2>
            <div className="container">
                <SubjectList subjects={subjects} source={source}/>
            </div>
            <div className={disableButtonLoadMore ? "more-button disabled" : "more-button"}
                 onClick={handleButtonOnClick}>
                <a href={`#${t('subject_result.button.showMore')}`}>{t('subject_result.button.showMore')}</a>
            </div>
            <Loader loading={loading}/>
        </aside>
    );
};

SubjectResult.propTypes = {
    subjects: PropTypes.array.isRequired,
    disableButtonLoadMore: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    handleOnClick: PropTypes.func.isRequired,
    isSimilarSubjects: PropTypes.bool,
    source: PropTypes.string
};

export default SubjectResult;