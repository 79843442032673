export const takeStringUntil = (str, predicate, startIndex = 0) => {
    if(str && predicate && startIndex < str.length){
        for(let i = startIndex; i < str.length; i++){
            let item = str[i];

            if(!predicate(item))
                return str.slice(startIndex, i);
        }

        return str.slice(startIndex);
    }
};