import React from 'react';
import {Snackbar} from "../components/common";
import logo from "./assets/images/Faky-Logo-Beta-Long-White.svg";
//import './assets/style.css';
import '../scss/style.scss';
import {useTranslation} from "react-i18next";

const LandingPage = () => {
    const {t} = useTranslation();
    return (
        <>
            <main className="landing-page">
                <section className="container flex-column flex-wrap" style={{border: '0px solid white'}}>
                    <div className="twothird-width">
                        <h2>
                            <img src={logo} className="logo"/>
                        </h2>
                        <h1 style={{letterSpacing: '-4px'}}>
                            {t('landing_page.title')}
                        </h1>
                    </div>
                    <div className="threethird-width flex">
                        <h2 className="text-info">
                            <div>
                                <p>
                                    {t('landing_page.content.1')}
                                </p>
                                <p onClick={() => window.location.reload(false)} className="text-blue">
                                    {t('landing_page.content.2')}
                                </p>
                            </div>
                        </h2>
                    </div>
                </section>
            </main>

            <Snackbar/>
        </>
    );
};

LandingPage.propTypes = {};

export default LandingPage;