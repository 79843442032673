import initialState from "./initialState";
import * as types from '../actions/actionTypes';

export default function installPromptReducer(state = initialState.installPrompt, action) {
    switch (action.type) {
        case types.SET_INSTALL_PROMPT_CLOSED :
            return {...state, isClosed: action.payload.value};
        case types.SET_HAS_CLICK_ON_INSTALL_BUTTON:
            return {...state, hasClickOnInstallButton: action.payload.value};
        case types.SET_COOKIE_IS_ACCEPTED :
            return {...state, cookieIsAccepted: action.payload.value};
        default:
            return state;
    }
};