import React from 'react';
import PropTypes from 'prop-types';
import {CssClassHelpers} from "../../helpers";
import {useTranslation} from "react-i18next";

const ImportImageStatListRow = ({propertyName, totalNumber, onClick}) => {
    const {t} = useTranslation();
    return (
        <li data-value={propertyName} className={`stats-level-${CssClassHelpers.mapImportImagePropertyNameToCssClass(propertyName)}`}
            onClick={onClick}>
            <span data-value={propertyName}>{t(`import_image.domain_title.${propertyName}`)}</span>
            <span data-value={propertyName}>{totalNumber}</span>
        </li>
    );
};

ImportImageStatListRow.propTypes = {
    propertyName: PropTypes.string.isRequired,
    totalNumber: PropTypes.number.isRequired,
};

export default ImportImageStatListRow;
