import * as types from "./actionTypes";
import {SubjectApi} from "../api";
import {Enums} from "../core";
import {beginGetSubjects, getSimilarSubjects, loadSubjectsSuccess} from "./subjectActions";
import {ErrorService} from "../services";

export const triggerImportSubjectSuccess = correlationId => {
    return {
        type: types.TRIGGER_IMPORT_SUBJECT_SUCCESS,
        payload: correlationId
    };
};

export const getStatusSuccess = status => {
    return {
        type: types.GET_STATUS_SUCCESS,
        payload: status
    };
};

export const updateImportSubjectSuccess = importSubject => {
    return {
        type: types.UPDATE_IMPORT_SUBJECT_SUCCESS,
        payload: importSubject
    }
};

export const triggerImportSubject = (request) => {
    return dispatch => {
        return SubjectApi.triggerImportSubject(request).then(result => {
            if (result.success) {
                dispatch(triggerImportSubjectSuccess(result.correlationId));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.TRIGGER_IMPORT_SUBJECT_ERROR);
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.TRIGGER_IMPORT_SUBJECT_ERROR);
            throw(error);
        });
    }
};

export const getStatus = (correlationId) => {
    return dispatch => {
        return SubjectApi.getStatus(correlationId).then(result => {
            if (result.success) {
                dispatch(getStatusSuccess(result.status));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.GET_STATUS_ERROR);
                dispatch(setSearchButtonDisabled(false));
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.GET_STATUS_ERROR);
            dispatch(setSearchButtonDisabled(false));
            throw(error);
        });
    };
};

export const getImportSubject = (id, shouldGetSimilars, correlationId, includeEmptyAnalysis = false) => {
    return dispatch => {
        let _scope = Enums.Scopes.SearchImportSubject + '_' + id;
        dispatch(beginGetSubjects(_scope, 1));
        return SubjectApi.getImportSubject(id, correlationId, includeEmptyAnalysis).then(result => {
            if (result.success) {
                let _subjects = [];
                _subjects.push(result.subject);
                dispatch(loadSubjectsSuccess(_subjects, _scope, 1));
                if (shouldGetSimilars) {
                    dispatch(getSimilarSubjects(id, {
                        tags: [...result.subject.tags],
                        searchOptions: {pageSize: 3, pageNumber: 1}
                    }));
                }
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.GET_SUBJECTS_ERROR, _scope);
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.GET_SUBJECTS_ERROR, _scope);
            throw(error);
        });
    };
};

export const updateImportSubject = (importSubject) => {
    return dispatch => {
        dispatch(updateImportSubjectSuccess(importSubject));
    };
};

export const setSearchButtonDisabled = (status) => {
    return {
        type: types.UPDATE_SEARCH_BUTTON_STATUS,
        payload: status
    };
};