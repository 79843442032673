import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function importSubjectReducer(state = initialState.importSubject, action) {
    switch (action.type) {
        case types.TRIGGER_IMPORT_SUBJECT_SUCCESS:
            return {
                ...state,
                correlationId: action.payload
            };
        case types.GET_STATUS_SUCCESS:
            return {
                ...state,
                status: [...action.payload]
            };
        case types.GET_IMPORT_SUBJECT_SUCCESS:
            return {
                ...state,
                subject: {...action.payload}
            };
        case types.UPDATE_IMPORT_SUBJECT_SUCCESS:
            return {
                ...action.payload
            };
        case types.UPDATE_SEARCH_BUTTON_STATUS: 
            return {
                ...state,
                disableSearchButton: action.payload
            }
        
        default:
            return state;
    }
}
