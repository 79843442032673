import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function googleCaptchaReducer(state = initialState.google, action) {
    switch (action.type) {
        case types.BEGIN_GET_TOKEN:
            return {
                ...state,
                recaptcha: {
                    isSuccess: false,
                    isFetching: true,
                    isFailed: false
                }
            };
        case types.GET_TOKEN_SUCCESS:
            return {
                ...state,
                recaptcha: {
                    isSuccess: true,
                    isFetching: false,
                    isFailed: false
                }
            };
        case types.GET_TOKEN_ERROR:
            return {
                ...state,
                recaptcha: {
                    isSuccess: false,
                    isFetching: false,
                    isFailed: true
                }
            };
        default :
            return state;
    }
}