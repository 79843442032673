import React from 'react';
import PropTypes from 'prop-types';
import {ImportImageStatListRow} from "./";

const ImportImageStatList = ({imageDetails, onClick}) => {
    return (
        <ul className="analyzed-image-info-stats-level">
            {imageDetails && imageDetails.hasOwnProperty('totalNumberOfMediaLinks') && Object.keys(imageDetails).filter(k => k !== 'totalNumberOfMediaLinks' && k !== 'mainImageUrl').map(property => {
                return <ImportImageStatListRow
                    key={property}
                    propertyName={property}
                    totalNumber={imageDetails[property].length} onClick={onClick}/>;
            })}
        </ul>
    );
};

ImportImageStatList.propTypes = {
    imageDetails: PropTypes.object.isRequired,
};

export default ImportImageStatList;
