import React from 'react';
import {useTranslation} from "react-i18next";
import {connect} from 'react-redux';
import {setInstallPromptClosed} from "../../actions/installPromptActions";
import {INSTALL_PROMPT_OPEN_CLASS} from "../../core/Constants";

const InstallPromptHeader = ({setInstallPromptClosed}) => {
    const {t} = useTranslation();

    const handleClose = (event) => {
        event.preventDefault();
        setInstallPromptClosed(true);
        document.body.classList.remove(INSTALL_PROMPT_OPEN_CLASS);
    }

    return (
        <>
            <p className="mobile-icon"/>
            <p className="icon-close" onClick={handleClose}/>
            <p className="green-text">
                {t("install_prompt.default.title")}
            </p>
            <p className="white-subtitle">
                {t("install_prompt.default.subtitle")}
            </p>
        </>
    );
};

const mapDispatchToProps = {setInstallPromptClosed}

export default connect(null, mapDispatchToProps)(InstallPromptHeader);