import React from 'react';
import ImageVideoUploader from "../common/ImageVideoUploader";
import {AppConfig} from "../../config";

const SearchFieldImageForm = (props) => {
    const {
        className,
        handleUploadImage,
        handleOnClick,
        uploadedImage,
        percentCompleted,
        handleUploadImageFromUrl,
        handleOnClickClipboard,
        loading,
        disableSearch,
        handleResetUploadImage,
        dispatch
    } = props;

    return (
        <form id="image-form" className={className}>
            <ImageVideoUploader imageExtensions={AppConfig.ALLOWED_IMAGE_EXTENSIONS} maximumSizeFile={AppConfig.MAXIMUM_SIZE_FILE}
                                handleUploadImage={handleUploadImage} handleOnClick={handleOnClick}
                                uploadedImage={uploadedImage}
                                handleUploadImageFromUrl={handleUploadImageFromUrl}
                                percentCompleted={percentCompleted}
                                handleOnClickClipboard={handleOnClickClipboard}
                                loading={loading}
                                disableSearch={disableSearch}
                                handleResetUploadImage={handleResetUploadImage}
                                dispatch={dispatch}
                                displaySubmitButton
                                displayTitle/>
        </form>
    );
};

export default SearchFieldImageForm;
