import React from 'react';
import {useTranslation} from "react-i18next";

const FactCheckingContent = () => {
    const {t} = useTranslation();
    return (
        <>
            <p>
                {t(`analysis_list_row.rss.content.1`)}
            </p>

            <h4>
                {t(`analysis_list_row.rss.content.2`)}
            </h4>

            <h3>
                {t(`analysis_list_row.rss.content.3`)}
            </h3>
            <p>
                <a href="https://www.lemonde.fr/les-decodeurs/" target="_blank">
                    {t(`analysis_list_row.rss.content.4`)}
                </a>
                {t(`analysis_list_row.rss.content.5`)}
                <a href="https://www.lemonde.fr/" target="_blank">
                    {t(`analysis_list_row.rss.content.6`)}
                </a>.
            </p>
            <h3>
                {t(`analysis_list_row.rss.content.7`)}
            </h3>
            <p>
                <a href="https://www.francetvinfo.fr/vrai-ou-fake/" target="_blank">
                    {t(`analysis_list_row.rss.content.8`)}
                </a>
                {t(`analysis_list_row.rss.content.9`)}
                <a href="https://www.francetvinfo.fr/" target="_blank">
                    {t(`analysis_list_row.rss.content.10`)}
                </a>.
            </p>
            <h3>
                {t(`analysis_list_row.rss.content.11`)}
            </h3>
            <p>
                <a href="https://information.tv5monde.com/info/decryptage-vrai-dire" target="_blank">
                    {t(`analysis_list_row.rss.content.12`)}
                </a>
                {t(`analysis_list_row.rss.content.13`)}
                <a href="http://www.tv5monde.com/" target="_blank">
                    {t(`analysis_list_row.rss.content.14`)}
                </a>.
            </p>
            <h3>
                {t(`analysis_list_row.rss.content.15`)}
            </h3>
            <p>
                <a href="https://observers.france24.com/fr/tag/intox" target="_blank">
                    {t(`analysis_list_row.rss.content.16`)}
                </a>
                {t(`analysis_list_row.rss.content.17`)}
                <a href="https://observers.france24.com/fr/" target="_blank">
                    {t(`analysis_list_row.rss.content.18`)}
                </a>
                {t(`analysis_list_row.rss.content.19`)}
                <a href="https://www.france24.com/fr/" target="_blank">
                    {t(`analysis_list_row.rss.content.20`)}
                </a>.
            </p>
            <h3>
                {t(`analysis_list_row.rss.content.21`)}
            </h3>
            <p>
                <a href="https://ici.radio-canada.ca/theme/281/fausses-nouvelles/1" target="_blank">
                    {t(`analysis_list_row.rss.content.22`)}
                </a>
                {t(`analysis_list_row.rss.content.23`)}
                <a href="https://ici.radio-canada.ca/" target="_blank">
                    {t(`analysis_list_row.rss.content.24`)}
                </a>
            </p>
            <h3>
                {t(`analysis_list_row.rss.content.25`)}
            </h3>
            <p>
                {t(`analysis_list_row.rss.content.26`)}
                <a href="https://www.rtbf.be/info/" target="_blank">
                    {t(`analysis_list_row.rss.content.27`)}
                </a>.
            </p>

            <h4>
                {t(`analysis_list_row.rss.content.28`)}
            </h4>

            <h3>
                {t(`analysis_list_row.rss.content.29`)}
            </h3>
            <p>
                {t(`analysis_list_row.rss.content.30`)}
                <a href="https://hoax-net.be/" target="_blank">
                    {t(`analysis_list_row.rss.content.31`)}
                </a>
                {t(`analysis_list_row.rss.content.32`)}
            </p>
        </>
    );
};

export default FactCheckingContent;