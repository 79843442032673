import React from 'react';
import PropTypes from 'prop-types';
import logoLynx from "../../assets/images/logo-lynx.png";
import Modal from "react-modal";
import {LynxContent} from ".";

Modal.setAppElement(document.getElementById('root'));

const LynxModal = ({closeModal, modalIsOpen}) => {
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            portalClassName="modal"
            overlayClassName="modal-open"
            className="modal-container"
            bodyOpenClassName="modal-visible"
        >
            <header className="modal-header">
                <button className="button-close-modal" onClick={closeModal}>Close</button>
                <div className="branding">
                    <img src={logoLynx} alt=""/>
                </div>
            </header>
            <div className="modal-content container-columns">
                <LynxContent/>
            </div>
        </Modal>
    );
};

LynxModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalIsOpen: PropTypes.bool,
};

export default LynxModal;