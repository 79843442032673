import React, { useEffect } from "react";
import logoDecodeur from "../../assets/images/logo-decodeurs.png";
import logoDecodex from "../../assets/images/logo-decodex-refactoring.png";
import logoDD from "../../assets/images/logo-dd-refactoring.png";
import logoTextGain from "../../assets/images/logo-textgain.png";
import logoGoogleVision from "../../assets/images/logo-google-vision.svg";
import logoNeutral from "../../assets/images/logo-neutral.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ScrollService } from "../../services";
import { DDContent } from "../DD";
import { DecodexContent } from "../Decodex";
import { DecodeurContent } from "../Decodeur";
import { FactCheckingContent } from "../Rss";
import { NeutralContent } from "../Neutral";
import { Helmet } from "react-helmet";
import { TextGainContent } from "../TextGain";
import { GoogleVisionContent } from "../GoogleVision";

const OurSourcesPage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    ScrollService.scrollToElement(hash);
  });

  return (
    <>
      <Helmet>
        <title>{t("meta.title.our_sources")}</title>
        <meta name="title" content={t("meta.title.our_sources")} />
        <meta name="description" content={t("meta.description.our_sources")} />
      </Helmet>
      <div>
        <div className="page-header">
          <div className="container">
            <h1>{t("our_source_page.title.1")}</h1>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="page-section">
              <p>{t("our_source_page.paragraph.1")}</p>
            </div>

            <div className="page-section" id="decodeur">
              <h2>{t("our_source_page.title.2")}</h2>
              <h3>{t("our_source_page.title.3")}</h3>
              <figure>
                <img src={logoDecodeur} alt="Les Décodeurs" />
                <figcaption>
                  {t("our_source_page.figcaption.le_monde.1")}
                  <a
                    href="http://decodeurs.blog.lemonde.fr/2014/03/07/les-decodeurs-cest-fini-place-aux-decodeurs/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://decodeurs.blog.lemonde.fr/2014/03/07/les-decodeurs-cest-fini-place-aux-decodeurs/
                  </a>
                  {t("our_source_page.figcaption.le_monde.2")}
                  <a
                    href="https://fr.wikipedia.org/w/index.php?curid=10616037"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://fr.wikipedia.org/w/index.php?curid=10616037
                  </a>
                </figcaption>
              </figure>
              <DecodeurContent />
            </div>

            <div className="page-section" id="decodex">
              <h3>{t("our_source_page.title.4")}</h3>
              <figure>
                <img
                  src={logoDecodex}
                  alt="Decodex"
                  className="no-img-resizing"
                />
                <figcaption>{t("our_source_page.figcaption.1")}</figcaption>
              </figure>
              <DecodexContent />
            </div>

            <div className="page-section" id="detecteurdesinformation">
              <h3>{t("our_source_page.title.5")}</h3>
              <figure>
                <img
                  src={logoDD}
                  alt=" Le détecteur de désinformation de la RTBF"
                />
                <figcaption>{t("our_source_page.figcaption.2")}</figcaption>
              </figure>
              <DDContent />
            </div>

            <div className="page-section" id="textgain">
              <h3>{t("our_source_page.title.6")}</h3>
              <figure>
                <img src={logoTextGain} alt="Textgain" />
                <figcaption>{t("our_source_page.figcaption.3")}</figcaption>
              </figure>
              <TextGainContent />
            </div>

            <div className="page-section" id="neutral">
              <h3>{t("our_source_page.title.7")}</h3>
              <figure>
                <img src={logoNeutral} alt="Crowlingo" />
                <figcaption>{t("our_source_page.figcaption.4")}</figcaption>
              </figure>
              <NeutralContent />
            </div>

            <div className="page-section" id="fact-checking">
              <h3>{t("our_source_page.title.8")}</h3>
              <FactCheckingContent />
            </div>

            <div className="page-section" id="googleVision">
              <h2>{t("our_source_page.title.9")}</h2>
              <h3>{t("our_source_page.title.10")}</h3>
              <figure>
                <img src={logoGoogleVision} alt="Google Vision Cloud" />
                <figcaption>
                  {t("our_source_page.figcaption.5")}
                  <a
                    href="https://commons.wikimedia.org/wiki/File:Cloud-vision-api-Logo.svg#filelinks"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://commons.wikimedia.org/wiki/File:Cloud-vision-api-Logo.svg#filelinks
                  </a>
                </figcaption>
              </figure>
              <GoogleVisionContent />
            </div>

            <div className=" page-section">
              <h2>{t("our_source_page.title.11")}</h2>
              <p>
                {t("our_source_page.paragraph.2")}
                <NavLink
                  to={`/${i18n.language}/${t("routes.contact_us_route")}`}
                >
                  {t("our_source_page.navlink.1")}
                </NavLink>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurSourcesPage;
