import axios from "axios";
import {AppConfig} from "../config";

class HealthCheckApi {
    static url = AppConfig.API_URL;

    static async head() {
        return await axios.head(this.url + "/healthCheck").then(response => response.data).then(result => {
            return result
        });
    };
}

export default HealthCheckApi;