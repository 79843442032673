import React from 'react';
import PropTypes from 'prop-types';
import {SearchResultImage, SearchResultText, SearchResultUrl} from "./index";

const SearchResult = (props) => {
    const {
        text,
        url,
        image,
        tags,
        link,
        isFetching,
        triggeredImage,
        imageOnClick,
        textDetected,
        showBackButton
    } = props;

    return (
        <div className="container">
            {url && <SearchResultUrl url={url} link={link}/>}
            {text && <SearchResultText text={text} tags={tags} isFetching={isFetching} showBackButton={showBackButton}/>}
            {image && <SearchResultImage imageDetails={image} triggeredImage={triggeredImage} onClick={imageOnClick}
                                         textDetected={textDetected}/>}
        </div>
    );
};

SearchResult.propTypes = {
    text: PropTypes.string,
    url: PropTypes.string,
    tags: PropTypes.array,
    isFetching: PropTypes.bool,
};

export default SearchResult;
