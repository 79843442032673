import React from 'react';
import PropTypes from 'prop-types';
import {AnalysisListRow} from "./index";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Enums} from "../../core";
import * as _ from "lodash";

const AnalysisList = ({analyses}) => {
        const getOrderNumber = origin => {

            switch (origin) {
                case Enums.AnalysisOrigin.Decodeur:
                    return 4;
                case Enums.AnalysisOrigin.Decodex:
                    return 0;
                case Enums.AnalysisOrigin.DD:
                    return 2;
                case Enums.AnalysisOrigin.TextGain:
                    return 3;
                case Enums.AnalysisOrigin.Neutral:
                    return 1;
                default :
                    return 20;
            }
        };

        let _analyses = analyses ? [...analyses] : analyses;

        if (_analyses) {
            
            let _analysesWithScoring = _analyses.filter(analysis => analysis.scoring || analysis.scoring === 0);
            _analysesWithScoring = _analysesWithScoring.map(analysis => ({
                ...analysis,
                orderNumber: getOrderNumber(analysis.origin.toLowerCase())
            }));
            _analysesWithScoring = _.sortBy(_analysesWithScoring, [(a) => a.orderNumber]);

            let _analysesWithoutScoring = _analyses.filter(analysis => !analysis.scoring && analysis.scoring !== 0);
            _analysesWithoutScoring = _analysesWithoutScoring.map(analysis => ({
                ...analysis,
                orderNumber: getOrderNumber(analysis.origin.toLowerCase())
            }));
            _analysesWithoutScoring = _.sortBy(_analysesWithoutScoring, [(a) => a.orderNumber]);

            _analyses = _analysesWithScoring.concat(_analysesWithoutScoring);
        }

        return (
            <div className="container">
                <TransitionGroup component={null}>
                    {_analyses && _analyses.map((analysis, index) =>
                        <CSSTransition
                            key={index}
                            classNames="fade"
                            timeout={{enter: 500, exit: 0}}>
                            <AnalysisListRow key={index} analysis={analysis}/>
                        </CSSTransition>)}
                </TransitionGroup>
            </div>
        );
    }
;

AnalysisList.propTypes = {
    analyses: PropTypes.array,
};

export default React.memo(AnalysisList);
