import React, {Component} from 'react';
import { withRouter } from "../hooks/useWithRouter";
import * as ReactGA from "react-ga";
import toastr from 'toastr';
import {withTranslation} from "react-i18next";
import log from "loglevel";
import {NotFoundPage} from "../components/common";

class RouterContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidUpdate(prevProps) {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let scrollToFinderResults = document.getElementById('finder-results') ? document.getElementById('finder-results').offsetTop - document.getElementById('header').clientHeight : 0;

        if (this.props.router.location.pathname !== prevProps.router.location.pathname && scrollToFinderResults === 0 && window.pageYOffset !== scrollToFinderResults) {
            window.scrollTo(0, 0);
        }
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(error, info) {
        log.error(JSON.stringify({errorMessage: `${error} - ${info}`}));
    }

    render() {
        const {t} = this.props;
        if (this.state.hasError) {
            toastr.warning(t('error_message.text'));
            return <NotFoundPage/>;
        }
        return this.props.children;
    }
}

export default withRouter(withTranslation()(RouterContainer));
