import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import * as ReactGA from "react-ga";
import {Enums} from "../../core";
import {NeutralModal} from "./index";

const NeutralAnalysisContentListRow = ({isDescriptionOpen, mediaLinks, originalScoring, additionalScoring}) => {
    const {t, i18n} = useTranslation();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = event => {
        event.preventDefault();
        setModalIsOpen(true);
        ReactGA.modalview(`${i18n.language}/${t('routes.our_sources_route')}/${Enums.AnalysisOrigin.Neutral}`);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    if(mediaLinks.length <= 1) {
        originalScoring = mediaLinks && mediaLinks[0] && mediaLinks[0].hasOwnProperty('originalScoring') ? mediaLinks[0].originalScoring : "";
    }

    const operation = additionalScoring >= 0 ? t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.scoring.positive`) : t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.scoring.negative`);
    additionalScoring = Math.abs(additionalScoring);

    return (
        <div className="collapsible" style={isDescriptionOpen ? {display: 'block'} : {}}>
            <div className="article-content">
                <div className="article-column left">
                    <div className="article-abstract">
                        <h6 className="heading-article">
                            {t(`our_partners`)}
                        </h6>
                        <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.sub_title_logo`)}</p>
                        <a href="#openModal" onClick={openModal}
                           className="more-link">{t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.read_more`)}</a>
                    </div>
                </div>

                <div className="article-column right">
                    <h6 className="heading-article">
                        {t(`detail_analysis_text`)}
                    </h6>
                    <div className="article-intro">
                        {originalScoring && 
                            <>
                                <p>                        
                                    {
                                        (mediaLinks.length <= 1) &&
                                        t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.description.one_url`, { score: additionalScoring, operation })
                                    }
                                    {
                                        (mediaLinks.length > 1) &&
                                        t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.description.multiple_urls`, originalScoring)
                                    }
                                </p>
                                <ol>
                                    <li>{t(`analysis_list_row.neutral.scoring.2`)}</li>
                                    <li>{t(`analysis_list_row.neutral.scoring.1`)}</li>
                                    <li>{t(`analysis_list_row.neutral.scoring.0`)}</li>
                                    <li>{t(`analysis_list_row.neutral.scoring.-1`)}</li>
                                    <li>{t(`analysis_list_row.neutral.scoring.-2`)}</li>
                                </ol>
                            </>
                        }
                        {!originalScoring && 
                            <p>
                                {t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.description.no_scoring`)}
                            </p>
                        }
                    </div>
                </div>
            </div>

            <NeutralModal closeModal={closeModal} modalIsOpen={modalIsOpen}/>
        </div>
    );
};

NeutralAnalysisContentListRow.propTypes = {};

export default NeutralAnalysisContentListRow;