import React from 'react';
import {Navigate, Route, Routes as RoutesWrapper} from "react-router-dom";
import {HomePage} from "../components/home";
import {SubjectPage, SubjectDetailPage} from "../components/subject";
import {useTranslation} from 'react-i18next';
import {NotFoundPage} from "../components/common";
import {
    WhoWeArePage,
    OurSourcesPage,
    HowDoesItWorkPage,
    HowDoesItWorkImagesPage,
    LegalMentionPage,
    PrivacyPage,
    FakyzationPage
} from "../components/content";
import {ContactUsPage, ContactUsThankYouPage} from "../components/contactUs";
import {ImportSubjectPage} from "../components/importSubject";
import {ImportImagePage} from "../components/importImage";
import {AnalyseImageTextPage} from "../components/analyseImageText";

const Routes = () => {
    const {t, i18n} = useTranslation();
    return (
        <>
            <RoutesWrapper>
                <Route exact path={`/`} element={<HomePage/>} />
                <Route path={`/${t('routes.who_we_are_route')}`} element={<WhoWeArePage/>} />
                <Route path={`/${t('routes.fakyzation')}`} element={<FakyzationPage/>} />
                <Route path={`/${t('routes.our_sources_route')}`} element={<OurSourcesPage/>} />
                <Route path={`/${t('routes.how_does_it_work_route')}`} element={<HowDoesItWorkPage/>} />
                <Route path={`/${t('routes.how_does_it_work_images_route')}`}
                       element={<HowDoesItWorkImagesPage/>} />
                <Route path={`/${t('routes.legal_mention_route')}`} element={<LegalMentionPage/>} />
                <Route path={`/${t('routes.privacy_route')}`} element={<PrivacyPage/>} />
                <Route exact path={`/${t('routes.contact_us_route')}`} element={<ContactUsPage/>} />
                <Route path={`/${t('routes.contact_us_thank_you_route')}`} element={<ContactUsThankYouPage/>} />
                <Route path={`/${t('routes.subject_route')}/:searchText`} element={<SubjectPage/>} />
                <Route path={`/${t('routes.subject_detail_route')}/:id`} element={<SubjectDetailPage/>} />
                <Route path={`/${t('routes.subject_detail_route')}/:id/:title`} element={<SubjectDetailPage/>} />
                <Route path={`/${t('routes.import_status_route')}/:correlationId`}
                       element={<ImportSubjectPage/>} />
                <Route path={`/${t('routes.import_image_route')}/:correlationId`}
                       element={<ImportImagePage/>} />
                <Route path={`/${t('routes.analyse_image_text_route')}/:searchText`} element={<AnalyseImageTextPage/>} />
                <Route path={`/404`} element={<NotFoundPage/>} />
                <Route path="*" element={<Navigate to={`/${i18n.language}/404`} replace />} />
            </RoutesWrapper>
        </>
    );
};
export default Routes;