import axios from "axios";
import {AppConfig} from "../config";

class ContactApi {
    static url = AppConfig.API_URL;

    static async sendEmail(request) {
        return await axios.post(this.url + "/contact/send", request).then(response => response.data).then(result => {
            if (result.success) return result;
        });
    }
}

export default ContactApi;