import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {SliderSubjectList} from "../subject";
import {withTranslation} from "react-i18next";
import {ListHelpers} from "../../helpers";
import {updateSearchOptions} from "../../actions/searchOptionsActions";
import {updateSearchParams} from "../../actions/searchParamsActions";
import {getLatestSubjects} from "../../actions/subjectActions";
import {Enums} from "../../core";
import {SearchService} from "../../services";
import {updateImportImage} from "../../actions/importImageActions";

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subjects: [...this.props.subjects]
        };
    }

    componentDidMount() {
        if (document.getElementById("search-field"))
            document.getElementById("search-field").focus();
        this.props.getLatestSubjects();
        this.props.updateSearchParams({text: '', url: ''});
        this.props.updateImportImage({uploadedImage: {correlationId: 0}});
    }

    componentWillUnmount() {
        this.props.updateSearchOptions({pageSize: 3, pageNumber: 1});
    }

    static getDerivedStateFromProps(props, state) {
        if (props.subjects.length > 0 && ListHelpers.getDifferences(props.subjects, state.subjects).length > 0) {
            return {subjects: [...props.subjects]};
        }
        return null;
    }

    render() {
        const {t} = this.props;
        const {subjects} = this.state;
        return (
            <>
                <section id="recently-checked">
                    <h2>{t('home.latest_subjects.title')}</h2>
                    <SliderSubjectList subjects={subjects}/>
                </section>
            </>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getLatestSubjects: bindActionCreators(getLatestSubjects, dispatch),
        updateSearchOptions: bindActionCreators(updateSearchOptions, dispatch),
        updateSearchParams: bindActionCreators(updateSearchParams, dispatch),
        updateImportImage: bindActionCreators(updateImportImage, dispatch)
    };
};

const mapStateToProps = (state) => {

    let {subjects} = SearchService.getSubjects(state, Enums.Scopes.SearchLatest);

    return {
        subjects: subjects
    }
};

HomePage.propTypes = {};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(HomePage));
