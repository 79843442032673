import React from 'react';
// import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {Enums} from "../../core";

const NeutralContent = () => {
    const {t, i18n} = useTranslation();
    return (
        <>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.content.1`)}
                <a href="https://crowlingo.com/" target="_blank" rel="noopener noreferrer">Crowlingo</a>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.content.2`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.content.3`)}
            </p>

            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.content.4`)}
                <a href="https://www.crowdtangle.com/" target="_blank" rel="noopener noreferrer">Crowdtangle</a>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.content.5`)}
            </p>

            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.content.6`)}
                <a href="https://news.mit.edu/2018/study-twitter-false-news-travels-faster-true-stories-0308"
                   target="_blank" rel="noopener noreferrer">
                    {t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.content.7`)}
                </a>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.content.8`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Neutral}.content.9`)}
            </p>
            <ol>
                <li>{t(`analysis_list_row.neutral.scoring.2`)}</li>
                <li>{t(`analysis_list_row.neutral.scoring.1`)}</li>
                <li>{t(`analysis_list_row.neutral.scoring.0`)}</li>
                <li>{t(`analysis_list_row.neutral.scoring.-1`)}</li>
                <li>{t(`analysis_list_row.neutral.scoring.-2`)}</li>
            </ol>
        </>
    );
};

export default NeutralContent;
