import React from 'react';
import PropTypes from 'prop-types';
// import {useTranslation} from "react-i18next";
// import {LanguageSwitcher} from "../common";

const HeaderMenu = () => {
    // const { t} = useTranslation();
    return (
        <nav className="header-menu">
            <ul>
                {/*<li className="login">*/}
                {/*    <a href="#">*/}
                {/*        {t('header.login')}*/}
                {/*    </a>*/}
                {/*</li>*/}
                {/*<LanguageSwitcher/>*/}
            </ul>
        </nav>
    );
};

HeaderMenu.propTypes = {
    isPanel: PropTypes.bool,
};

export default HeaderMenu;