import React from 'react';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const HeaderContactUs = ({isMobile}) => {
    const {t, i18n} = useTranslation();

    if (isMobile) return <></>;

    return (
        <div id="header-contact-us">
            <div className="contact-us-button">
                <NavLink
                    to={`/${i18n.language}/${t('routes.contact_us_route')}`}>
                    {t('main_menu.link_6')}
                </NavLink>
            </div>
        </div>
    );
};

export default HeaderContactUs;