import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ScrollService } from "../../services";
import { Helmet } from "react-helmet";

const WhoWeArePage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    ScrollService.scrollToElement(hash);
  });

  // const handleOnClick = (event) => {
  //     event.preventDefault();
  //     const splitHref = event.target.href.split('#');
  //     window.location.hash = `#${splitHref[splitHref.length-1]}`;
  //     ScrollService.scrollToElement(splitHref[splitHref.length-1]);
  // };

  return (
    <>
      <Helmet>
        <title>{t("meta.title.who_we_are")}</title>
        <meta name="title" content={t("meta.title.who_we_are")} />
        <meta name="description" content={t("meta.description.who_we_are")} />
      </Helmet>
      <div>
        <div className="page-header">
          <div className="container">
            <h1>À propos de Faky ?</h1>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            {/*<div className="page-section summary">*/}
            {/*    <h2>Sommaire</h2>*/}
            {/*    <ul>*/}
            {/*        <li><a href="#faky" onClick={handleOnClick}>C’est quoi Faky ?</a></li>*/}
            {/*        <li><a href="#tauxfiabilité" onClick={handleOnClick}>Comment le taux de fiabilité est-il calculé ?</a></li>*/}
            {/*        <li><a href="#restezcritiques">Restez critiques !</a></li>*/}
            {/*        <li><a href="#nosobjectifs" onClick={handleOnClick}>Nos objectifs</a></li>*/}
            {/*        <li><a href="#rtbfinitiative" onClick={handleOnClick}>Faky, une initiative de la RTBF</a></li>*/}
            {/*        <li><a href="#équipefaky" onClick={handleOnClick}>L’équipe Faky</a></li>*/}
            {/*        <li><a href="#nospartenaires" onClick={handleOnClick}>Nos partenaires</a></li>*/}
            {/*    </ul>*/}
            {/*</div>*/}

            <div className="page-section" id="faky">
              <h2>{t("who_are_we_page.title.1")}</h2>
              <p>{t("who_are_we_page.paragraph.1")}</p>
              <p>{t("who_are_we_page.paragraph.2")}</p>
              <p>
                {t("who_are_we_page.paragraph.3.1")}
                <NavLink
                  to={`/${i18n.language}/${t(
                    "routes.how_does_it_work_images_route"
                  )}`}
                >
                  {t("who_are_we_page.paragraph.3.nav1")}
                </NavLink>
                {t("who_are_we_page.paragraph.3.2")}
                <NavLink
                  to={`/${i18n.language}/${t(
                    "routes.our_sources_route"
                  )}#decodex`}
                >
                  {t("who_are_we_page.paragraph.3.nav2")}
                </NavLink>
                .
              </p>
              <p>{t("who_are_we_page.paragraph.4")}</p>
            </div>

            <div className="page-section" id="tauxfiabilité">
              <h2>{t("who_are_we_page.title.2")}</h2>
              <p>
                {t("who_are_we_page.paragraph.5.1")}
                <NavLink
                  to={`/${i18n.language}/${t("routes.how_does_it_work_route")}`}
                >
                  {t("who_are_we_page.paragraph.5.nav")}
                </NavLink>
              </p>
            </div>

            <div className="page-section" id="restezcritiques">
              <h2>{t("who_are_we_page.title.3")}</h2>
              <p>{t("who_are_we_page.paragraph.6")}</p>
            </div>

            <div className="page-section" id="nosobjectifs">
              <h2>{t("who_are_we_page.title.4")}</h2>
              <p>{t("who_are_we_page.paragraph.7")}</p>
              <p>
                <Trans i18nKey="who_are_we_page.paragraph.8 " />
              </p>
              <ul>
                <li>
                  <Trans i18nKey="who_are_we_page.paragraph.faky_is.1" />
                </li>
                <li>
                  <Trans i18nKey="who_are_we_page.paragraph.faky_is.2" />
                </li>
                <li>
                  <Trans i18nKey="who_are_we_page.paragraph.faky_is.3" />
                </li>
                <li>
                  <Trans i18nKey="who_are_we_page.paragraph.faky_is.4" />
                </li>
                <li>
                  <Trans i18nKey="who_are_we_page.paragraph.faky_is.5" />
                </li>
                <li>
                  <Trans i18nKey="who_are_we_page.paragraph.faky_is.6.1" />
                  <NavLink
                    to={`/${i18n.language}/${t("routes.privacy_route")}`}
                  >
                    <Trans i18nKey="who_are_we_page.paragraph.faky_is.6.nav1" />
                  </NavLink>
                  {t("who_are_we_page.paragraph.faky_is.6.2")}
                  <NavLink
                    to={`/${i18n.language}/${t("routes.privacy_route")}`}
                  >
                    {t("who_are_we_page.paragraph.faky_is.6.nav2")}
                  </NavLink>
                  {t("who_are_we_page.paragraph.faky_is.6.3")}
                  <NavLink
                    to={`/${i18n.language}/${t("routes.privacy_route")}`}
                  >
                    {t("who_are_we_page.paragraph.faky_is.6.nav3")}
                  </NavLink>
                  {t("who_are_we_page.paragraph.faky_is.6.4")}
                </li>
                <li>
                  <Trans i18nKey="who_are_we_page.paragraph.faky_is.7" />
                </li>
                <li>
                  <Trans i18nKey="who_are_we_page.paragraph.faky_is.8" />
                </li>
              </ul>
              <p>
                <Trans i18nKey="who_are_we_page.paragraph.9" />
              </p>
              <ul>
                <li>
                  <Trans i18nKey="who_are_we_page.paragraph.objectifs.1" />
                </li>
                <li>
                  <Trans i18nKey="who_are_we_page.paragraph.objectifs.2" />
                </li>
              </ul>
              <p>{t("who_are_we_page.paragraph.10")}</p>
              <ul>
                <li>
                  <Trans i18nKey="who_are_we_page.paragraph.objectifs.3" />
                </li>
              </ul>
            </div>
            <a id="rtbf" />
            <div className="page-section" id="rtbfinitiative">
              <h2>{t("who_are_we_page.title.5")}</h2>
              <p>{t("who_are_we_page.paragraph.11")}</p>
              <p>{t("who_are_we_page.paragraph.12")}</p>
            </div>

            <div className="page-section" id="équipefaky">
              <h2>{t("who_are_we_page.title.6")}</h2>
              <p>{t("who_are_we_page.paragraph.13")}</p>
            </div>

            <div className="page-section" id="nospartenaires">
              <h2>{t("who_are_we_page.title.7")}</h2>
              <p>
                {t("who_are_we_page.paragraph.14.1")}
                <NavLink
                  to={`/${i18n.language}/${t("routes.our_sources_route")}`}
                >
                  {t("who_are_we_page.paragraph.14.nav1")}
                </NavLink>
                {t("who_are_we_page.paragraph.14.2")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhoWeArePage;
