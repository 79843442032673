import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Enums} from "../../core";
import {useTranslation} from "react-i18next";
import {TextGainModal} from "./index";
import * as ReactGA from "react-ga";

const TextGainAnalysisContentListRow = ({isDescriptionOpen, mediaLinks, originalScoring}) => {
    const {t, i18n} = useTranslation();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = event => {
        event.preventDefault();
        setModalIsOpen(true);
        ReactGA.modalview(`${i18n.language}/${t('routes.our_sources_route')}/${Enums.AnalysisOrigin.TextGain}`);
    };

    if(mediaLinks.length <= 1) {
        originalScoring = mediaLinks && mediaLinks[0] && mediaLinks[0].hasOwnProperty('originalScoring') ? mediaLinks[0].originalScoring : "";
    }

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="collapsible" style={isDescriptionOpen ? {display: 'block'} : {}}>
            <div className="article-content">
                <div className="article-column left">
                    <div className="article-abstract">                    
                        <h6 className="heading-article">
                            {t(`our_partners`)}
                        </h6>
                        <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.TextGain}.sub_title_logo`)}</p>
                        <a href="#openModal" onClick={openModal}
                           className="more-link">{t(`analysis_list_row.${Enums.AnalysisOrigin.TextGain}.read_more`)}</a>
                    </div>
                </div>

                <div className="article-column right">
                    <h6 className="heading-article">
                        {t(`detail_analysis_text`)}
                    </h6>
                    <div className="article-intro">
                        <p>                        
                            {
                                (mediaLinks.length <= 1) &&
                                t(`analysis_list_row.${Enums.AnalysisOrigin.TextGain}.description.one_url`, originalScoring)
                            }
                            {
                                (mediaLinks.length > 1) &&
                                t(`analysis_list_row.${Enums.AnalysisOrigin.TextGain}.description.multiple_urls`, originalScoring)
                            }
                        </p>
                    </div>
                </div>
            </div>

            <TextGainModal closeModal={closeModal} modalIsOpen={modalIsOpen}/>
        </div>
    );
};

TextGainAnalysisContentListRow.propTypes = {
    isDescriptionOpen: PropTypes.bool,
    mediaLinks: PropTypes.array.isRequired,
};

export default TextGainAnalysisContentListRow;
