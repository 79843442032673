import React from 'react';
import {useTranslation} from "react-i18next";

const CategoriesMenu = () => {
    const {t} = useTranslation();
    return (
        <nav className="categories-menu" style={{display: 'none'}}>
            <h3>{t('categories_menu.title')}</h3>
            <ul>
                <li>
                    <a href={`#${t('categories_menu.category_1')}`}>
                        <span className="category dot unreliable bg"/><span>{t('categories_menu.category_1')}</span>
                    </a>
                </li>
                <li>
                    <a href={`#${t('categories_menu.category_2')}`}>
                        <span
                            className="category dot semi-unreliable bg"/><span>{t('categories_menu.category_2')}</span>
                    </a>
                </li>
                <li>
                    <a href={`#${t('categories_menu.category_3')}`}>
                            <span
                                className="category dot indeterminate bg"/><span>{t('categories_menu.category_3')}</span>
                    </a>
                </li>
                <li>
                    <a href={`#${t('categories_menu.category_4')}`}>
                            <span
                                className="category dot semi-reliable bg"/><span>{t('categories_menu.category_4')}</span>
                    </a>
                </li>
                <li>
                    <a href={`#${t('categories_menu.category_5')}`}>
                            <span
                                className="category dot reliable bg"/><span>{t('categories_menu.category_5')}</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default CategoriesMenu;