import * as types from './actionTypes';

export const getSubjectsSearchTagsSuccess = tags => {
    return {
        type: types.GET_SUBJECTS_SEARCH_TAGS_SUCCESS,
        payload: tags
    };
};

export const updateSearchTags = (tags) => {
    return {
        type: types.UPDATE_SEARCH_TAGS,
        payload: tags
    }
};