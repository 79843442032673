import React from 'react'
import {useTranslation} from "react-i18next";
import {Loader} from "../common";
import {isEdge, isFirefox, isIE, isIOS} from "react-device-detect";

const SearchFieldKeywordForm = (props) => {

    const {
        handleOnclick,
        text,
        handleOnChange,
        className,
        errors,
        handleKeyDown,
        loading,
        handleOnClickClipboard,
        disableSearch
    } = props;

    const {t} = useTranslation();
    const moveCaretAtEnd = (e) => {
        var temp_value = e.target.value
        e.target.value = ''
        e.target.value = temp_value
    };

    return (
        <form id="keyword-form" className={className}>
            <div className="form-label">
                <label>{t("search_field.label2")}</label>
                {(!isIOS && !isFirefox && !isIE && !isEdge) &&
                <button className="button-link" onClick={handleOnClickClipboard}>
                    <span>{t("search_field.copy_url2")}</span>
                </button>}
            </div>
            <div className="form-field">
                <textarea
                    id="search-field"
                    value={text}
                    name="text"
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                    placeholder={t("search_field.placeholder2")}
                    autoFocus
                    onFocus={moveCaretAtEnd}/>

            </div>
            <div className="form-actions">
                <div className="errors">
                    <span className="error">{errors}</span>
                </div>
                <input
                    type="submit"
                    value={t("search_field.button.text2")}
                    onClick={handleOnclick}
                    className={disableSearch ? "disabled" : ""}
                />
            </div>
            <Loader loading={loading}/>
        </form>
    );
};


export default SearchFieldKeywordForm;