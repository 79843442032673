import React from 'react';
import PropTypes from 'prop-types';
import {Enums} from "../../core";
import {CssClassHelpers} from "../../helpers";
import {ScoringListRow} from "./index";
import {useTranslation} from "react-i18next";
import {Loader} from "../common";
import faceIco from '../../assets/images/face-ico.svg';

const ScoringList = ({scoring, loading = false, errorMessage = '', showingScoringTitle = true, isImportImage = false, showingSadFace = true}) => {
    const {t} = useTranslation();
    const scores = Object.values(Enums.Scoring);
    const _isUndetermined = !scores.includes(scoring);
    scoring = _isUndetermined ? null : scoring;
    return (
        <div className="container">
            {errorMessage ? <div className="evaluation no-results">
                    {showingSadFace && <img src={faceIco} alt=""/>}
                    <h3>{errorMessage}</h3>
                </div> :
                <div className={`evaluation category ${CssClassHelpers.mapScoringToCssClass(scoring)} bg`}>
                    {loading && <Loader showInProgressTitle={true} isLoadMore={false}/>}
                    {showingScoringTitle ? scoring === null ?
                        <h3>{t('scoring.undetermined_title')}</h3> :
                        <h3>{t('scoring.title') + t(`scoring.${Enums.getKey(Enums.Scoring, scoring)}`).toLocaleLowerCase()}</h3> : ''}
                </div>
            }
            {!isImportImage && <ul className={"categories"} style={{ 'display': 'none'}}>
                {scores.map((score, index) =>
                    <ScoringListRow key={index} isActive={scoring === score} scoring={score}/>)}
                {!errorMessage && <li className="triangle"/>}
            </ul>}
        </div>
    );
};

ScoringList.propTypes = {
    scoring: PropTypes.number,
    showingScoringTitle: PropTypes.bool,
};

export default ScoringList;
