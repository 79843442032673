import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Enums } from "../../core";
import { useTranslation } from "react-i18next";
import { DDModal } from "./index";
import * as ReactGA from "react-ga";

const DdAnalysisContentListRow = ({ isDescriptionOpen, description, mediaLinks, scoring }) => {
    const { t, i18n } = useTranslation();

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = event => {
        event.preventDefault();
        setModalIsOpen(true);
        ReactGA.modalview(`${i18n.language}/${t('routes.our_sources_route')}/${Enums.AnalysisOrigin.Decodeur}`);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    return (
        <div className="collapsible" style={isDescriptionOpen ? { display: 'block' } : {}}>
            <div className="article-content">
                <div className="article-column left">
                    <div className="article-abstract">
                        <h6 className="heading-article">
                            {t(`our_partners`)}
                        </h6>
                        <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.sub_title_logo`)}</p>
                        <a href="#openModal" onClick={openModal} className="more-link">{t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.read_more`)}</a>
                    </div>
                </div>
                <div className="article-column right">
                    <h6 className="heading-article">
                        {t(`detail_analysis_text`)}
                    </h6>
                    {mediaLinks.length === 1 ?
                        <div className="article-intro">
                            <p>{mediaLinks[0].scoringDescription}</p>
                            <p >
                                {
                                    (scoring < 50) &&
                                    t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.descriptionLessThanScoring50`)
                                }
                                {
                                    (scoring >= 50) &&
                                    t(`analysis_list_row.${Enums.AnalysisOrigin.DD}.descriptionMoreThanScoring50`)
                                }
                            </p>
                        </div>
                        :
                        <div className="article-intro">
                            <p>{description}</p>
                        </div>
                    }
                </div>
            </div>
            <DDModal closeModal={closeModal} modalIsOpen={modalIsOpen} />
        </div>
    );
};

DdAnalysisContentListRow.propTypes = {
    isDescriptionOpen: PropTypes.bool.isRequired,
    description: PropTypes.string,
    mediaLinks: PropTypes.array,
    scoring: PropTypes.number
};

export default DdAnalysisContentListRow;
