import {AppConfig} from "../config";
import {beginGetToken, getTokenSuccess} from "../actions/googleCaptchaActions";
import {networkError} from "../actions/ErrorActions";

const googleUtils = {
    getToken(action, callback, dispatch) {
        dispatch(beginGetToken());
        try {
            let interval = setInterval(function () {
                if (window.grecaptcha) {
                    window.grecaptcha.ready(function () {
                        window.grecaptcha.execute(AppConfig.GR_KEY, {action: action}).then(function (token) {
                            callback(token);
                            dispatch(getTokenSuccess());
                        }, function () {
                            dispatch(networkError());
                        });
                    });
                    clearInterval(interval);
                }
            }, 100);
        } catch {
            dispatch(networkError());
        }
    }
};

export default googleUtils;