import * as types from "./actionTypes";

export const networkError = () => {
    return {
        type: types.NETWORK_ERROR
    };
};

export const tooManyRequestError = () => {
    return {
        type: types.TOO_MANY_REQUEST_ERROR
    };
};


export const setIsTooManyRequestError = (value) => {
    return {
        type: types.SET_TOO_MANY_REQUEST,
        payload: value
    };
};

export const fetchError = (error, type, scope, page = 1) => {
    let action = {
        type: type,
        payload: {
            errors: error
        }
    };

    if (scope) action.payload = {...action.payload, searchName: scope, page: page};

    return action;
};