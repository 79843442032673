import React from 'react';
import {connect} from 'react-redux'
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import Cookies from 'js-cookie';
import {INSTALL_PROMPT_COOKIE_CONSENT_KEY} from "../../core/Constants";
import {setHasClickOnInstallButton, setInstallPromptClosed} from "../../actions/installPromptActions"

import {isMobile} from "react-device-detect";

import { bindActionCreators } from 'redux';


const FakyzationPage = ({setInstallPromptClosed, setHasClickOnInstallButton}) => {
    const {t, i18n} = useTranslation();
    
    const handleOpenPopup = (event) => {
        event.preventDefault();
        Cookies.remove(INSTALL_PROMPT_COOKIE_CONSENT_KEY);
        setInstallPromptClosed(false);
        setHasClickOnInstallButton(true);
    }

    return (
        <>
            <Helmet>
                <title>{t('meta.title.fakyzation')}</title>
                <meta name="title" content={t('meta.title.fakyzation')}/>
                <meta name="description" content={t('meta.description.fakyzation')}/>
            </Helmet>
            <div>
                <div className="page-header">
                    <div className="container">
                        <h1>{t('install_popup_mobile.title.1')}</h1>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="page-section">
                            <p>{t('install_popup_mobile.paragraph.1')}</p>
                        </div>

                        <div className="page-section" id="subsection">
                            <h2>{t('install_popup_mobile.title.2')}</h2>
                            <p>{t('install_popup_mobile.paragraph.2')}</p>
                        </div>
                        {isMobile &&
                            <div className="download-popup-fixed activate-btn-popup">
                                <p className="icon-extra-large"></p>
                                <p className="green-text">{t('install_popup_mobile.paragraph.3')}</p>
                                <p className="white-subtitle">{t('install_popup_mobile.paragraph.4')}</p>
                                <p className="white-subtitle">{t('install_popup_mobile.paragraph.5')}</p>
                                <div className="button-content">
                                    <button className="button-light-green" onClick={handleOpenPopup}>{t('install_popup_mobile.button')}</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setInstallPromptClosed: bindActionCreators(setInstallPromptClosed, dispatch),
        setHasClickOnInstallButton: bindActionCreators(setHasClickOnInstallButton, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(FakyzationPage) ;
