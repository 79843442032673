import React from 'react';
import PropTypes from 'prop-types';
import {Cell, Label, Pie, PieChart} from "recharts";

const ImportImagePieChart = ({imageDetails}) => {

    const defaultData = [
        {name: 'Group A', value: 0},
        {name: 'Group B', value: 10},
        {name: 'Group C', value: 0},
        {name: 'Group D', value: 0},
        {name: 'Group D', value: 0}
    ];

    const data = imageDetails && imageDetails.hasOwnProperty("totalNumberOfMediaLinks") ?
        Object.keys(imageDetails).filter(k => k !== 'totalNumberOfMediaLinks' && k !== 'mainImageUrl').map(k => ({
            name: k,
            value: imageDetails[k].length
        })) : defaultData;

    const COLORS = ['#5DD700', '#F39200', '#E52029', '#A4A4A4', '#58B8D0'];

    const biggestValue = Math.max.apply(Math, data.map(function (o) {
        return o.value;
    }));

    const CustomLabel = ({viewBox, value}) => {
        const {cx, cy} = viewBox;
        const index = data.map(e => e.value).indexOf(value);
        return (
            <text x={cx} y={cy} fill={COLORS[index % COLORS.length]} className="recharts-text recharts-label"
                  textAnchor="middle" dominantBaseline="central">
                <tspan alignmentBaseline="middle" fontSize="30">{value}</tspan>
            </text>
        )
    };

    return (
        <div className="analyzed-image-info-stats-graph">
            <PieChart width={240} height={180}>
                <Pie
                    data={data}
                    dataKey="value"
                    cx={120}
                    cy={90}
                    innerRadius={50}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={0.5}>
                    {data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)}
                    {imageDetails && imageDetails.hasOwnProperty("totalNumberOfMediaLinks") &&
                    <Label width={30} position="center"
                           content={<CustomLabel
                               value={biggestValue}/>}>
                    </Label>}
                </Pie>
            </PieChart>
        </div>
    );
};

ImportImagePieChart.propTypes = {
    imageDetails: PropTypes.object.isRequired,
};

export default ImportImagePieChart;
