import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router';
import { useQuery } from '../../hooks';
import { UrlHelpers } from '../../helpers';
import { SubjectApi } from "../../api";
import { takeStringUntil } from '../../utils/string-utils';

const findUrlFromString = (str) => {
    if(typeof str === "string"){
        let startUrlIndex = str.search(/https?:\/\//i);

        if(startUrlIndex === -1)
            return;

        return takeStringUntil(str, (char) => char !== ' ', startUrlIndex);
    }
};


const getUrlFromQueryString = (query) => {
    //need to check in each parameters where is the url to share. More info why here : https://web.dev/web-share-target/#verifying-shared-content

    for(let parameter of Object.values(query)){
        let url = findUrlFromString(parameter);

        if(UrlHelpers.validUrl(url))
            return url;
    }
};

export const ShareTargetPage = () => {
    const { i18n, t } = useTranslation();

    const query = useQuery();

    let urlToShare = getUrlFromQueryString(query);

    useEffect(() => {
        if(!urlToShare)
            return;

        SubjectApi.triggerImportSubject({url: urlToShare}).then(result => {
            if (result.success) {
                var returnUrl =  `/${i18n.language}/${t("routes.import_status_route")}/${result.correlationId}?searchUrl=${urlToShare}`;
                document.location.href = returnUrl;
            } else {
                document.location.href= `/${i18n.language}/Oups`;
            }
        }).catch(error => {
            document.location.href= `/${i18n.language}/Oups`;
        });
    }, [urlToShare]);

    if(!urlToShare)
        return <Navigate to={`/${i18n.language}/404`} replace={true}/>

    return null;
}

ShareTargetPage.propTypes = {

}

export default ShareTargetPage;