import React from 'react';
import PropTypes from 'prop-types';

const SubjectDetailDescription = ({subject}) => {
    return (
        <div className={'container'}>
            <article className="article-result">
                <div className="article-content">
                    {subject.imageUrl && <div className="article-image">
                        <img src={subject.imageUrl} alt=""/>
                    </div>}
                    <div className="article-intro">
                        <h3>{subject.title}</h3>
                        <p>{subject.description}</p>
                    </div>
                </div>
            </article>
        </div>
    );
};

SubjectDetailDescription.propTypes = {
    subject: PropTypes.object.isRequired,
};

export default SubjectDetailDescription;