import React, {useEffect, useState} from 'react';
import {SearchResult} from "../searchResult";
import {SubjectResult} from "../subject";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {GoogleService, ScrollService, SearchService} from "../../services";
import {searchSubjects} from "../../actions/subjectActions";
import {connect} from "react-redux";
import {Enums} from "../../core";
import {updateSearchTags} from "../../actions/tagActions";
import {updateSearchOptions} from "../../actions/searchOptionsActions";
import {ListHelpers} from "../../helpers";

const AnalyseImageTextPage = (props) => {
    const {
        subjects,
        searchSubjects,
        dispatch,
        tags,
        search,
        searchOptions,
        subjectsCount,
        updateSearchTags,
        updateSearchOptions
    } = props;

    const {t} = useTranslation();
    const {searchText} = useParams();
    const [state, setState] = useState({subjects: [], loading: false});

    const imageText = (searchText && searchText.replace(/_/g, ' ')) || "";

    useEffect(() => {
        if (imageText) {
            GoogleService.getToken('searchSubjects', (recaptchaToken) => {
                searchSubjects({
                    recaptchaToken,
                    searchOptions: {pageNumber: 1, pageSize: 3},
                    text: imageText,
                    url: ""
                });
            }, dispatch);
        }
    }, [imageText]);

    useEffect(() => {
        if (subjects.length > 0 && ListHelpers.getDifferences(subjects, state.subjects).length > 0) {
            setState({subjects: [...subjects], loading: false});
        }
    }, [subjects]);

    useEffect(() => {
        if (search && search[1].isSuccess && searchOptions.pageNumber === 1) {
            setTimeout(() => {
                ScrollService.scrollToElement("finder-results");
            }, 350);
        }
    }, [search]);

    useEffect(() => {
        return () => {
            updateSearchTags([]);
            updateSearchOptions({pageSize: 3, pageNumber: 1})
        };
    }, []);

    const handleOnClick = event => {
        event.preventDefault();
        let _searchOptions = {...searchOptions};
        if (Math.ceil(subjectsCount / _searchOptions.pageSize) >= _searchOptions.pageNumber + 1) {
            _searchOptions.pageNumber += 1;
            setState({...state, loading: true});
            updateSearchOptions({..._searchOptions});
            GoogleService.getToken('searchSubjects', (recaptchaToken) => {
                searchSubjects({
                    recaptchaToken,
                    searchOptions: {..._searchOptions},
                    text: imageText,
                    url: ""
                });
            }, dispatch);
        }
    }

    const disableButtonLoadMore = !(Math.ceil(subjectsCount / searchOptions.pageSize) >= searchOptions.pageNumber + 1);

    return (
        <>
            <section id="finder-results" className="grey-section">
                <h2>{t('search_result.title')}</h2>
                <SearchResult text={imageText} tags={tags} isFetching={search && search[1].isFetching}
                              showBackButton={search && search[1].isSuccess}/>
            </section>

            {(state.subjects && state.subjects.length > 0) &&
            <SubjectResult disableButtonLoadMore={disableButtonLoadMore}
                           loading={state.loading}
                           subjects={state.subjects}
                           handleOnClick={handleOnClick} source="ResultList"/>}

            {(state.subjects.length === 0 && tags.length > 0 && (search && !search[1].isFetching)) &&
            <aside id="results-evaluation">
                <div className="container">
                    <div className="evaluation no-results">
                        <h3>{t('subjects_page.no_results_message')}</h3>
                    </div>
                </div>
            </aside>}
        </>
    );
};

const mapStateToProps = (state) => {
    const pathname = window.location.pathname;
    let searchTextUrl = pathname.replace(window.location.search, '');
    const pathnameWillRemoveIndex = searchTextUrl.lastIndexOf("/");

    searchTextUrl = searchTextUrl.slice(-1, pathnameWillRemoveIndex)

    const searchText = (searchTextUrl && searchTextUrl.replace(/_/g, ' ')) || "";
    const scope = Enums.Scopes.SearchText + '_' + searchText;
    let {subjects, subjectsCount} = SearchService.getSubjects(state, scope, state.searchOptions.pageNumber);

    return {
        searchParams: state.searchParams,
        searchOptions: state.searchOptions,
        subjects: subjects,
        subjectsCount: subjectsCount,
        tags: state.tags,
        search: state.searches[scope]
    };
};

const mapDispatchToProps = dispatch => {
    return {
        searchSubjects: request => dispatch(searchSubjects(request)),
        updateSearchTags: request => dispatch(updateSearchTags(request)),
        updateSearchOptions: request => dispatch(updateSearchOptions(request)),
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseImageTextPage);