import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {CssClassHelpers} from "../../helpers";
import {useTranslation} from "react-i18next";
import {take} from "lodash";
import {ImportImageDomainUrlList} from "./";
import {ScrollService} from "../../services";

const ImportImageDomainListRow = ({propertyName, totalNumber, sources, visible, setVisible, setSourcesScroll, scroll}) => {
    const {t} = useTranslation();
    const defaultPageNumber = 1;
    const defaultPageSize = 5;
    const pageSize = 20;
    const [values, setValues] = useState({
        filteredUrl: take(sources.map(source => source.url), defaultPageSize),
        pageSize: defaultPageSize,
        pageNumber: defaultPageNumber
    });

    useEffect(() => {
        if ((visible || scroll) && totalNumber > 0) {
            setSourcesScroll(propertyName, false);
            ScrollService.scrollToElement(`domain-list-${propertyName}`);
        }
    }, [visible, scroll]);

    const handleOnClick = (event) => {
        event.preventDefault();
        const {pageNumber, pageSize} = values;
        setValues({
            ...values, filteredUrl: take(sources.map(source => source.url), pageSize * pageNumber)
        });
        setVisible(propertyName, !visible);
    };

    const handleOnClickShowMore = (event) => {
        event.preventDefault();
        const {pageNumber} = values;
        let newPageNumber = pageNumber + 1;
        setValues({
            ...values,
            filteredUrl: take(sources.map(source => source.url), (pageSize * newPageNumber) + defaultPageSize),
            pageNumber: newPageNumber,
            pageSize: pageSize
        });
    };

    const disableButtonLoadMore = !(Math.ceil(totalNumber / values.pageSize) >= values.pageNumber + 1);

    return (
        <li className="analyzed-image-detailed-item">
            <div id={`domain-list-${propertyName}`}
                 className={`analyzed-image-detailed-${CssClassHelpers.mapImportImagePropertyNameToCssClass(propertyName)}`}
                 onClick={handleOnClick}>
                <p>{t(`import_image.domain_title.${propertyName}`)} <span>({totalNumber})</span></p>
                <button
                    className={visible ? "button-toggle-description description-open" : "button-toggle-description"}
                    onClick={handleOnClick}>Toggle description
                </button>
            </div>
            {(visible && sources.length > 0) &&
            <ImportImageDomainUrlList disableButtonLoadMore={disableButtonLoadMore}
                                      handleOnClickShowMore={handleOnClickShowMore}
                                      sources={sources}
                                      filteredUrl={values.filteredUrl}/>}
        </li>
    );
};

ImportImageDomainListRow.propTypes = {
    propertyName: PropTypes.string.isRequired,
    totalNumber: PropTypes.number.isRequired,
};

export default ImportImageDomainListRow;
