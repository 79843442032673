import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {updateSearchOptions} from "../../actions/searchOptionsActions";
import {bindActionCreators} from "redux";
import {getImageStatus, getImportImageSubject} from "../../actions/importImageActions";
import {setSearchButtonDisabled} from '../../actions/importSubjectActions';
import {ListHelpers} from "../../helpers";
import {ImportService, SearchService} from "../../services";
import {AppConfig} from "../../config";
import {Enums} from "../../core";
import {isEqual} from "lodash";
import {ImportImageDomainList} from "./index";
import {SearchResult} from "../searchResult";
import {ScoringList} from "../scoring";

class ImportImagePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            importImage: {...this.props.importImage},
            showLoader: true,
            loading: true,
            errorMessage: '',
            subject: {...this.props.subject},
            imageDetails: {...this.props.imageDetails},
            triggeredImage: {...this.props.triggeredImage},
            sourcesVisibility: {},
            sourcesScroll: {}
        };
        this.interval = null;
        this.timeout = null;
        this.getStatus = this.getStatus.bind(this);
        this.setIntervalAndTimeout = this.setIntervalAndTimeout.bind(this);
        this.clearIntervalAndTimeout = this.clearIntervalAndTimeout.bind(this);
    }

    getStatus() {
        this.props.getImageStatus(this.state.triggeredImage.correlationId);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.subject.id && !isEqual(props.subject, state.subject)) {
            return {subject: {...props.subject}};
        }

        if (props.imageDetails && !isEqual(props.imageDetails, state.imageDetails)) {
            let sourcesVisibility = {};
            let sourcesScroll = {};

            Object.keys(props.imageDetails).filter(k => k !== 'totalNumberOfMediaLinks' && k !== 'mainImageUrl').forEach(property => {
                sourcesVisibility[property] = false;
                sourcesScroll[property] = false
            });

            return {
                imageDetails: {...props.imageDetails},
                sourcesVisibility,
                sourcesScroll
            };
        }

        if (ListHelpers.getDifferences(state.importImage.status, props.importImage.status).length > 0) {
            return ImportService.processStatus(props, state);
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.clearInterval) {
            this.setState({clearInterval: false});
            this.clearIntervalAndTimeout();
        }

        if (prevProps.triggeredImage.correlationId !== this.props.triggeredImage.correlationId) {
            this.setState({
                importImage: {...this.props.importImage},
                showLoader: true,
                loading: true,
                errorMessage: '',
                triggeredImage: {...this.props.triggeredImage}
            });
            this.clearIntervalAndTimeout();
            this.setIntervalAndTimeout();
        }
    }

    componentDidMount() {
        this.setIntervalAndTimeout();
    }

    clearIntervalAndTimeout() {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        this.interval = null;
        this.timeout = null;
    }

    setIntervalAndTimeout() {
        const {t} = this.props;
        this.interval = setInterval(this.getStatus, AppConfig.IMPORT_IMAGE_INTERVAL[0]);
        this.timeout = setTimeout(() => {
            clearInterval(this.interval);
            this.interval = setInterval(this.getStatus, AppConfig.IMPORT_IMAGE_INTERVAL[1]);
            this.timeout = setTimeout(() => {
                clearInterval(this.interval);
                let errorMessage = t('import_image_page.timeout_error_message');
                this.setState({showLoader: false, errorMessage: errorMessage,});
            }, AppConfig.IMPORT_IMAGE_TIMEOUT[1]);
        }, AppConfig.IMPORT_IMAGE_TIMEOUT[0]);


    }

    componentWillUnmount() {
        this.clearIntervalAndTimeout();
        this.props.updateSearchOptions({pageSize: 3, pageNumber: 1});
        this.setState({importImage: {status: [], triggeredImage: {correlationId: 0}}});
    }

    handleStatsListOnClick = (event) => {
        const {sourcesScroll} = this.state;
        this.setState({
            sourcesScroll: {
                ...sourcesScroll,
                [event.target.dataset.value]: true
            }
        });
    };

    setSourcesVisibility = (propertyName, value) => {
        this.setState({sourcesVisibility: {...this.state.sourcesVisibility, [propertyName]: value}});
    };

    setSourcesScroll = (propertyName, value) => {
        this.setState({
            sourcesScroll: {...this.state.sourcesScroll, [propertyName]: value},
            sourcesVisibility: {...this.state.sourcesVisibility, [propertyName]: true}
        });
    };


    render() {

        const {showLoader, errorMessage, sourcesVisibility, sourcesScroll, subject} = this.state;
        const {t, imageDetails, triggeredImage} = this.props;

        let displayLoader = !showLoader ? !(imageDetails && imageDetails.hasOwnProperty("totalNumberOfMediaLinks")) : showLoader;

        return (
            <>
                <section id="finder-results" className="grey-section">
                    <h2>{t('search_result.title')}</h2>
                    <SearchResult image={imageDetails} triggeredImage={triggeredImage}
                                  textDetected={subject.description}
                                  imageOnClick={this.handleStatsListOnClick}/>
                </section>

                {!(imageDetails && imageDetails.hasOwnProperty("totalNumberOfMediaLinks")) &&
                <section id="results-evaluation">
                    <ScoringList scoring={0} loading={displayLoader} showingScoringTitle={false}
                                 errorMessage={errorMessage} isImportImage={true}/>
                </section>}

                {(imageDetails && imageDetails.hasOwnProperty("totalNumberOfMediaLinks")) &&
                <div className="container">
                    <article className="analyzed-image">
                        <ImportImageDomainList imageDetails={imageDetails} sourcesVisibility={sourcesVisibility}
                                               sourcesScroll={sourcesScroll}
                                               setSourcesVisibility={this.setSourcesVisibility}
                                               setSourcesScroll={this.setSourcesScroll}/>
                    </article>
                </div>}
            </>
        );
    }
}

ImportImagePage.propTypes = {};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSearchOptions: bindActionCreators(updateSearchOptions, dispatch),
        getImportImageSubject: bindActionCreators(getImportImageSubject, dispatch),
        getImageStatus: bindActionCreators(getImageStatus, dispatch),
        setSearchButtonDisabled: bindActionCreators(setSearchButtonDisabled, dispatch)
    };
};

const mapStateToProps = (state, ownProps) => {
    let _importImage = {...state.importImage};
    let _triggeredImage = {..._importImage.triggeredImage};

    if (!_triggeredImage.correlationId && ownProps.match.params.correlationId) {
        _triggeredImage.correlationId = ownProps.match.params.correlationId;
    }

    let _subject = {scoring: 0};
    let _imageDetails = {};
    if (_importImage.status && _importImage.status.length > 0) {
        const _detailScope = Enums.Scopes.SearchImportImageSubject + '_' + _importImage.status[0].subjectId;
        _subject = SearchService.getSubject(state, _detailScope);

        _imageDetails = state.imageDetails.byId[_subject.id] ? state.imageDetails.byId[_subject.id] : {};
    }

    return {
        importImage: _importImage,
        subject: _subject,
        imageDetails: _imageDetails,
        triggeredImage: _triggeredImage
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ImportImagePage));
