import {combineReducers} from 'redux';
import searchOptions from './searchOptionsReducer';
import searchParams from './searchParamsReducer';
import subjects from './subjectsReducer';
import searches from './searchesReducer';
import tags from './tagReducer';
import importSubject from './importSubjectReducer';
import importImage from './importImageReducer';
import links from './linksReducer';
import errors from './errorsReducer';
import imageDetails from './imageDetailsReducer';
import network from './networkReducer';
import google from './googleCaptchaReducer';
import installPrompt from "./installPromptReducer";

const rootReducer = combineReducers({
    subjects,
    searches,
    searchOptions,
    searchParams,
    tags,
    importSubject,
    links,
    errors,
    importImage,
    imageDetails,
    network,
    google,
    installPrompt
});

export default rootReducer;
