import axios from "axios";
import {AppConfig} from "../config";

class StatisticsApi {
    static url = AppConfig.API_URL;

    static async addStatistic(type, action, target, subjectsIds) {
        return await axios.post(`${this.url}/Statistics`, {
            Statistic: {
                type: type,
                action: action,
                target: target,
                subjectsIds: subjectsIds
            }
        });
    }
}

export default StatisticsApi;