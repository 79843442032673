import React from 'react';
import {ShareSectionDesktop, ShareSectionMobile} from "./index";

const ShareSection = () => {
    return (
        <>
            <ShareSectionMobile/>
            <ShareSectionDesktop/>
        </>
    );
};

export default ShareSection;