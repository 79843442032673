import initialState from "./initialState";
import * as types from '../actions/actionTypes';
import {isEqual} from "lodash";

export default function imageDetailsReducer(state = initialState.imageDetails, action) {
    if (action.type === types.GET_IMAGE_DETAILS_SUCCESS) {
        let _imageDetailsById = {...state.byId};
        let _allIds = [...state.allIds];
        for (let imageDetail of action.payload.elements) {
            if (_allIds.includes(action.payload.subjectId) && isEqual(imageDetail, _imageDetailsById[action.payload.subjectId])) continue;
            _imageDetailsById = {
                ..._imageDetailsById,
                [action.payload.subjectId]: imageDetail
            };
            _allIds.push(action.payload.subjectId);
        }
        return {
            ...state,
            byId: {..._imageDetailsById},
            allIds: [..._allIds]
        };
    }
    return state;
}
