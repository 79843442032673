import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const PrivacyPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("meta.title.privacy")}</title>
        <meta name="title" content={t("meta.title.privacy")} />
        <meta name="description" content={t("meta.description.privacy")} />
      </Helmet>
      <div>
        <div className="page-header">
          <div className="container">
            <h1>{t("privacy_page.title.1")}</h1>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="page-section">
              <p>{t("privacy_page.paragraph.1")}</p>
              <p>{t("privacy_page.paragraph.2")}</p>
              <h2>{t("privacy_page.title.2")}</h2>

              <p>{t("privacy_page.paragraph.3")}</p>
              <p>{t("privacy_page.paragraph.4")}</p>
              <p>{t("privacy_page.paragraph.5")}</p>
              <p>{t("privacy_page.paragraph.6")}</p>
              <p>{t("privacy_page.paragraph.7")}</p>
              <p>{t("privacy_page.paragraph.8")}</p>
            </div>

            <div className="page-section">
              <h2>{t("privacy_page.title.3")}</h2>

              <p>{t("privacy_page.paragraph.9")}</p>

              <h3> {t("privacy_page.title.4")} </h3>

              <p>{t("privacy_page.paragraph.10")}</p>
              <ul>
                <li>{t("privacy_page.paragraph.technical_cookie.1")}</li>

                <li>{t("privacy_page.paragraph.technical_cookie.2")}</li>
              </ul>

              <p></p>
              <p>{t("privacy_page.paragraph.11")}</p>
              <ul>
                <li>{t("privacy_page.paragraph.technical_cookie.3")}</li>

                <li>{t("privacy_page.paragraph.technical_cookie.4")}</li>

                <li>{t("privacy_page.paragraph.technical_cookie.5")}</li>

                <li>{t("privacy_page.paragraph.technical_cookie.6")}</li>
              </ul>

              <p></p>
              <p>{t("privacy_page.paragraph.12")}</p>
              <p>{t("privacy_page.paragraph.13")}</p>

              <h3> {t("privacy_page.title.5")} </h3>

              <p>{t("privacy_page.paragraph.14")}</p>
              <p>{t("privacy_page.paragraph.15")}</p>
              <p>{t("privacy_page.paragraph.16")}</p>
              <p>{t("privacy_page.paragraph.17")}</p>
              <p>{t("privacy_page.paragraph.18")}</p>

              <h3> {t("privacy_page.title.6")} </h3>

              <p>{t("privacy_page.paragraph.19")}</p>
              <p>{t("privacy_page.paragraph.20")}</p>
              <p>{t("privacy_page.paragraph.21")}</p>
              <p>{t("privacy_page.paragraph.22")}</p>
              <ul>
                <li>
                  {t("privacy_page.paragraph.social_media.facebook.1")}
                  <a
                    href="https://www.facebook.com/about/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("privacy_page.paragraph.social_media.facebook.2")}
                  </a>
                </li>

                <li>
                  {t("privacy_page.paragraph.social_media.twitter.1")}
                  <a
                    href="https://twitter.com/privacy?lang=fr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("privacy_page.paragraph.social_media.twitter.2")}
                  </a>
                </li>

                <li>
                  {t("privacy_page.paragraph.social_media.google.1")}
                  <a
                    href="https://support.google.com/accounts/answer/61416?hl=fr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("privacy_page.paragraph.social_media.google.2")}
                  </a>
                </li>

                <li>
                  {t("privacy_page.paragraph.social_media.linkedin.1")}
                  <a
                    href="https://www.linkedin.com/legal/cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("privacy_page.paragraph.social_media.linkedin.2")}
                  </a>
                </li>

                <li>
                  {t("privacy_page.paragraph.social_media.yahoo.1")}
                  <a
                    href="https://consent.yahoo.com/collectConsent?sessionId=3_cc-session_d1bc9fae-ddec-4cb8-98a6-1aa3f6c207a1&lang=&inline=false"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("privacy_page.paragraph.social_media.yahoo.2")}
                  </a>
                </li>

                <li>
                  {t("privacy_page.paragraph.social_media.youtube.1")}
                  <a
                    href="https://support.google.com/accounts/answer/61416?hl=fr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("privacy_page.paragraph.social_media.youtube.2")}
                  </a>
                </li>
              </ul>

              <p></p>
              <p>{t("privacy_page.paragraph.23")}</p>

              <h3> {t("privacy_page.title.7")} </h3>

              <p>{t("privacy_page.paragraph.24")}</p>
              <p>{t("privacy_page.paragraph.25")}</p>

              <div className="page-section">
                <h2> {t("privacy_page.title.8")}</h2>

                <p>{t("privacy_page.paragraph.26")}</p>
                <p>{t("privacy_page.paragraph.27")}</p>
                <p>{t("privacy_page.paragraph.28")}</p>
                <ul>
                  <li>{t("privacy_page.paragraph.cookie_choices.1")}</li>
                </ul>

                <p></p>
                <p>{t("privacy_page.paragraph.29")}</p>
                <p>{t("privacy_page.paragraph.30")}</p>
                <p>{t("privacy_page.paragraph.31")}</p>
                <ul>
                  <li>{t("privacy_page.paragraph.navigator_config.1")}</li>

                  <li>{t("privacy_page.paragraph.navigator_config.2")}</li>
                </ul>

                <p></p>

                <h3> {t("privacy_page.title.9")} </h3>

                <p>{t("privacy_page.paragraph.32")}</p>
                <p>{t("privacy_page.paragraph.33")}</p>

                <h3>{t("privacy_page.title.10")}</h3>

                <p>{t("privacy_page.paragraph.34")}</p>
                <p>{t("privacy_page.paragraph.35")}</p>
                <p>
                  {t("privacy_page.paragraph.36")}
                  <a
                    href="http://www.youronlinechoices.com/be-fr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.youronlinechoices.com/be-fr
                  </a>
                </p>

                <h3>{t("privacy_page.title.11")}</h3>

                <p>{t("privacy_page.paragraph.37")}</p>
                <ul>
                  <li>
                    {t("privacy_page.paragraph.navigators.1")}
                    <a
                      href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
                    </a>
                  </li>

                  <li>
                    {t("privacy_page.paragraph.navigators.2")}
                    <a
                      href="http://help.apple.com/safari/mac/9.0/#/sfri11471"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://help.apple.com/safari/mac/9.0/#/sfri11471
                    </a>
                  </li>

                  <li>
                    {t("privacy_page.paragraph.navigators.3")}
                    <a
                      href="http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647
                    </a>
                  </li>

                  <li>
                    {t("privacy_page.paragraph.navigators.4")}
                    <a
                      href="http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies
                    </a>
                  </li>

                  <li>
                    {t("privacy_page.paragraph.navigators.5")}
                    <a
                      href="http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://help.opera.com/Windows/10.20/fr/cookies.html
                    </a>
                  </li>

                  <li>{t("privacy_page.paragraph.navigators.6")}</li>
                </ul>
                <p>
                  {t("privacy_page.paragraph.general_terms.1")}
                  <NavLink
                    to={`/${i18n.language}/${t("routes.legal_mention_route")}`}
                  >
                    {t("privacy_page.paragraph.general_terms.navlink")}
                  </NavLink>{" "}
                  {t("privacy_page.paragraph.general_terms.2")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPage;
