import React from 'react';
import {ScoringExplanation, ScoringList} from "../scoring";
import {AnalysisList} from "../analysis";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {AppConfig} from "../../config";
import {Enums} from "../../core";
import {isEqual} from "lodash";
import {ListHelpers} from "../../helpers";
import {SubjectResult} from "../subject";
import {ImportService, SearchService} from "../../services";
import {SearchResult} from "../searchResult";
import {updateSearchOptions} from "../../actions/searchOptionsActions";
import {getSimilarSubjects} from "../../actions/subjectActions";
import {getImportSubject, getStatus, setSearchButtonDisabled} from "../../actions/importSubjectActions";
import {getEmbed} from "../../actions/linkActions";
import * as ReactGA from "react-ga";
import {ShareSection} from "../ShareSection";

class ImportSubjectPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            importSubject: {...this.props.importSubject},
            subject: {...this.props.subject},
            similarSubjects: [...this.props.similarSubjects],
            showLoader: true,
            loading: true,
            errorMessage: '',
            link: {...this.props.link},
            showingSadFace: true
        };
        this.interval = null;
        this.timeout = null;
        this.getStatus = this.getStatus.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.setIntervalAndTimeout = this.setIntervalAndTimeout.bind(this);
        this.clearIntervalAndTimeout = this.clearIntervalAndTimeout.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.similarSubjects.length > 0 && ListHelpers.getDifferences(props.similarSubjects, state.similarSubjects).length > 0) {
            return {similarSubjects: [...props.similarSubjects], loading: false};
        }

        if (props.subject && props.subject.id && !isEqual(props.subject, state.subject)) {
            return {subject: {...props.subject}};
        }

        if (props.link && props.link.hasOwnProperty("id") && !isEqual(props.link, state.link)) {
            return {link: {...props.link}};
        }

        if (ListHelpers.getDifferences(state.importSubject.status, props.importSubject.status).length > 0) {
            const newState = ImportService.processStatus(props, state);

            if (newState.clearInterval && !newState.errorMessage) {
                const {similarSubjectsCount, importSubject, getImportSubject} = props;
                getImportSubject(importSubject.status[0].subjectId, similarSubjectsCount === 0, "", true);
            }

            return newState;
        }

        return null;
    }

    getStatus() {
        this.props.getStatus(this.state.importSubject.correlationId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.clearInterval) {
            this.setState({clearInterval: false});
            this.clearIntervalAndTimeout();
        }

        if (prevProps.importSubject.correlationId !== this.props.importSubject.correlationId) {
            this.setState({
                importSubject: {...this.props.importSubject},
                subject: {scoring: 0},
                similarSubjects: [],
                showLoader: true,
                loading: true,
                errorMessage: '',
                link: {}
            });
            this.clearIntervalAndTimeout();
            this.setIntervalAndTimeout();
        }
    }

    componentDidMount() {
        this.setIntervalAndTimeout();
        if (this.state.importSubject.status.length > 0) {
            this.setState(ImportService.processStatus(this.props, this.state));
        }
    }

    clearIntervalAndTimeout() {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        this.interval = null;
        this.timeout = null;
    }

    setIntervalAndTimeout() {
        const {t} = this.props;
        this.interval = setInterval(this.getStatus, AppConfig.IMPORT_SUBJECT_INTERVAL[0]);
        this.timeout = setTimeout(() => {
            clearInterval(this.interval);
            this.interval = setInterval(this.getStatus, AppConfig.IMPORT_SUBJECT_INTERVAL[1]);
            this.timeout = setTimeout(() => {
                clearInterval(this.interval);
                let errorMessage = t('import_subject_page.timeout_error_message');
                if (this.state.subject.id) {
                    errorMessage = "";
                    ReactGA.event({
                        category: 'ImportUrl',
                        action: 'Result',
                        label: this.state.subject.analyses.length > 0 ? `${this.state.subject.analyses.length} Analysis` : 'No Analysis'
                    });
                }
                this.setState({showLoader: false, errorMessage: errorMessage});
                this.props.setSearchButtonDisabled(false);

                if (!importSubject?.status) return;

                const {similarSubjectsCount, importSubject, getImportSubject} = this.props;
                getImportSubject(importSubject.status[0].subjectId, similarSubjectsCount === 0, "", true);
            }, AppConfig.IMPORT_SUBJECT_TIMEOUT[1]);
        }, AppConfig.IMPORT_SUBJECT_TIMEOUT[0]);
    }

    componentWillUnmount() {
        this.clearIntervalAndTimeout();
        this.props.updateSearchOptions({pageSize: 3, pageNumber: 1});
    }

    handleOnClick(event) {
        event.preventDefault();
        const {similarSubjectsCount} = this.props;
        let _searchOptions = {...this.props.searchOptions};
        if (Math.ceil(similarSubjectsCount / _searchOptions.pageSize) >= _searchOptions.pageNumber + 1) {
            _searchOptions.pageNumber += 1;
            this.setState({loading: true});
            this.props.updateSearchOptions({..._searchOptions});
            this.props.getSimilarSubjects(this.state.subject.id, {
                searchOptions: _searchOptions,
                tags: [...this.props.subject.tags]
            });
        }
    }

    render() {
        const {showLoader, loading, similarSubjects, subject, errorMessage, link, showingSadFace} = this.state;
        const {t, similarSubjectsCount, searchOptions, searchParams, search} = this.props;
        const disableButtonLoadMore = !(Math.ceil(similarSubjectsCount / searchOptions.pageSize) >= searchOptions.pageNumber + 1);

        const showExplanationText = subject.scoring || (subject.scoring === 0 && subject.analyses && subject.analyses.length > 0);

        let scoredAnalysis = 0;
        if (subject.analyses) {
            scoredAnalysis = subject.analyses.filter(analysis => analysis.scoring || analysis.scoring === 0).length;
        }


        return (
            <>
                {searchParams.url && <section id="finder-results" className="grey-section">
                    <h2>{t('search_result.title')}</h2>
                    <SearchResult url={searchParams.url} link={link}/>
                </section>}

                <section id="results-evaluation"
                         style={{'display': (showExplanationText && scoredAnalysis > 0 && link) ? 'none' : 'block'}}>
                    <ScoringList scoring={subject.scoring} loading={showLoader} errorMessage={errorMessage}
                                 showingScoringTitle={subject.analyses !== undefined && subject.analyses.length > 0}
                                 showingSadFace={showingSadFace && !search.isFetching}/>
                </section>

                {(subject.analyses && subject.analyses.length > 0) && <section id="partner-analyses">
                    {(subject.analyses && subject.analyses.length > 0) &&
                    <h2>{t('subject_detail_page.analyses_title')} {t('subject_detail_page.outil', {
                        count: scoredAnalysis,
                        second_count: subject.analyses.length
                    })}</h2>}
                    {showExplanationText && <ScoringExplanation analysesLength={subject.analyses.length}/>}
                    <AnalysisList analyses={subject.analyses}/>
                </section>}

                {(subject.analyses && subject.analyses.length > 0) && <ShareSection/>}

                {(similarSubjects && similarSubjects.length > 0) &&
                <SubjectResult disableButtonLoadMore={disableButtonLoadMore} loading={loading}
                               similarSubjects={similarSubjects} handleOnClick={this.handleOnClick}
                               subjects={similarSubjects} isSimilarSubjects={true} source="Similar"/>}

            </>
        );
    }
}

ImportSubjectPage.propTypes = {};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSearchOptions: bindActionCreators(updateSearchOptions, dispatch),
        getSimilarSubjects: bindActionCreators(getSimilarSubjects, dispatch),
        getImportSubject: bindActionCreators(getImportSubject, dispatch),
        getStatus: bindActionCreators(getStatus, dispatch),
        getEmbed: bindActionCreators(getEmbed, dispatch),
        setSearchButtonDisabled: bindActionCreators(setSearchButtonDisabled, dispatch)
    };
};

const mapStateToProps = (state) => {
    const pathname = window.location.pathname;
    let textUrlId = pathname.replace(window.location.search, '');
    const pathnameWillRemoveIndex = textUrlId.lastIndexOf("/");

    textUrlId = textUrlId.slice(pathnameWillRemoveIndex + 1, -1);

    let _importSubject = {...state.importSubject};

    if (!_importSubject.correlationId && textUrlId) {
        _importSubject.correlationId = textUrlId;
    }

    let _subject = {scoring: 0};
    let _subjects = [];
    let _subjectsCount = 0;
    let _link = undefined;
    let search = {isFetching: false};
    if (_importSubject.status && _importSubject.status.length > 0) {
        const _detailScope = Enums.Scopes.SearchImportSubject + '_' + _importSubject.status[0].subjectId;
        _subject = SearchService.getSubject(state, _detailScope);

        if (state.searches.hasOwnProperty(_detailScope)) {
            search = state.searches[_detailScope][1];
        }

        const _similarScope = Enums.Scopes.SearchSimilar + '_' + _importSubject.status[0].subjectId;
        let {subjects, subjectsCount} = SearchService.getSubjects(state, _similarScope, state.searchOptions.pageNumber);
        _subjects = subjects;
        _subjectsCount = subjectsCount;

        const _linkScope = Enums.Scopes.SearchLink + '_' + encodeURIComponent(state.searchParams.url);
        _link = SearchService.getLink(state, _linkScope);
    }

    return {
        importSubject: _importSubject,
        similarSubjects: _subjects,
        similarSubjectsCount: _subjectsCount,
        searchOptions: state.searchOptions,
        subject: _subject,
        searchParams: state.searchParams,
        link: _link,
        search
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ImportSubjectPage));
