import initialState from "./initialState";
import {UPDATE_SEARCH_OPTIONS} from "../actions/actionTypes";

export default function searchOptionsReducer(state = initialState.searchOptions, action) {
    if (action.type === UPDATE_SEARCH_OPTIONS) {
        return {
            ...state,
            ...action.payload
        }
    }

    return state;
}