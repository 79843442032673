import {UrlHelpers} from "../helpers";
import {Enums} from "../core";

const SearchService = {
    getSubjects({searches, subjects}, scope, page = 1) {
        let _subjects = [];
        let _subjectsCount = 0;
        if (searches.hasOwnProperty(scope) && searches[scope].hasOwnProperty(page) && searches[scope][page].isSuccess) {
            let _subjectIds = [];
            for (let i = 1; i <= page; i++) {
                _subjectIds = [..._subjectIds, ...searches[scope][i].elements]
            }
            _subjects = _subjectIds.map((id) => subjects.byId[id]);
            _subjectsCount = searches[scope].elementsCount;
        }
        return {subjects: _subjects, subjectsCount: _subjectsCount}
    },
    getSubject({searches, subjects}, scope) {
        let _subject = {scoring: 0};
        if (searches.hasOwnProperty(scope) && searches[scope][1].isSuccess) {
            let _subjectIds = searches[scope][1].elements;
            _subject = _subjectIds.map((id) => subjects.byId[id])[0];
        }
        return _subject;
    },
    getLink({searches, links}, scope) {
        let _link = {};
        if (searches.hasOwnProperty(scope) && searches[scope][1].isSuccess) {
            let _linkIds = searches[scope][1].elements;
            _link = _linkIds.map((id) => links.byId[id])[0];
        }
        return _link;
    },
    getScope({searchParams}, searchText = '') {
        let _searchParams = {...searchParams};
        if (searchText) {
            let _searchText = decodeURIComponent(searchText);
            let _isUrl = UrlHelpers.validUrl(_searchText);

            if (!_isUrl && _searchParams.url === '' && _searchParams.text !== decodeURIComponent(searchText)) {
                _searchParams.text = decodeURIComponent(searchText);
            }

            if (_isUrl && _searchParams.url !== decodeURIComponent(searchText)) {
                _searchParams.url = decodeURIComponent(searchText);
            }
        }

        const _scope = _searchParams.text !== '' ? Enums.Scopes.SearchText + '_' + _searchParams.text : Enums.Scopes.SearchUrl + '_' + _searchParams.url;

        return {scope: _scope, searchParams: _searchParams};
    }
};

export default SearchService;
