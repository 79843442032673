import React from 'react';
import PropTypes from 'prop-types';
import {ImportImageDomainListRow} from "./";


const ImportImageDomainList = ({imageDetails, sourcesVisibility, setSourcesVisibility, setSourcesScroll, sourcesScroll}) => {
    return (
        <ul className="analyzed-image-detailed">
            {imageDetails && imageDetails.hasOwnProperty('totalNumberOfMediaLinks') && Object.keys(imageDetails).filter(k => k !== 'totalNumberOfMediaLinks' && k !== 'mainImageUrl').map(property => {
                return <ImportImageDomainListRow key={property} propertyName={property}
                                                 totalNumber={imageDetails[property].length}
                                                 sources={imageDetails[property]}
                                                 visible={sourcesVisibility[property]}
                                                 setVisible={setSourcesVisibility}
                                                 scroll={sourcesScroll[property]}
                                                 setSourcesScroll={setSourcesScroll}/>;
            })
            }
        </ul>


    );
};

ImportImageDomainList.propTypes = {
    imageDetails: PropTypes.object.isRequired,
};

export default ImportImageDomainList;
