import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {RouteService} from "../../services";
import {Enums} from "../../core";
import {useNavigate} from "react-router-dom";

const SearchResultText = ({text, tags, isFetching, showBackButton = false}) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const handleOnClick = (event) => {
        event.preventDefault();
        if (document.getElementById("search-field"))
            document.getElementById('search-field').focus();
    };

    const handleEmptyLinkOnClick = (event) => {
        event.preventDefault();
    };

    const handleOnClickBackButton = event => {
        event.preventDefault();
        RouteService.redirect(Enums.Routes.ImportImage, correlationId, i18n.language, navigate, t);
    };

    const _text = text.length > 350 ? text.substring(0, 350) : text;
    const correlationId = RouteService.getParameterByName("correlationId");
    const importImageUrl = `/${i18n.language}/${t('routes.import_image_route')}/${correlationId}`;

    return (
        <article className="article-result text-result">
            <header className="article-header">
                {t('search_result_text.title')}
            </header>
            <div className="article-content">
                <div className={_text.length > 150 ? "article-column article-text" : "article-column"}>
                    <h4>{t('search_result_text.sub_title_1')}</h4>
                    <p>{_text}</p>
                </div>
                <div className="article-column">
                    <h4>{t('search_result_text.sub_title_2')}</h4>
                    <ul className="tags">
                        {tags && tags.map((keyword, index) =>
                            <li key={index}>
                                <a href={`/${i18n.language}/${t('routes.subject_route')}/${keyword}`}>{keyword}</a>
                            </li>)}
                        {(tags.length === 0 && !isFetching) &&
                        <li><a href="#no-keywords"
                               onClick={handleEmptyLinkOnClick}>{t('search_result_text.sub_title_3')}</a></li>}
                        {(tags.length === 0 && isFetching) &&
                        <li><a href={"#in-progress"}
                               onClick={handleEmptyLinkOnClick}>{t('search_result_text.analysis_in_progress')}</a></li>}
                    </ul>
                    {(tags.length === 0 && !isFetching) &&
                    <a href="#search-field" className="more-link"
                       onClick={handleOnClick}>{t('search_result_text.link_1')}</a>}
                </div>
            </div>
            {(showBackButton && correlationId) && <div className="article-footer">
                <div className="back-button">
                    <a href={importImageUrl} onClick={handleOnClickBackButton}>{t('search_result_text.back_button')}</a>
                </div>
            </div>}
        </article>
    );
};

SearchResultText.propTypes = {
    text: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    isFetching: PropTypes.bool,
};

export default SearchResultText;
