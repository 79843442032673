import axios from 'axios';

const setupAxiosInterceptors = (language) => {

    const onRequestSuccess = config => config;
    const onResponseSuccess = response => response;

    const onResponseError = error => {
        const status = error.status || (error.response ? error.response.status : 0);
        if (status === 504 || status === 0) {
            window.location.href = `/${language}/Oups`;
        }

        return Promise.reject(error);
    };
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;