import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {ImportImageDomainUrlListRow} from "./";
import * as ReactGA from "react-ga";

const ImportImageDomainUrlList = ({disableButtonLoadMore, sources, handleOnClickShowMore, filteredUrl}) => {
    const {t} = useTranslation();
    const handleOnClick = (event) => {
        ReactGA.event({
            category: 'ImportImage',
            action: 'ShowMore'
        });
        handleOnClickShowMore(event);
    };
    return (
        <div className="analyzed-image-detailed-list">
            <ul className={disableButtonLoadMore ? "" : "show-more"}>
                {filteredUrl.map(url => <ImportImageDomainUrlListRow url={url} key={url}
                                                                     analysisUrl={sources.filter(source => source.url === url)[0].analysisUrl}/>)}
            </ul>
            {(!disableButtonLoadMore && sources.map(source => source.url).length > 5) &&
            <div className={"more-button"}>
                <a href={`#${t('import_image.button.show_more')}`}
                   onClick={handleOnClick}>{t('import_image.button.show_more')}</a>
            </div>}
        </div>
    );
};

ImportImageDomainUrlList.propTypes = {
    disableButtonLoadMore: PropTypes.bool.isRequired,
    sources: PropTypes.array,
    handleOnClickShowMore: PropTypes.func.isRequired,
    filteredUrl: PropTypes.array,
};

export default ImportImageDomainUrlList;