import React from 'react';
import PropTypes from 'prop-types';
import {LegalMenu, MainMenu, CategoriesMenu, HeaderMenu} from "./";

const HeaderPanel = ({onClose}) => {
    return (
        <div id="header-panel">
            <div className="panel-button-holder">
                <button className="button-menu-close menu-toggle" onClick={onClose}>Close Menu</button>
            </div>
            <div className="panel-menu">
                <MainMenu onClick={onClose}/>
                <HeaderMenu/>
                <CategoriesMenu onClick={onClose}/>
                <LegalMenu onClick={onClose}/>
            </div>
        </div>
    );
};

HeaderPanel.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default HeaderPanel;