export const SET_TOO_MANY_REQUEST = 'SET_TOO_MANY_REQUEST';
export const GET_IMAGE_DETAILS_ERROR = 'GET_IMAGE_DETAILS_ERROR';
export const BEGIN_UPLOAD_IMAGE = 'BEGIN_UPLOAD_IMAGE';
export const UPDATE_IMPORT_IMAGE_SUCCESS = 'UPDATE_IMPORT_IMAGE_SUCCESS';
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS';
export const GET_SUBJECTS_ERROR = 'GET_SUBJECTS_ERROR';
export const BEGIN_GET_SUBJECTS = 'BEGIN_GET_SUBJECTS';

export const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS';
export const UPDATE_SEARCH_OPTIONS = 'UPDATE_SEARCH_OPTIONS';

export const TRIGGER_IMPORT_SUBJECT_SUCCESS = 'TRIGGER_IMPORT_SUBJECT_SUCCESS';
export const TRIGGER_IMPORT_SUBJECT_ERROR = 'TRIGGER_IMPORT_SUBJECT_ERROR';
export const UPDATE_IMPORT_SUBJECT_SUCCESS = 'UPDATE_IMPORT_SUBJECT_SUCCESS';

export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_ERROR = 'GET_STATUS_ERROR';
export const GET_IMPORT_SUBJECT_SUCCESS = 'GET_IMPORT_SUBJECT_SUCCESS';

export const GET_SUBJECTS_SEARCH_TAGS_SUCCESS = 'GET_SUBJECTS_SEARCH_TAGS_SUCCESS';
export const UPDATE_SEARCH_TAGS = 'UPDATE_SEARCH_TAGS';
export const UPDATE_SEARCH_BUTTON_STATUS = 'UPDATE_SEARCH_BUTTON_STATUS';

export const BEGIN_GET_LINKS = 'BEGIN_GET_LINKS';
export const GET_LINKS_ERROR = 'GET_LINKS_ERROR';
export const GET_LINKS_SUCCESS = 'GET_LINKS_SUCCESS';

export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';
export const UPLOAD_IMAGE_FROM_URL_ERROR = 'UPLOAD_IMAGE_FROM_URL_ERROR';
export const GET_IMAGE_STATUS_ERROR = 'GET_IMAGE_STATUS_ERROR';
export const GET_IMAGE_STATUS_SUCCESS = 'GET_IMAGE_STATUS_SUCCESS';
export const TRIGGER_IMAGE_ERROR = 'TRIGGER_IMAGE_ERROR';
export const TRIGGER_IMAGE_SUCCESS = 'TRIGGER_IMAGE_SUCCESS';
export const GET_IMAGE_DETAILS_SUCCESS = "GET_IMAGE_DETAILS_SUCCESS";

export const NETWORK_ERROR = 'NETWORK_ERROR';
export const UPDATE_NETWORK = 'UPDATE_NETWORK';

export const TOO_MANY_REQUEST_ERROR = 'TOO_MANY_REQUEST_ERROR';

export const BEGIN_GET_TOKEN = 'BEGIN_GET_TOKEN';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_ERROR = 'GET_TOKEN_ERROR';

export const CLEAN_SEARCH_FIELDS = 'CLEAN_SEARCH_FIELDS';

export const SET_INSTALL_PROMPT_CLOSED = 'SET_INSTALL_PROMPT_CLOSED';
export const SET_HAS_CLICK_ON_INSTALL_BUTTON = 'SET_HAS_CLICK_ON_INSTALL_BUTTON';
export const SET_COOKIE_IS_ACCEPTED = 'SET_COOKIE_IS_ACCEPTED';