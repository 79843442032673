import React from 'react';
import {useTranslation} from "react-i18next";

const GoogleVisionContent = () => {
    const {t} = useTranslation();
    return (
        <>
            <p>
                {t(`analysis_list_row.google_vision.content.1`)}
            </p>
            <p>
                {t(`analysis_list_row.google_vision.content.2`)}
            </p>
            <p>
                {t(`analysis_list_row.google_vision.content.3`)}
            </p>
        </>
    );
};

export default GoogleVisionContent;