const AppConfig = {
    IMPORT_SUBJECT_INTERVAL: window.Configuration.IMPORT_SUBJECT_INTERVAL,
    IMPORT_SUBJECT_TIMEOUT: window.Configuration.IMPORT_SUBJECT_TIMEOUT,
    API_URL: window.Configuration.API_URL,
    ONLINE_INTERVAL: window.Configuration.ONLINE_INTERVAL,
    IMPORT_IMAGE_INTERVAL: window.Configuration.IMPORT_IMAGE_INTERVAL,
    IMPORT_IMAGE_TIMEOUT: window.Configuration.IMPORT_IMAGE_TIMEOUT,
    ALLOWED_IMAGE_EXTENSIONS: window.Configuration.ALLOWED_IMAGE_EXTENSIONS,
    GR_KEY: window.Configuration.GR_KEY,
    GA_KEY: window.Configuration.GA_KEY,
    IS_PRODUCTION: window.Configuration.IS_PRODUCTION,
    ALLOWED_VIDEO_EXTENSIONS: window.Configuration.ALLOWED_VIDEO_EXTENSIONS,
    MAXIMUM_SIZE_FILE: window.Configuration.MAXIMUM_SIZE_FILE,
    PROGRESSIVE_WEB_APP_POPUP_ENABLED: window.Configuration.PROGRESSIVE_WEB_APP_POPUP_ENABLED
};

export default AppConfig;
