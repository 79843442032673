import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const Loader = ({isLoadMore = true, showInProgressTitle = false, loading = false, circlesCount = 12}) => {
    const {t} = useTranslation();
    const circles = Array.from(Array(circlesCount), (_, x) => x + 1 < 10 ? "0" + (x + 1) : x + 1);
    return (
        <div className={isLoadMore ? 'preloader-small' : 'preloader'} style={loading ? {display: "block"} : {}}>
            <div className="preloader">
                <div className="preloader-circle">
                    {circles && circles.map(circle => <div key={circle} className={`circle-${circle} circle`}/>)}
                </div>
                {showInProgressTitle && <span>{t('loader.analysis_in_progress')}</span>}
            </div>
        </div>
    );
};

Loader.propTypes = {
    isLoadMore: PropTypes.bool,
    showInProgressTitle: PropTypes.bool,
    loading: PropTypes.bool,
    circlesCount: PropTypes.number,
};

export default Loader;