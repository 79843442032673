import React from 'react';
import PropTypes from 'prop-types';
import logoDecodeur from "../../assets/images/logo-decodeurs.png";
import Modal from "react-modal";
import {useTranslation} from "react-i18next";
import {DecodeurContent} from "./";

Modal.setAppElement(document.getElementById('root'));

const DecodeurModal = ({closeModal, modalIsOpen}) => {
    const {t} = useTranslation();
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            portalClassName="modal"
            overlayClassName="modal-open"
            className="modal-container"
            bodyOpenClassName="modal-visible"
        >
            <header className="modal-header">
                <button className="button-close-modal" onClick={closeModal}>Close</button>
                <div className="branding">
                    <img src={logoDecodeur} alt=""/>
                </div>
                <div className="tagline">
                    {t('decodeur_modal.tagline')}
                </div>
            </header>
            <div className="modal-content container-columns">
                <DecodeurContent/>
            </div>
        </Modal>
    );
};

DecodeurModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalIsOpen: PropTypes.bool,
};

export default DecodeurModal;
