import React from 'react';
import {useTranslation} from "react-i18next";

const Snackbar = () => {
    const {t} = useTranslation();
    const handleOnClick = () => {
        document.getElementById('snackbar').className = 'snackbar';
    };
    return (
        <>
            <div id="snackbar" className="snackbar">
                <div className="snackbar-overlay"/>
                <div className="snackbar-body">
                    <p className="snackbar-body-msg">{t('snackbar.text')}</p>
                    <button id="reload" onClick={handleOnClick} className="btn-white">{t('snackbar.button')}</button>
                </div>
            </div>
        </>
    );
};

export default Snackbar;