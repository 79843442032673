import React from 'react';
import {Trans, useTranslation} from "react-i18next";

const ScoringExplanation = ({analysesLength}) => {
    const {t} = useTranslation();
    return (
        <div className="container">
            <div className="container-box">
                <h6>{t('scoring_explanation.title')}</h6>
                <div className="description">
                    <Trans i18nKey="scoring_explanation.description" count={analysesLength}/>
                </div>
            </div>

        </div>
    )
};

export default ScoringExplanation;