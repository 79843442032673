import {Enums} from "../core";

class RouteService {

    static toggleLanguage(language, i18n, moment, location, navigate, t) {
        if (i18n.options.whitelist.includes(language) && i18n.language !== language) {
            let url = this.getTranslatedUrl(language, i18n, location, t);
            i18n.changeLanguage(language);
            moment.locale(language);
            navigate(url);
        }
    }

    static getCurrentRouteName(location, currentLanguage, param = "") {
        let routeNameParts = location.pathname.split('/');
        routeNameParts = routeNameParts.filter(routeNamePart => routeNamePart !== "");

        if (routeNameParts.length === 1) return routeNameParts[0];

        routeNameParts = routeNameParts.filter(routeNamePart => routeNamePart !== currentLanguage && routeNamePart !== param);

        if (!routeNameParts) return '';

        return routeNameParts[0];
    }

    static getTranslatedUrl(language, i18n, location, t, param = "") {
        let _routeName = this.getCurrentRouteName(location, i18n.language, param);
        let _url = `/${language}`;

        if (!_routeName) return _url;

        let _translatedRouteName = this.getTranslatedRouteName(language, i18n, _routeName, t);

        if (!_translatedRouteName) return _url;

        _url += `/${_translatedRouteName}`;

        let _routeNameParts = location.pathname.split('/');
        _routeNameParts = _routeNameParts.filter(routeNamePart => routeNamePart !== param && routeNamePart !== i18n.language && routeNamePart !== "");

        if (_routeNameParts.length >= 2)
            _url += `/${_routeNameParts[_routeNameParts.length - 1]}`;

        if (!param) return _url;

        _url += `/${param}`;
        return _url;
    }

    static getTranslatedRouteName(language, i18n, routeName, t) {
        const currentResources = i18n.getResourceBundle(i18n.language, '').routes;
        for (let property in currentResources) {
            if (currentResources && currentResources.hasOwnProperty(property) && currentResources[property] === routeName) {
                return t('routes.' + property, {lng: language});
            }
        }
        return '';
    }

    static redirect(route, param, currentLanguage, navigate, t) {
        let url = `/${currentLanguage}`;
        switch (route) {
            case Enums.Routes.Subject:
                url += `/${t('routes.subject_route')}/${param}`;
                break;
            case Enums.Routes.SubjectDetail:
                url += `/${t('routes.subject_detail_route')}/${param}`;
                break;
            case Enums.Routes.Fakyzation:
                url += `/${t('routes.fakyzation')}`;
                break;
            case Enums.Routes.WhoWeAre:
                url += `/${t('routes.who_we_are_route')}`;
                break;
            case Enums.Routes.OurSources:
                url += `/${t('routes.our_sources_route')}`;
                break;
            case Enums.Routes.HowDoesItWork:
                url += `/${t('routes.how_does_it_work_route')}`;
                break;
            case Enums.Routes.LegalMention:
                url += `/${t('routes.legal_mention_route')}`;
                break;
            case Enums.Routes.Privacy:
                url += `/${t('routes.privacy_route')}`;
                break;
            case Enums.Routes.ImportStatus:
                url += `/${t('routes.import_status_route')}/${param}`;
                break;
            case Enums.Routes.Faky:
                url += `/${t('routes.faky_route')}`;
                break;
            case Enums.Routes.ContactUs:
                url += `/${t('routes.contact_us_route')}`;
                break;
            case Enums.Routes.ContactUsThankYou:
                url += `/${t('routes.contact_us_thank_you_route')}`;
                break;
            case Enums.Routes.Home:
                url += `${param}`;
                break;
            case Enums.Routes.ImportImage:
                url += `/${t('routes.import_image_route')}/${param}`;
                break;
            case Enums.Routes.HowDoesItWorkImages:
                url += `/${t('routes.how_does_it_work_images_route')}`;
                break;
            case Enums.Routes.AnalyseImageText:
                url += `/${t('routes.analyse_image_text_route')}/${param}`;
                break;
            default:
                break;
        }

        navigate(url);
    }

    static getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[[]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    static showSearchField(location, currentLanguage, t, searchParams) {
        const currentRouteName = this.getCurrentRouteName(location, currentLanguage);
        const searchUrl = this.getParameterByName("searchUrl");

        return currentRouteName === currentLanguage || currentRouteName === t('routes.subject_route') || currentRouteName === t('routes.import_status_route') ||
            ((searchParams.url !== '' || searchUrl) && currentRouteName === t('routes.subject_detail_route')) || currentRouteName === t('routes.import_image_route') || currentRouteName === t('routes.analyse_image_text_route');
    }

    static getSearchParamsInUrl(location, i18n) {
        let routeNameParts = location.pathname.split('/');
        routeNameParts = routeNameParts.filter(routeNamePart => routeNamePart !== "" && routeNamePart !== i18n.language);
        return routeNameParts[routeNameParts.length - 1];
    }
}

export default RouteService;
