import {DeviceService} from "./index";

const ScrollService = {
    scrollToElement(elementId, padding = 0) {
        if (document.getElementById(elementId) && document.getElementById(elementId).offsetTop !== undefined) {
            const headerHeight = DeviceService.isMobile() ? document.getElementById('header').clientHeight + 8 : document.getElementById('header').clientHeight;
            window.scrollTo({
                behavior: 'smooth',
                top: ScrollService.getOffsetTop(elementId) - headerHeight - padding
            });
        }
    },
    getOffsetTop(elementId) {
        let element = document.getElementById(elementId);
        let offsetTop = 0;
        do {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        } while (element !== document.body);
        return offsetTop;
    }
};

export default ScrollService;