import {Enums} from "../core";

const CssClassHelpers = {
    mapScoringToCssClass(scoring) {
        switch (scoring) {
            case Enums.Scoring.NotReliable:
                return "unreliable";
            case Enums.Scoring.Doubtful:
                return "semi-unreliable";
            case Enums.Scoring.Undetermined:
                return "indeterminate";
            case Enums.Scoring.RelativelyReliable:
                return "semi-reliable";
            case Enums.Scoring.Reliable:
                return "reliable";
            default :
                return "";
        }
    },
    mapImportImagePropertyNameToCssClass(propertyName) {
        switch (propertyName) {
            case"reliableSources":
                return "reliable";
            case "indeterminateSources":
                return "indeterminate";
            case"satiricSources":
                return "unreliable";
            case"notReliableSources":
                return "semi-unreliable";
            case"socialMediaSources":
                return "social-networks";
            default :
                return "";
        }
    }
};

export default CssClassHelpers;
