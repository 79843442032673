import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Enums } from "../../core";
import { useTranslation } from "react-i18next";
import { LynxModal } from "./index";
import * as ReactGA from "react-ga";

const LynxAnalysisContentListRow = ({ isDescriptionOpen, description, mediaLinks, scoring }) => {
    const { t, i18n } = useTranslation();

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = event => {
        event.preventDefault();
        setModalIsOpen(true);
        ReactGA.modalview(`${i18n.language}/${t('routes.our_sources_route')}/${Enums.AnalysisOrigin.Decodeur}`);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    
    return (
        <div className="collapsible" style={isDescriptionOpen ? { display: 'block' } : {}}>
            <div className="article-content">
                <div className="article-column left">
                    <div className="article-abstract">
                        <h6 className="heading-article">
                            {t(`our_partners`)}
                        </h6>
                        <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.sub_title_logo`)}</p>
                        <a href="#openModal" onClick={openModal} className="more-link">{t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.read_more`)}</a>
                    </div>
                </div>
                <div className="article-column right">
                    <h6 className="heading-article">
                        {t(`detail_analysis_text`)}
                    </h6>
                    {mediaLinks.length === 1 ?
                        <div className="article-intro">
                            <p>{mediaLinks[0].scoringDescription}</p>
                            <p>                        
                                {t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.description.one_url`, { scoring: mediaLinks[0].originalScoring})}
                            </p>
                        </div>
                        :
                        <div className="article-intro">
                            <p>{description}</p>
                        </div>
                    }
                    <div>
                        <br></br>
                        <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.description.bulletpoints_intro`)}</p>
                    </div>
                    <div>
                    <ul>
                        <li>{t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.description.bulletpoint.1`)}</li>
                        <li>{t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.description.bulletpoint.2`)}</li>
                        <li>{t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.description.bulletpoint.3`)}</li>
                        <li>{t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.description.bulletpoint.4`)}</li>
                        <li>{t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.description.bulletpoint.5`)}</li>
                    </ul>
                    </div>
                </div>
            </div>
            <LynxModal closeModal={closeModal} modalIsOpen={modalIsOpen} />
        </div>
    );
};

LynxAnalysisContentListRow.propTypes = {
    isDescriptionOpen: PropTypes.bool.isRequired,
    description: PropTypes.string,
    mediaLinks: PropTypes.array,
    scoring: PropTypes.number
};

export default LynxAnalysisContentListRow;
