export default {
    searches: {},
    subjects: {
        byId: {},
        allIds: []
    },
    links: {
        byId: {},
        allIds: []
    },
    searchParams: {
        text: '',
        url: ''
    },
    searchOptions: {
        pageSize: 3,
        pageNumber: 1
    },
    tags: [],
    importSubject: {
        correlationId: 0,
        status: [],
        disableSearchButton: false
    },
    importImage: {
        uploadedImage: {
            correlationId: 0,
            url: "",
            name: "",
            size: 0
        },
        triggeredImage: {
            correlationId: 0,
            url: "",
            name: "",
            size: 0,
        },
        status: [],
    },
    errors: {
        elements: [],
        isTooManyRequestError: false
    },
    imageDetails: {
        byId: {},
        allIds: []
    },
    connection: {
        online: true
    },
    google: {
        recaptcha: {
            isFetching: false,
            isSuccess: false,
            isFailed: false
        }
    },
    installPrompt: {
        isClosed: false,
        hasClickOnInstallButton: false
    }
};
