import initialState from "./initialState";
import * as types from "../actions/actionTypes";
import {isEqual} from "lodash";

export default function linksReducer(state = initialState.links, action) {
    if (action.type === types.GET_LINKS_SUCCESS) {
        let _linksById = {...state.byId};
        let _allIds = [...state.allIds];
        for (let link of action.payload.elements) {
            if (_allIds.includes(link.id) && isEqual(link, _linksById[link.id])) continue;
            _linksById = {
                ..._linksById,
                [link.id]: link
            };
            _allIds.push(link.url);
        }
        return {
            ...state,
            byId: {..._linksById},
            allIds: [..._allIds]
        };
    } else {
        return state;
    }
}