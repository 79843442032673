import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import InstallPromptIOS from "./InstallPromptIOS";
import {Cookies} from "react-cookie-consent";
import {
    COOKIE_CONSENT_KEY,
    FAKY_PWA_INSTALLED,
    INSTALL_PROMPT_COOKIE_CONSENT_KEY,
    INSTALL_PROMPT_OPEN_CLASS
} from "../../core/Constants";
import InstallPromptAndroid from "./InstallPromptAndroid";
import InstallPromptHeader from "./InstallPromptHeader";
import {connect} from "react-redux";
import {setHasClickOnInstallButton, setInstallPromptClosed} from "../../actions/installPromptActions";
import {bindActionCreators} from 'redux';
import {AppConfig} from "../../config";


const InstallPrompt = ({
                           setInstallPromptClosed,
                           setHasClickOnInstallButton,
                           hasClickOnInstallButton,
                           cookieIsAccepted
                       }) => {
    const {t} = useTranslation();
    const [cookieConsent, setCookieConsent] = useState(Cookies.get(COOKIE_CONSENT_KEY));

    useEffect(() => {
        setCookieConsent(Cookies.get(COOKIE_CONSENT_KEY))
    }, [cookieIsAccepted]);

    const handleOnClickInstall = event => {
        event.preventDefault();
        setHasClickOnInstallButton(true);
        document.body.classList.add(INSTALL_PROMPT_OPEN_CLASS);
    };

    const handleOnClickCancel = event => {
        event.preventDefault();
        Cookies.set(INSTALL_PROMPT_COOKIE_CONSENT_KEY, true, {expires: 22});
        setInstallPromptClosed(true);
    };

    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    const installPromptCookieConsent = Cookies.get(INSTALL_PROMPT_COOKIE_CONSENT_KEY);
    const progressiveWebAppInstalled = localStorage.getItem(FAKY_PWA_INSTALLED) === "true";

    if (!cookieConsent || installPromptCookieConsent || !isMobile || (isIOS && isInStandaloneMode()) || !AppConfig.PROGRESSIVE_WEB_APP_POPUP_ENABLED || progressiveWebAppInstalled) return <></>;

    if (isIOS && !isInStandaloneMode() && hasClickOnInstallButton) {
        return <InstallPromptIOS/>;
    }

    if (isAndroid && hasClickOnInstallButton) {
        return <InstallPromptAndroid/>;
    }

    return (
        <div id="install-prompt" className="download-popup-fixed">
            <div className="download-popup-fixed-content">
                <InstallPromptHeader/>
                <div className="button-content">
                    <button className="button-light-green" onClick={handleOnClickInstall}>
                        {t("install_prompt.default.submit_button")}
                    </button>
                    <button className="btn-ghost" onClick={handleOnClickCancel}>
                        {t("install_prompt.default.cancel_button")}
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setInstallPromptClosed: bindActionCreators(setInstallPromptClosed, dispatch),
        setHasClickOnInstallButton: bindActionCreators(setHasClickOnInstallButton, dispatch)
    };
};

const mapStateToProps = (state) => {
    return {
        hasClickOnInstallButton: state.installPrompt.hasClickOnInstallButton,
        cookieIsAccepted: state.installPrompt.cookieIsAccepted
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallPrompt);