import axios from "axios";
import {AppConfig} from "../config";

class LinkApi {
    static url = AppConfig.API_URL;

    static async getEmbed(url) {
        return await axios.get(`${this.url}/links/embed?url=${url}`).then(response => response.data).then(result => {
            return result;
        });
    }
}

export default LinkApi;