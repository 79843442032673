import React from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import {SubjectListRow} from "./index";
import "slick-carousel/slick/slick.css";

const SliderSubjectList = ({subjects}) => {

    const blurSearchField = () => {
        if (document.getElementById('search-field'))
            document.getElementById('search-field').blur();
    };

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
        centerMode: false,
        variableWidth: true,
        variableHeight: true,
        infinite: false,
        swipeToSlide: true,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    };

    return (
        <div className="container scrollable">
            <Slider {...settings} className="articles" beforeChange={blurSearchField}>
                {subjects && subjects.map((subject, index) => <SubjectListRow key={index} subject={subject}
                                                                              source="LastChecked"/>)}
            </Slider>
        </div>
    );
};

SliderSubjectList.propTypes = {
    subjects: PropTypes.array.isRequired,
};

export default SliderSubjectList;