export default class Enums {
    static get Scoring() {
        return Scoring;
    }

    static get AnalysisOrigin() {
        return AnalysisOrigin;
    }

    static get ImportStatus() {
        return ImportStatus;
    }

    static get Routes() {
        return Routes;
    }

    static get Scopes() {
        return Scopes;
    }

    static get Languages() {
        return Languages;
    }

    static get Errors() {
        return Errors;
    }

    static get StatusCode() {
        return StatusCode;
    }

    static get ContactUsObjectType() {
        return ContactUsObjectType;
    }

    static getKey(enumeration, value) {
        return Object.keys(enumeration).find(key => enumeration[key] === value);
    }
}

const Scoring = {
    NotReliable: -2,
    Doubtful: -1,
    Undetermined: 0,
    RelativelyReliable: 1,
    Reliable: 2
};

const AnalysisOrigin = {
    Decodeur: "decodeur",
    Decodex: "decodex",
    DD: "dd",
    Rss: "rss",
    TextGain: "textgain",
    Neutral: "neutral",
    Lynx: "lynx",
};

const ImportStatus = {
    ToProcess: "ToProcess",
    Processing: "Processing",
    Processed: "Processed",
    Aborted: "Aborted",
    Error: "Error"
};

const Routes = {
    Subject: 0,
    SubjectDetail: 1,
    About: 2,
    WhoWeAre: 3,
    OurSources: 4,
    HowDoesItWork: 5,
    ImportStatus: 6,
    LegalMention: 7,
    Privacy: 8,
    Faky: 9,
    Home: 10,
    ContactUs: 11,
    ContactUsThankYou: 12,
    ImportImage: 13,
    HowDoesItWorkImages: 14,
    AnalyseImageText: 15,
    Fakyzation: 16
};

const Scopes = {
    SearchUrl: 'search_url',
    SearchText: 'search_text',
    SearchLatest: 'search_latest',
    SearchDetail: 'search_detail',
    SearchSimilar: 'search_similar',
    SearchImportSubject: 'search_import_subject',
    SearchLink: "search_link",
    SearchImportImageSubject: "search_import_image_subject"
};

const Languages = {
    English: 'en',
    French: 'fr'
};


const Errors = {
    Network: "Network Error"
};

const StatusCode = {
    TooManyRequest: 429
};

const ContactUsObjectType = {
    GeneralQuestion: 0,
    ReportFakeNews: 1,
    ArticleAnalysisQuestion: 2
};