import React from 'react';
import {useTranslation} from "react-i18next";
import {RouteService} from "../../services";
import {Enums} from "../../core";
import { withRouter } from "../../hooks/useWithRouter";
import {useNavigate} from "react-router-dom";

const ContactUsThankYouPage = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const handleOnClick = (event) => {
        event.preventDefault();
        RouteService.redirect(Enums.Routes.Home, "", i18n.language, navigate, t);
    };

    return (
        <>
            <div id="contact-us-thank-you">
                <div className="page-header">
                    <div className="container">
                        <div className="form-message">
                            <h2>{t('contact_us_thank_you_page.section_1.title')}</h2>
                            <p>{t('contact_us_thank_you_page.section_1.content')}</p>
                        </div>
                        <div className="form-actions">
                            <input type="submit" value={t('contact_us_thank_you_page.button.text')}
                                   onClick={handleOnClick}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withRouter(ContactUsThankYouPage);