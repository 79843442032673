import * as types from "./actionTypes";
import ImageApi from "../api/ImageApi";
import {SubjectApi} from "../api";
import {Enums} from "../core";
import {beginGetSubjects, loadSubjectsSuccess} from "./subjectActions";
import {setSearchButtonDisabled} from "./importSubjectActions";
import {ErrorService} from "../services";

export const uploadImageSuccess = (correlationId, url, name, size) => {
    return {
        type: types.UPLOAD_IMAGE_SUCCESS,
        payload: {
            correlationId,
            url,
            name,
            size
        }
    };
};

export const beginUploadImage = () => {
    return {
        type: types.BEGIN_UPLOAD_IMAGE
    }
};

export const triggerImageSuccess = (correlationId, url, name, size) => {
    return {
        type: types.TRIGGER_IMAGE_SUCCESS,
        payload: {correlationId, url, name, size}
    };
};

export const getImageStatusSuccess = status => {
    return {
        type: types.GET_IMAGE_STATUS_SUCCESS,
        payload: status
    };
};

export const uploadImage = (request, file, token, callback) => {
    return dispatch => {
        dispatch(beginUploadImage());
        return ImageApi.uploadImage(request, token, callback).then(result => {
            if (result && result.success) {
                const {correlationId, url} = result;
                const {name, size} = file;
                dispatch(updateImportImage({
                    uploadedImage: {url: "", correlationId: 0, name: "", size: 0},
                    status: []
                }));
                dispatch(uploadImageSuccess(correlationId, url, name, size));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.UPLOAD_IMAGE_ERROR);
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.UPLOAD_IMAGE_ERROR);
            throw(error);
        });
    }
};

export const uploadImageFromUrl = (url, token, callback) => {
    return dispatch => {
        dispatch(beginUploadImage());
        return ImageApi.uploadImageFromUrl(url, token, callback).then(result => {
            if (result && result.success) {
                const {correlationId, url, fileName, fileSize} = result;
                dispatch(updateImportImage({
                    uploadedImage: {url: "", correlationId: 0, name: "", size: 0},
                    status: []
                }));
                dispatch(uploadImageSuccess(correlationId, url, fileName, fileSize));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.UPLOAD_IMAGE_FROM_URL_ERROR);
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.UPLOAD_IMAGE_FROM_URL_ERROR);
            throw(error);
        });
    }
};

export const triggerImage = (correlationId, url, name, size) => {
    return dispatch => {
        return ImageApi.triggerImage(correlationId).then(result => {
            if (result && result.success) {
                dispatch(triggerImageSuccess(result.correlationId, url, name, size));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.TRIGGER_IMAGE_ERROR);
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.TRIGGER_IMAGE_ERROR);
            throw(error);
        });
    }
};

export const getImageStatus = (correlationId) => {
    return dispatch => {
        return SubjectApi.getStatus(correlationId).then(result => {
            if (result.success) {
                dispatch(getImageStatusSuccess(result.status));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.GET_IMAGE_STATUS_ERROR);
                dispatch(setSearchButtonDisabled(false));
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.GET_IMAGE_STATUS_ERROR);
            dispatch(setSearchButtonDisabled(false));
            throw(error);
        });
    };
};

export const getImageDetailsSuccess = (imageDetails, id) => {
    return {
        type: types.GET_IMAGE_DETAILS_SUCCESS,
        payload: {
            subjectId: id,
            elements: imageDetails
        }
    };
};

export const getImageDetails = (id) => {
    return dispatch => {
        return ImageApi.getImportImageSubject(id).then(result => {
            if (result.success) {
                let _elements = [];
                _elements.push(result.imageSubjectDetails);
                dispatch(getImageDetailsSuccess(_elements, id));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.GET_IMAGE_DETAILS_ERROR);
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.GET_IMAGE_DETAILS_ERROR);
            throw(error);
        });
    };
};

export const getImportImageSubject = (id, includeEmptyAnalysis = false) => {
    return dispatch => {
        let _scope = Enums.Scopes.SearchImportImageSubject + '_' + id;
        dispatch(beginGetSubjects(_scope, 1));
        return SubjectApi.getImportSubject(id, "", includeEmptyAnalysis).then(result => {
            if (result.success) {
                let _subjects = [];
                _subjects.push(result.subject);
                dispatch(loadSubjectsSuccess(_subjects, _scope, 1));
                dispatch(getImageDetails(id));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.GET_SUBJECTS_ERROR, _scope);
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.GET_SUBJECTS_ERROR, _scope);
            throw(error);
        });
    };
};

export const updateImportImageSuccess = importImage => {
    return {
        type: types.UPDATE_IMPORT_IMAGE_SUCCESS,
        payload: importImage
    }
};


export const updateImportImage = (importImage) => {
    return dispatch => {
        dispatch(updateImportImageSuccess(importImage));
    };
};

export const uploadFromContactForm = (request, file, token, callback) => {
    return dispatch => {
        dispatch(beginUploadImage());
        return ImageApi.uploadFromContactForm(request, token, callback).then(result => {
            if (result && result.success) {
                const {correlationId, url} = result;
                const {name, size} = file;
                dispatch(updateImportImage({
                    uploadedImage: {url: "", correlationId: 0, name: "", size: 0},
                    status: []
                }));
                dispatch(uploadImageSuccess(correlationId, url, name, size));
            } else {
                ErrorService.dispatchError(dispatch, result.errors, types.UPLOAD_IMAGE_ERROR);
            }
        }).catch(error => {
            ErrorService.dispatchError(dispatch, error, types.UPLOAD_IMAGE_ERROR);
            throw(error);
        });
    }
};
