import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import InstallPromptHeader from "./InstallPromptHeader";
import InstallPromptFAQContent from "./InstallPromptFAQContent";
import * as ReactGA from "react-ga";
import {INSTALL_PROMPT_OPEN_CLASS} from "../../core/Constants";
import {setInstallPromptClosed} from "../../actions/installPromptActions";
import {connect} from "react-redux";

const InstallPromptAndroid = ({setInstallPromptClosed}) => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(true);
    const [className, setClassName] = useState("download-popup-fixed");

    const handleOnClick = async (event) => {
        event.preventDefault();
        // Hide the app provided install promotion
        setIsOpen(false);
        setInstallPromptClosed(true);
        document.body.classList.remove(INSTALL_PROMPT_OPEN_CLASS);

        if (!window.deferredPrompt) return;
        // Show the install prompt
        window.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        const {outcome} = await window.deferredPrompt.userChoice;
        // Optionally, send analytics event with outcome of user choice
        ReactGA.event({
            category: 'InstallPromptAndroid',
            action: `User response to the install prompt: ${outcome}`,
            label: t("install_prompt.android.download.content_1")
        });

        // We've used the prompt, and can't use it again, throw it away
        window.deferredPrompt = null;
    };

    useEffect(() => {
        setClassName(isOpen ? "download-popup-fixed" : "");
    }, [isOpen])

    return (
        <div id="install-prompt">
            <div className={className}>
                <div className="download-popup-fixed-content">
                    <InstallPromptHeader/>
                    <div className="download-bar">
                        <p className="green-text no-margin">
                            {t("install_prompt.android.download.title")}
                        </p>
                        <div className='button-content'>
                            <button className='button-light-green' onClick={handleOnClick}>
                                {t("install_prompt.android.download.content")}
                            </button>
                        </div>
                    </div>
                    <div className="download-bar">
                        <p className="green-text no-margin">
                            {t("install_prompt.android.how_to_use_it.title")}
                        </p>
                        <div className="circle-popup">
                            <span>1</span>
                        </div>
                        <span className="white-subtitle bold-text">
                        {t("install_prompt.android.how_to_use_it.content.1")}
                            <span className="white-subtitle">
                            {t("install_prompt.android.how_to_use_it.content.2")}
                        </span>
                    </span>
                        <div className="circle-popup">
                            <span>2</span>
                        </div>
                        <span className="white-subtitle ">
                        {t("install_prompt.android.how_to_use_it.content.3")}
                            <span className="white-subtitle bold-text">
                            {t("install_prompt.android.how_to_use_it.content.4")}
                        </span>
                    </span>
                        <div className="circle-popup">
                            <span>3</span>
                        </div>
                        <span className="white-subtitle ">
                        {t("install_prompt.android.how_to_use_it.content.5")}
                            <span className="white-subtitle bold-text">
                            {t("install_prompt.android.how_to_use_it.content.6")}
                        </span>
                    </span>
                        <div className="faky-logo"/>
                    </div>
                    <InstallPromptFAQContent/>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {setInstallPromptClosed}

export default connect(null, mapDispatchToProps)(InstallPromptAndroid);