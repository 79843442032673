import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Enums} from "../../core";
import {useTranslation} from "react-i18next";
import {DecodeurModal} from "./index";
import * as ReactGA from "react-ga";

const DecodeurAnalysisContentListRow = ({isDescriptionOpen, imageUrl, title, description, url}) => {
    const {t, i18n} = useTranslation();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = event => {
        event.preventDefault();
        setModalIsOpen(true);
        ReactGA.modalview(`${i18n.language}/${t('routes.our_sources_route')}/${Enums.AnalysisOrigin.Decodeur}`);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleOnClick = (event) => {
        ReactGA.event({
            category: 'SubjectDetails',
            action: `Click on ${Enums.AnalysisOrigin.Decodeur} read more button`,
            label: event.target.href
        });
    };

    return (
        <div className="collapsible" style={isDescriptionOpen ? {display: 'block'} : {}}>
            <div className="article-content">
                <div className="article-column left">
                    <div className="article-abstract">
                        <h6 className="heading-article">
                            {t(`our_partners`)}
                        </h6>
                        <p>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.sub_title_logo`)}</p>
                        <a href="#openModal" onClick={openModal}
                           className="more-link">{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.read_more`)}</a>
                    </div>
                    {imageUrl && <div className="article-image">
                        <img src={imageUrl} alt=""/>
                    </div>}
                </div>
                <div className="article-column right">
                    <h6 className="heading-article">
                        {t(`detail_analysis_text`)}
                    </h6>
                    <div className="article-intro">
                        {url &&
                            <>
                                {title !== "" ?
                                    <h3>{title}</h3> :
                                    <h3>{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.default_title`)}</h3>}
                                <p>{description}</p>
                                <a href={url} target="_blank" onClick={handleOnClick}
                                    rel="noopener noreferrer">{t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.link`)}</a>
                            </>
                        }
                        {!url && 
                            <p>
                                {t(`analysis_list_row.${Enums.AnalysisOrigin.Decodeur}.description.no_scoring`)}
                            </p>
                        }
                    </div>
                </div>
            </div>
            <DecodeurModal closeModal={closeModal} modalIsOpen={modalIsOpen}/>
        </div>
    );
};

DecodeurAnalysisContentListRow.propTypes = {
    isDescriptionOpen: PropTypes.bool.isRequired,
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
};

export default DecodeurAnalysisContentListRow;