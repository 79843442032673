import React from 'react';
import {useTranslation} from "react-i18next";
import {isMobile} from "react-device-detect";
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import {NavLink} from "react-router-dom";

const ShareSectionDesktop = () => {
    const {i18n, t} = useTranslation();

    if (isMobile) return <></>;

    return (
        <section id="contact-us">
            <div className="container">
                <div className='share-content-desktop'>
                    <div className='share-content-box'>
                        <div className="share-text">
                            <h3>{t('share_section.share_title')}</h3>
                        </div>
                        <div className='flex-container share-icon-content'>
                            <FacebookShareButton url={window.location.href}>
                                <div className='icon-facebook'/>
                            </FacebookShareButton>
                            <TwitterShareButton url={window.location.href}>
                                <div className='icon-twitter'/>
                            </TwitterShareButton>
                            <LinkedinShareButton url={window.location.href}>
                                <div className='icon-linkedin'/>
                            </LinkedinShareButton>
                            <EmailShareButton url={window.location.href}>
                                <div className='icon-message'/>
                            </EmailShareButton>
                        </div>
                    </div>
                    <div className='share-content-box'>
                        <div className="share-text">
                            <h3>{t('share_section.contact_us_title')}</h3>
                        </div>
                        <NavLink to={`/${i18n.language}/${t('routes.contact_us_route')}`} className='flex-container '>
                            <div className='icon-contact'/>
                            <div className="share-text">
                                <h3 className='share-text-small'>
                                    {t('main_menu.link_6')}
                                </h3>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default ShareSectionDesktop;