import React from 'react';
import {withRouter} from "../../hooks/useWithRouter";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {RouteService} from "../../services";
import moment from "moment";
import {Enums} from "../../core";

const LanguageSwitcher = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const language = i18n.language === Enums.Languages.French ? Enums.Languages.English : Enums.Languages.French;

    const toggleLanguage = (event) => {
        event.preventDefault();
        RouteService.toggleLanguage(language, i18n, moment, location, navigate, t);
    };

    return (
        <li>
            <a href={`#${language}`}
               onClick={toggleLanguage}>{i18n.language === Enums.Languages.French ? t('language_switcher.en') : t('language_switcher.fr')}</a>
        </li>
    );
};

export default withRouter(LanguageSwitcher);