// These must be the first lines in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React, {Suspense} from 'react';
import { createRoot  } from 'react-dom/client'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {configureStore} from "./store";
import {DefaultRoutes} from "./routes";
import {handleOnUpdate} from "./services/ServiceWorkerService" ;
import './i18n/i18n';
import * as ReactGA from "react-ga";

// Load moment.js locales
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import moment from 'moment';

//Importing logger
import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import {upperFirst} from "lodash";
import {AppConfig} from "./config";


// Set default locale
moment.locale('fr');

//Initializing GA
ReactGA.initialize(AppConfig.GA_KEY);

//Initializing logger
const customJSON = log => ({
    message: log.message,
    level: upperFirst(log.level.label),
    stacktrace: log.stacktrace,
    timestamp: log.timestamp
});
remote.apply(log, {format: customJSON, url: AppConfig.API_URL + '/logger'});

const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Suspense fallback={<div/>}>
                <DefaultRoutes/>
            </Suspense>
        </BrowserRouter>
    </Provider>
);

let refreshing;
// The event listener that is fired when the service worker updates
// Here we reload the page
navigator.serviceWorker.addEventListener('controllerchange', function () {
    if (refreshing) return;
    window.location.reload();
    refreshing = true;
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
    onUpdate: handleOnUpdate
});
