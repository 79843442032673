import {Enums} from "../core";
import _ from "lodash";
import * as ReactGA from "react-ga";

const ImportService = {
    processStatus(props, state) {
        const {t} = props;
        let importObjectFromProps = props.importSubject ? props.importSubject : props.importImage;
        let importObjectFromState = state.importSubject ? state.importSubject : state.importImage;
        let _newState = props.importSubject ? {importSubject: {...importObjectFromProps}} : {importImage: {...importObjectFromProps}};
        const {status} = importObjectFromProps;

        let _statusNotErrorOrNotAborted = status.filter(stat => stat.status !== Enums.ImportStatus.Error && stat.status !== Enums.ImportStatus.Aborted);
        if (props.link && !props.link.hasOwnProperty("id") && props.searchParams.url && _statusNotErrorOrNotAborted.length > 0 && _statusNotErrorOrNotAborted[0].subjectId) {
            props.getEmbed(encodeURIComponent(props.searchParams.url));
        }
        let statusErrorCount = status.filter(stat => stat.status === Enums.ImportStatus.Error).length;
        if (statusErrorCount > 0 && status.length > 0) {
            if (props.importSubject) {
                props.setSearchButtonDisabled(false);

                ReactGA.event({
                    category: 'ImportUrl',
                    action: 'Result',
                    label: 'No Analysis'
                });
            }

            const {similarSubjectsCount} = props;
            props.importSubject ? props.getImportSubject(status[0].subjectId, similarSubjectsCount === 0, status[0].correlationId) : props.getImportImageSubject(status[0].subjectId);

            return {
                ..._newState,
                showLoader: false,
                clearInterval: true,
                errorMessage: props.importSubject ? t('import_subject_page.error_message') : t('import_image_page.error_message')
            };
        }

        let _statusNotAbortedCount = status.filter(stat => stat.status !== Enums.ImportStatus.Aborted).length;
        if (_statusNotAbortedCount === 0 && status.length > 0) {
            if (props.importSubject) {
                props.setSearchButtonDisabled(false);

                ReactGA.event({
                    category: 'ImportUrl',
                    action: 'Result',
                    label: 'No Analysis'
                });
            }

            const {similarSubjectsCount} = props;
            props.importSubject ? props.getImportSubject(status[0].subjectId, similarSubjectsCount === 0, status[0].correlationId) : props.getImportImageSubject(status[0].subjectId);

            return {
                ..._newState,
                showLoader: false,
                clearInterval: true,
                showingSadFace: false,
                errorMessage: props.importSubject ? t('import_subject_page.aborted_error_message') : t('import_image_page.no_result_message')
            };
        }

        let _statusNotProcessedOrNotAbortedCount = status.filter(stat => stat.status !== Enums.ImportStatus.Processed && stat.status !== Enums.ImportStatus.Aborted).length;
        if (_statusNotProcessedOrNotAbortedCount === 0 && status.length > 0) {
            if (props.importSubject) {
                props.setSearchButtonDisabled(false);

                ReactGA.event({
                    category: 'ImportUrl',
                    action: 'Result',
                    label: `${status.filter(stat => stat.status === Enums.ImportStatus.Processed).length} Analysis`
                });
            }
            _newState = {..._newState, showLoader: false, clearInterval: true};
        }


        let statusDifferences = _.differenceWith(importObjectFromProps.status, importObjectFromState.status, _.isEqual).map(object => object.status);
        if (statusDifferences.filter(status => status === Enums.ImportStatus.Processed).length > 0 && (!_newState.clearInterval || props.importImage)) {
            const {similarSubjectsCount} = props;
            props.importSubject ? props.getImportSubject(status[0].subjectId, similarSubjectsCount === 0, status[0].correlationId) : props.getImportImageSubject(status[0].subjectId);
        }

        return _newState;
    }
};

export default ImportService;
