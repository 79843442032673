import React from 'react';
import {getCachedMediaMetadata, getFileFromSource} from "./getCachedMediaMetadata";
import {GoogleService} from "../../services";
import {GOOGLE_RECAPTCHA_ACTION_NAME_IMAGE} from "../../core/Constants";
import ImageApi from "../../api/ImageApi";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom';

const ShareTargetImagePage = ({dispatch}) => {
    const {i18n, t} = useTranslation();

    getCachedMediaMetadata('image/').then(images => {

        if (images.length === 0) {
            document.location.href = `/${i18n.language}/404`;
        }
        getFileFromSource(images[0].src).then(file => {
            const formData = new FormData();
            formData.append("File", file);

            GoogleService.getToken(GOOGLE_RECAPTCHA_ACTION_NAME_IMAGE, (token) => {
                ImageApi.uploadImage(formData, token).then(result => {
                    if (result && result.success) {
                        const {correlationId} = result;
                        ImageApi.triggerImage(correlationId).then(result => {
                            if (result.success) {
                                document.location.href = `/${i18n.language}/${t("routes.import_image_route")}/${result.correlationId}`;
                            } else {
                                document.location.href = `/${i18n.language}/Oups`;
                            }
                        }).catch(() => {
                            document.location.href = `/${i18n.language}/Oups`;
                        });
                    } else {
                        document.location.href = `/${i18n.language}/Oups`;
                    }
                }).catch(() => {
                    document.location.href = `/${i18n.language}/Oups`;
                });
            }, dispatch);
        }).catch(() => {
            document.location.href = `/${i18n.language}/Oups`;
        });
    });

    return <></>;
};

export default connect(null, (dispatch) => ({dispatch}))(ShareTargetImagePage);