import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function networkReducer(state = initialState.connection, action) {
    switch (action.type) {
        case types.NETWORK_ERROR:
            return {
                ...state,
                online: false
            };
        case types.UPDATE_NETWORK:
            return {
                ...state,
                online: action.payload
            };
        default:
            return state;
    }
};