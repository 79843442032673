import * as types from "./actionTypes";

export const beginGetToken = () => {
    return {
        type: types.BEGIN_GET_TOKEN
    };
};

export const getTokenSuccess = () => {
    return {
        type: types.GET_TOKEN_SUCCESS
    };
};