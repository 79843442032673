import { useLocation } from "react-router";

export default function useQuery(){
    let location = useLocation();

    let urlParams= new URLSearchParams(location.search);

    let params = {};

    for(let key of urlParams.keys()){
        params[key] = urlParams.get(key);
    }

    return params;
}