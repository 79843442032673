import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import cache from 'i18next-localstorage-cache';
import languageDetector from "i18next-browser-languagedetector";
import postProcessor from 'i18next-sprintf-postprocessor';
import {localesFr, localesEn} from "./locales";

i18n
    .use(languageDetector)
    .use(cache)
    .use(postProcessor)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: "fr", // use en if detected lng is not
        // whitelist: ["en", "fr"],
        whitelist: ["fr"],
        debug: false,
        defaultNS: "translations",
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            wait: true,
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'em'],
        },
        overloadTranslationOptionHandler: postProcessor.overloadTranslationOptionHandler
    });

i18n.addResourceBundle("en", "translations", localesEn);
i18n.addResourceBundle("fr", "translations", localesFr);