import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {ScoringExplanation, ScoringList} from "../scoring";
import {AnalysisList} from "../analysis";
import {SubjectDetailDescription, SubjectResult} from "./index";
import {ListHelpers} from "../../helpers";
import {updateSearchOptions} from "../../actions/searchOptionsActions";
import {getSimilarSubjects, getSubjectDetails} from "../../actions/subjectActions";
import {isEqual} from "lodash";
import {SearchService} from "../../services";
import {Enums} from "../../core";
import {updateSearchParams} from "../../actions/searchParamsActions";
import {SearchResult} from "../searchResult";
import {ShareSection} from "../ShareSection";

class SubjectDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: {...this.props.subject},
            similarSubjects: [...this.props.similarSubjects],
            loading: false,
            searchParams: {...this.props.searchParams}
        };
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.similarSubjects.length > 0 && ListHelpers.getDifferences(props.similarSubjects, state.similarSubjects).length > 0) {
            return {similarSubjects: [...props.similarSubjects], loading: false};
        }

        if (props.subject && !isEqual(props.subject, state.subject)) {
            return {subject: {...props.subject}};
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({similarSubjects: []});
            this.props.updateSearchOptions({pageSize: 3, pageNumber: 1});
            this.props.getSubjectDetails(this.props.match.params.id);
        }

        if (!isEqual(prevProps.searchParams, this.props.searchParams)) {
            this.setState({searchParams: {...this.props.searchParams}});
        }
    }

    componentDidMount() {
        if (!this.props.subject.id || !this.props.subject.tags || !this.props.subject.analyses) {
            this.props.getSubjectDetails(this.props.match.params.id);
        }
    }

    componentWillUnmount() {
        this.setState({
            subject: {},
            similarSubjects: []
        });

        this.props.updateSearchOptions({pageSize: 3, pageNumber: 1});
    }

    handleOnClick(event) {
        event.preventDefault();
        const {similarSubjectsCount} = this.props;
        let _searchOptions = {...this.props.searchOptions};
        if (Math.ceil(similarSubjectsCount / _searchOptions.pageSize) >= _searchOptions.pageNumber + 1) {
            _searchOptions.pageNumber += 1;
            this.setState({loading: true});
            this.props.updateSearchOptions({..._searchOptions});
            let _tags = this.props.subject.tags ? [...this.props.subject.tags] : [];
            this.props.getSimilarSubjects(this.props.match.params.id, {
                searchOptions: _searchOptions,
                tags: _tags
            });
        }
    }

    render() {
        const {t, searchOptions, similarSubjectsCount, link, search} = this.props;
        const {similarSubjects, loading, searchParams, subject} = this.state;
        const disableButtonLoadMore = !(Math.ceil(similarSubjectsCount / searchOptions.pageSize) >= searchOptions.pageNumber + 1);

        const showExplanationText = subject.scoring || (subject.scoring === 0 && subject.analyses && subject.analyses.length > 0);

        let scoredAnalysis = 0;
        if (subject.analyses) {
            scoredAnalysis = subject.analyses.filter(analysis => analysis.scoring || analysis.scoring === 0).length;
        }

        return (
            <>
                {searchParams.url && <section id="finder-results" className="grey-section">
                    <h2>{t('search_result.title')}</h2>
                    <SearchResult url={searchParams.url} link={link}/>
                </section>}

                {(subject && !searchParams.url) && <section id="finder-results" className="grey-section">
                    <h2>{t('subject_detail_page.title')}</h2>
                    <SubjectDetailDescription subject={subject}/>
                </section>}

                <section id="results-evaluation"
                         style={{'display': (showExplanationText && scoredAnalysis > 0 && link) ? 'none' : 'block'}}>
                    <ScoringList scoring={subject.scoring}
                                 errorMessage={(!subject.title || subject.analyses.length === 0) && !search.isFetching ? t('import_subject_page.aborted_error_message') : ""}
                                 showingSadFace={subject.title && subject.analyses.length !== 0 && !search.isFetching}/>
                </section>

                {(subject.analyses && subject.analyses.length > 0) && <section id="partner-analyses">
                    <h2>{t('subject_detail_page.analyses_title')} ({scoredAnalysis} sur 5)</h2>
                    {showExplanationText && <ScoringExplanation/>}
                    <AnalysisList analyses={subject.analyses}/>
                </section>}


                {(subject.analyses && subject.analyses.length > 0) && <ShareSection/>}

                {(similarSubjects && similarSubjects.length > 0) &&
                <SubjectResult disableButtonLoadMore={disableButtonLoadMore}
                               loading={loading}
                               handleOnClick={this.handleOnClick}
                               subjects={similarSubjects} isSimilarSubjects={true} source="Similar"/>}
            </>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        updateSearchOptions: bindActionCreators(updateSearchOptions, dispatch),
        updateSearchParams: bindActionCreators(updateSearchParams, dispatch),
        getSimilarSubjects: bindActionCreators(getSimilarSubjects, dispatch),
        getSubjectDetails: bindActionCreators(getSubjectDetails, dispatch)
    };
};

const mapStateToProps = (state, ownProps) => {

    const _detailScope = Enums.Scopes.SearchDetail + '_' + ownProps.match.params.id;
    let _subject = SearchService.getSubject(state, _detailScope);

    let search = {isFetching: false};
    if (state.searches.hasOwnProperty(_detailScope)) {
        search = state.searches[_detailScope][1];
    }

    const _similarScope = Enums.Scopes.SearchSimilar + '_' + ownProps.match.params.id;
    let {subjects, subjectsCount} = SearchService.getSubjects(state, _similarScope, state.searchOptions.pageNumber);

    const _linkScope = Enums.Scopes.SearchLink + '_' + encodeURIComponent(state.searchParams.url);
    let _link = SearchService.getLink(state, _linkScope);

    return {
        subject: _subject,
        similarSubjects: subjects,
        similarSubjectsCount: subjectsCount,
        searchOptions: state.searchOptions,
        searchParams: state.searchParams,
        link: _link,
        search
    }
};


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SubjectDetailPage));