import React from 'react';
import {Enums} from "../../core";
import {useTranslation} from "react-i18next";

const LynxContent = () => {
    const {t} = useTranslation();
    return (
        <>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.content.1`)}
            </p>
            <p>
                {t(`analysis_list_row.${Enums.AnalysisOrigin.Lynx}.content.2`)}
            </p>
        </>
    );
};

export default LynxContent;