import React from 'react';
import {ImportImagePieChart, ImportImagePreview, ImportImageStatList} from "../importImage";
import {useTranslation} from "react-i18next";
import iconAnalyzedText from "../../assets/images/icon-t.svg";
import {MAX_LENGTH_SEARCH_TEXT} from "../../core/Constants";
import {snakeCase} from "lodash/string";
import {Enums} from "../../core";
import {RouteService} from "../../services";
import {useNavigate} from "react-router-dom";

const SearchResultImage = ({imageDetails, triggeredImage, onClick, textDetected = ""}) => {
    const {t, i18n} = useTranslation();
    const defaultImageDetails = {
        reliableSources: [],
        indeterminateSources: [],
        satiricSources: [],
        notReliableSources: [],
        socialMediaSources: []
    };

    const textDetectedProcessed = snakeCase(textDetected.substring(0, MAX_LENGTH_SEARCH_TEXT));
    const navigate = useNavigate();

    const handleOnClick = event => {
        event.preventDefault();
        RouteService.redirect(Enums.Routes.AnalyseImageText, `${textDetectedProcessed}?correlationId=${triggeredImage.correlationId}`, i18n.language, navigate, t);
    };

    const textDetectedUrl = `/${i18n.language}/${t('routes.analyse_image_text_route')}/${textDetectedProcessed}?correlationId=${triggeredImage.correlationId}`;

    return (
        <article className="analyzed-image analyzed-image-bordered">
            <div className="analyzed-image-overview">
                <ImportImagePreview
                    url={imageDetails && imageDetails.hasOwnProperty('mainImageUrl') ? imageDetails.mainImageUrl : triggeredImage.hasOwnProperty('url') ? triggeredImage.url : ''}/>
                <div className="analyzed-image-info">
                    <p>{t('import_image.title')}</p>
                    <div className="analyzed-image-info-wrapper">
                        {(imageDetails && imageDetails.hasOwnProperty("totalNumberOfMediaLinks")) ?
                            <p>{t('import_image.addresses_analysed.1')}
                                <span>{t('import_image.addresses_analysed.2', imageDetails && imageDetails.hasOwnProperty("totalNumberOfMediaLinks") ? imageDetails.totalNumberOfMediaLinks : 0)}</span>
                                {t('import_image.addresses_analysed.3')}
                            </p> : <p>{t('import_image.addresses_in_progress')}</p>}
                        <div className="analyzed-image-info-stats">
                            <ImportImageStatList
                                imageDetails={(imageDetails && imageDetails.hasOwnProperty("totalNumberOfMediaLinks")) ? imageDetails : defaultImageDetails}
                                onClick={onClick}/>
                            {(imageDetails && imageDetails.hasOwnProperty("totalNumberOfMediaLinks")) &&
                            <ImportImagePieChart imageDetails={imageDetails}/>}
                        </div>
                    </div>
                </div>
            </div>
            {textDetected && <div className="analyzed-image-text-container">
                <div className="analyzed-image-text">
                    <div className="analyzed-image-text-content">
                        <img src={iconAnalyzedText} alt=""/>
                        <p>{t('import_image.analyzed_image.label')}</p>
                    </div>
                    <div className="analyzed-image-text-button">
                        <a href={textDetectedUrl} onClick={handleOnClick}>{t('import_image.analyzed_image.button')}</a>
                    </div>
                </div>
            </div>}
        </article>
    );
};

export default SearchResultImage;
