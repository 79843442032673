import React, {Component} from "react";
import {SubjectResult} from "./index";
import {bindActionCreators} from "redux";
import {ListHelpers} from "../../helpers";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {SearchResult} from "../searchResult";
import {isEqual} from "lodash";
import {searchSubjects} from "../../actions/subjectActions";
import {updateSearchOptions} from "../../actions/searchOptionsActions";
import {GoogleService, SearchService} from "../../services";
import {updateSearchParams} from "../../actions/searchParamsActions";

class SubjectPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subjects: [...this.props.subjects],
            searchOptions: {...this.props.searchOptions},
            loading: false,
            searchParams: {...this.props.searchParams}
        };
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.subjects.length > 0 && ListHelpers.getDifferences(props.subjects, state.subjects).length > 0) {
            return {subjects: [...props.subjects], loading: false};
        }

        if (props.searchOptions.pageNumber !== state.searchOptions.pageNumber) {
            return {searchOptions: {...props.searchOptions}};
        }

        if (!isEqual(state.searchParams, props.searchParams)) {
            return {subjects: [], searchParams: {...props.searchParams}};
        }

        return null;
    }

    componentWillUnmount() {
        this.setState({
            subjects: [],
            searchOptions: {...this.props.searchOptions},
            loading: false
        });
        this.props.updateSearchOptions({pageSize: 3, pageNumber: 1});
    }

    handleOnClick(event) {
        event.preventDefault();
        const {subjectsCount} = this.props;
        let _searchOptions = {...this.state.searchOptions};
        if (Math.ceil(subjectsCount / _searchOptions.pageSize) >= _searchOptions.pageNumber + 1) {
            _searchOptions.pageNumber += 1;
            this.setState({
                searchOptions: _searchOptions,
                loading: true
            });
            this.props.updateSearchOptions({..._searchOptions});
            GoogleService.getToken('searchSubjects', (token) => {
                this.props.searchSubjects({
                    ...this.props.searchParams,
                    searchOptions: {..._searchOptions},
                    recaptchaToken: token
                });
            }, this.props.dispatch);
        }
    }

    render() {
        const {subjects, searchOptions, loading, searchParams} = this.state;
        const {t, tags, search} = this.props;
        const disableButtonLoadMore = !(Math.ceil(this.props.subjectsCount / searchOptions.pageSize) >= searchOptions.pageNumber + 1);

        return (
            <>
                <section id="finder-results" className="grey-section">
                    <h2>{t('search_result.title')}</h2>
                    <SearchResult text={searchParams.text} tags={tags} isFetching={search && search[1].isFetching}/>
                </section>

                {(subjects && subjects.length > 0) &&
                <SubjectResult disableButtonLoadMore={disableButtonLoadMore}
                               loading={loading}
                               subjects={subjects}
                               handleOnClick={this.handleOnClick} source="ResultList"/>}

                {(subjects.length === 0 && tags.length > 0 && (search && !search[1].isFetching)) &&
                <aside id="results-evaluation">
                    <div className="container">
                        <div className="evaluation no-results">
                            <h3>{t('subjects_page.no_results_message')}</h3>
                        </div>
                    </div>
                </aside>}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchSubjects: bindActionCreators(searchSubjects, dispatch),
        updateSearchOptions: bindActionCreators(updateSearchOptions, dispatch),
        updateSearchParams: bindActionCreators(updateSearchParams, dispatch),
        dispatch
    };
};

const mapStateToProps = (state) => {

    let {scope, searchParams} = SearchService.getScope(state);

    let {subjects, subjectsCount} = SearchService.getSubjects(state, scope, state.searchOptions.pageNumber);

    return {
        searchParams: searchParams,
        searchOptions: state.searchOptions,
        subjects: subjects,
        subjectsCount: subjectsCount,
        tags: state.tags,
        search: state.searches[scope]
    }
};

SubjectPage.propTypes = {};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SubjectPage));