import React from 'react';
import {useTranslation} from "react-i18next";
import {isFirefox} from "react-device-detect";
import InstallPromptHeader from "./InstallPromptHeader";
import InstallPromptFAQContent from "./InstallPromptFAQContent";
import {connect} from "react-redux";
import {setInstallPromptClosed} from "../../actions/installPromptActions";
import {INSTALL_PROMPT_OPEN_CLASS} from "../../core/Constants";

const InstallPromptIOS = ({setInstallPromptClosed}) => {
    const {t} = useTranslation();

    const handleOnClickClose = event => {
        event.preventDefault();
        setInstallPromptClosed(true);
        document.body.classList.remove(INSTALL_PROMPT_OPEN_CLASS);
    };

    return (
        <div id="install-prompt">
            <div className="download-popup-fixed">
                <div className="download-popup-fixed-content">
                    <InstallPromptHeader/>
                    <div className="download-bar">
                        <p className="green-text no-margin">
                            {t("install_prompt.ios.download.title")}
                        </p>
                        <div className="content-holder">
                        <span className="white-subtitle">
                            {t("install_prompt.ios.download.subtitle")}
                        </span>
                            <span className="share-icon"/>
                            <span className="white-subtitle">
                            {t(`install_prompt.ios.download.${isFirefox ? "firefox_" : ""}second_subtitle`)}
                        </span>
                            <span className="white-subtitle bold-text">
                            {t(`install_prompt.ios.download.${isFirefox ? "firefox_" : ""}third_subtitle`)}
                        </span>
                            {isFirefox &&
                            <span className="white-subtitle">
                            {t("install_prompt.ios.download.firefox_fourth_subtitle")}
                        </span>}
                        </div>
                    </div>
                    <InstallPromptFAQContent/>
                    <div className="button-content">
                        <button className="button-light-green" onClick={handleOnClickClose}>
                            {t("install_prompt.ios.submit_button")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {setInstallPromptClosed};

export default connect(null, mapDispatchToProps)(InstallPromptIOS);