import React from 'react';
import PropTypes from 'prop-types';

const ImportImageDomainUrlListRow = ({url, analysisUrl}) => {

    const handleOnClick = () => {
        if (!analysisUrl) return;
        window.open(analysisUrl, '_blank');
    };

    return (
        <li key={url}>
            {analysisUrl && <span className="tooltip" onClick={handleOnClick}/>}
            <a href={url} target="_blank" rel="noreferrer noopener">{url}</a>
        </li>
    );
};

ImportImageDomainUrlListRow.propTypes = {
    url: PropTypes.string.isRequired,
    analysisUrl: PropTypes.string,
};

export default ImportImageDomainUrlListRow;