import * as types from "./actionTypes";

export const setInstallPromptClosed = (value) => {
    return {
        type: types.SET_INSTALL_PROMPT_CLOSED,
        payload: {value}
    };
};

export const setHasClickOnInstallButton = (value) => {
    return {
        type: types.SET_HAS_CLICK_ON_INSTALL_BUTTON,
        payload: {value}
    }
}

export const setCookieIsAccepted = value => ({
    type: types.SET_COOKIE_IS_ACCEPTED,
    payload: {value}
});