import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {AppConfig} from "../../config"

const MainMenu = ({isFooter, onClick}) => {
    const {t, i18n} = useTranslation();
    return (
        <nav className={isFooter ? "footer-main-menu" : "main-menu"}>
            <ul>
                <li>
                    <NavLink
                        to={`/${i18n.language}/${t('routes.who_we_are_route')}`}
                        onClick={onClick}>{t('main_menu.link_1')}</NavLink>
                </li>
                {AppConfig.PROGRESSIVE_WEB_APP_POPUP_ENABLED &&
                <li>
                    <NavLink
                        to={`/${i18n.language}/${t('routes.fakyzation')}`}
                        onClick={onClick}>{t('main_menu.link_2')}</NavLink>
                </li>}
                <li>
                    <NavLink
                        to={`/${i18n.language}/${t('routes.our_sources_route')}`}
                        onClick={onClick}>{t('main_menu.link_3')}</NavLink>
                </li>
                <li>
                    <NavLink
                        to={`/${i18n.language}/${t('routes.how_does_it_work_route')}`}
                        onClick={onClick}>{t('main_menu.link_4')}</NavLink>
                </li>
                <li>
                    <NavLink
                        to={`/${i18n.language}/${t('routes.how_does_it_work_images_route')}`}
                        onClick={onClick}>{t('main_menu.link_5')}</NavLink>
                </li>

                <li>
                    <NavLink
                        to={`/${i18n.language}/${t('routes.contact_us_route')}`}
                        onClick={onClick}>{t('main_menu.link_6')}</NavLink>
                </li>
            </ul>
        </nav>
    );
};

MainMenu.propTypes = {
    isFooter: PropTypes.bool,
};

export default MainMenu;