import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const LegalMenu = ({ isFooter = false, onClick }) => {
  const { t, i18n } = useTranslation();
  return (
    <nav className={isFooter ? "footer-legal-menu" : "legal-menu"}>
      <ul>
        <li>
          <NavLink
            to={`/${i18n.language}/${t("routes.legal_mention_route")}`}
            onClick={onClick}
          >
            {t("legal_menu.link_1")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/${i18n.language}/${t("routes.privacy_route")}`}
            onClick={onClick}
          >
            {t("legal_menu.link_2")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/${i18n.language}/${t(
              "routes.who_we_are_route"
            )}#rtbfinitiative`}
            onClick={onClick}
          >
            {t("legal_menu.link_3")}
          </NavLink>
        </li>
        <li>
          <span>{t("legal_menu.copyright")} {new Date().getFullYear()} {t("legal_menu.copyright2")}</span>
        </li>
      </ul>
    </nav>
  );
};

LegalMenu.propTypes = {
  isFooter: PropTypes.bool
};

export default LegalMenu;
