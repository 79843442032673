import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {AnalysisContentListRow, AnalysisHeaderListRow} from "./";
import {Trans, useTranslation} from "react-i18next";
import {ScrollService} from "../../services";

const AnalysisListRow = ({analysis}) => {
    const {t} = useTranslation();

    let [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
    useEffect(() => {
        setIsDescriptionOpen(false);
    }, [analysis]);

    const handleOnClick = (event) => {
        event.preventDefault();
        setIsDescriptionOpen(!isDescriptionOpen);
        if (!isDescriptionOpen) {
            ScrollService.scrollToElement(event.target.getAttribute("data-id"), 10);
        }
    };

    let emptyScoringCssClass = (!analysis.scoring && analysis.scoring !== 0) ? 'article-empty-analysis' : '';
    let dimensionDescriptionKey = `analysis_list_row.${analysis.origin.toLowerCase()}.dimension_description`;

    return (
        <>
            <h6 className="title-analysis">
                <span className="title-text">{t(`analysis_list_row.${analysis.origin.toLowerCase()}.dimension`)}</span>
                <span className="info-accordion">
                    <button className="btn-info">
                        i
                </button>
                    <div className="info-accordion-content">
                        <Trans i18nKey={dimensionDescriptionKey}/>
                    </div>
                </span>
            </h6>

            <article className={`article-partner-result ${emptyScoringCssClass}`}
                     id={`${analysis.origin.toLowerCase()}DescriptionToggle`}>
                <AnalysisHeaderListRow handleOnClick={handleOnClick} isDescriptionOpen={isDescriptionOpen}
                                       {...analysis} />
                <AnalysisContentListRow isDescriptionOpen={isDescriptionOpen} analysis={analysis}/>
            </article>
        </>
    );
};

AnalysisListRow.propTypes = {
    analysis: PropTypes.object.isRequired,
};

export default React.memo(AnalysisListRow);