import React from 'react';
import {useTranslation} from "react-i18next";
import {isFirefox, isMobile} from "react-device-detect";
import {NavLink} from "react-router-dom";

const ShareSectionMobile = () => {
    const {t, i18n} = useTranslation();

    const handleOnClick = () => {
        window.navigator.share({
            title: t('meta.title.main'),
            text: '',
            url: document.location.href
        });
    };

    if (isFirefox || !isMobile) return <></>;

    return (
        <div className="share-section-mobile">
            <div className="container">
           
                <div className="share-content-center">
                <div className="share-text">
                        <h3>{t('share_section.text')}</h3>
                    </div>
                    <div className='icon-share' onClick={handleOnClick}/>
                    
                </div>
            </div>
            <section id="contact-us">
                <h2>{t('share_section.contact_title')}</h2>
                <div className="contact-us-button">
                    <NavLink
                        to={`/${i18n.language}/${t('routes.contact_us_route')}`}>
                        {t('main_menu.link_6')}
                    </NavLink>
                </div>
            </section>
        </div>
    );
};

export default ShareSectionMobile;
