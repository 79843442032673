import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {Enums} from "../../core";
import {CssClassHelpers} from "../../helpers";

const ScoringListRow = ({scoring, isActive}) => {
    const {t} = useTranslation();
    return (
        <li className={isActive ? `${CssClassHelpers.mapScoringToCssClass(scoring)} active`  : `${CssClassHelpers.mapScoringToCssClass(scoring)}`}>
            <span className={`category dot ${CssClassHelpers.mapScoringToCssClass(scoring)} bg`}/>
            <span>{t(`scoring.${Enums.getKey(Enums.Scoring,scoring)}`)}</span>
        </li>
    );
};

ScoringListRow.propTypes = {
    isActive: PropTypes.bool,
    scoring: PropTypes.number.isRequired,
};

export default ScoringListRow;