export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const MAX_LENGTH_SEARCH_TEXT = 1900;

export const FORM_TABS = {
    KEYWORD_FORM_TAB: 'keyword',
    ARTICLE_FORM_TAB: 'article',
    IMAGE_FORM_TAB: 'image'
};

export const INSTALL_PROMPT_COOKIE_CONSENT_KEY = "InstallPromptConsent";

export const COOKIE_CONSENT_KEY = "CookieConsent";

export const GOOGLE_RECAPTCHA_ACTION_NAME_IMAGE = "uploadImage";

export const INSTALL_PROMPT_OPEN_CLASS = "install-prompt-open";

export const FAKY_PWA_INSTALLED = "FAKY_PWA_INSTALLED";