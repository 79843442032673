import React from 'react';
import { withRouter } from '../../hooks/useWithRouter';
import logoSquare from '../../assets/images/logo-square.svg';
import { LegalMenu, MainMenu } from './';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DeviceService, RouteService } from '../../services';

const Footer = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const displayContactUsButton = DeviceService.isMobile() && RouteService.getCurrentRouteName(location, i18n.language) !== t('routes.contact_us_route');

    return (
        <>
            {displayContactUsButton && <NavLink className="corona-message-mobile" to={`/${i18n.language}/${t('routes.contact_us_route')}`}>
                <div>
                    {t('main_menu.link_6')}
                </div>
            </NavLink>}
            <footer id="footer">
                <div id="footer-content" className="container">
                    <div id="footer-branding">
                        <img src={logoSquare} alt="Faky" />
                    </div>
                    <div id="footer-menu">
                        <MainMenu isFooter={true} />
                        <LegalMenu isFooter={true} />
                    </div>
                </div>
            </footer>
        </>
    );
};

export default withRouter(Footer);
