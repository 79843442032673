import React from 'react';
import { withRouter } from "../../hooks/useWithRouter";
import {useLocation, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import logoMobile from '../../assets/images/Faky-Logo-Beta-Mobile.svg';
import logoBeta from '../../assets/images/Faky-Logo-Beta.svg'
import { HeaderPanel, HeaderContactUs, HeaderMenu } from "./index";
import { RouteService, ScrollService, DeviceService } from "../../services";
import { Enums } from "../../core";

const Header = ({ updateImportImage, setSearchButtonDisabled }) => {
    const { t, i18n } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();
    const showContactUsButton = RouteService.getCurrentRouteName(location, i18n.language) !== t('routes.contact_us_route');

    const toggleMenu = () => {
        document.body.classList.toggle('menu-open');
    };

    const focusOnSearchField = () => {
        if (RouteService.getCurrentRouteName(location, i18n.language) !== i18n.language) {
            setSearchButtonDisabled(false);
            RouteService.redirect(Enums.Routes.Home, "", i18n.language, navigate, t);
        } else if (document.getElementById("search-field")) {
            document.getElementById("search-field").focus({ preventScroll: true });
            ScrollService.scrollToElement("search-field");
            document.getElementById("search-field").value = '';
        } else if (document.getElementById("image-search-field")) {
            updateImportImage({ uploadedImage: { correlationId: 0 } });
        }
    };

    return (
        <header id="header">
            <div id="header-content" className="container">
                <div id="branding">
                    <div className="logo" onClick={focusOnSearchField}>
                        {DeviceService.isMobile() ? <img src={logoMobile} alt="Faky" /> :
                            <img src={logoBeta} alt="Faky" />}
                    </div>
                    <span className="slogan">{t('faky_slogan')}</span>
                </div>
                <div id="header-menu">
                    <HeaderMenu />
                    {showContactUsButton && <HeaderContactUs />}
                    <button className="button-back-to-top" onClick={focusOnSearchField}><span>Back to top</span>
                    </button>
                    <button className="button-menu-open menu-toggle" onClick={toggleMenu}>Menu</button>
                    <HeaderPanel onClose={toggleMenu} />
                    <div className="overlay menu-toggle" onClick={toggleMenu} />
                </div>
            </div>
        </header>
    );
};

export default withRouter(Header);
