import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import App from "../components/App";
import {withTranslation} from 'react-i18next';
import PageLanding from "../landingPage";
import ShareTargetPage from '../components/sharetarget/ShareTargetPage';
import ShareTargetImagePage from "../components/sharetarget/ShareTargetImagePage";

const DefaultRoutes = (props) => {
    const {i18n} = props;

    return (
        <Routes>
            <Route path="/share-target" element={<ShareTargetPage/>} />
            <Route path="/share-image" element={<ShareTargetImagePage/>} />
            <Route path={`/${i18n.language}/Oups`} element={<PageLanding/>}/>
            <Route exact path={'/'} element={<App/>}/>
            <Route path={`/:locale/*`} element={<App/>} />
            <Route element={<Navigate to={`/${i18n.language}/404`} replace />} />
        </Routes>
    );
};

export default withTranslation("route")(DefaultRoutes);