import React from 'react';
import {useTranslation} from "react-i18next";
import {withRouter} from "../../hooks/useWithRouter";
import {RouteService} from "../../services";
import {Enums} from "../../core";
import {useNavigate} from "react-router-dom";

const NotFoundPage = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const handleOnClick = (event) => {
        event.preventDefault();
        RouteService.redirect(Enums.Routes.Home, "", i18n.language, navigate, t);
    };

    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <h1>{t('not_found_page.title')}</h1>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="form-message">
                        <p>{t('not_found_page.text')}</p>
                    </div>
                    <div className="form-actions">
                        <input type="submit" value={t('not_found_page.link')}
                               onClick={handleOnClick}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(NotFoundPage);