import initialState from "./initialState";
import {UPDATE_SEARCH_PARAMS} from "../actions/actionTypes";

export default function searchParamsReducer(state = initialState.searchParams, action) {
    if (action.type === UPDATE_SEARCH_PARAMS) {
        return {
            ...state,
            ...action.payload
        };
    }

    return state;
}