import React from 'react';
import PropTypes from 'prop-types';
import {Enums} from "../../core";
import {DecodeurAnalysisContentListRow} from '../Decodeur';
import {DDAnalysisContentListRow} from '../DD';
import {DecodexAnalysisContentListRow} from '../Decodex';
import {TextGainAnalysisContentListRow} from "../TextGain";
import {NeutralAnalysisContentListRow} from "../Neutral";
import {LynxAnalysisContentListRow} from "../Lynx";

const AnalysisContentListRow = ({isDescriptionOpen, analysis}) => {
    const _origin = analysis && analysis.origin ? analysis.origin.toLowerCase() : "";
    
    switch (_origin) {
        case Enums.AnalysisOrigin.Decodeur:
            return <DecodeurAnalysisContentListRow isDescriptionOpen={isDescriptionOpen} {...analysis}/>;
        case Enums.AnalysisOrigin.Decodex:
            return <DecodexAnalysisContentListRow isDescriptionOpen={isDescriptionOpen} {...analysis}/>;
        case Enums.AnalysisOrigin.DD:
            return <DDAnalysisContentListRow isDescriptionOpen={isDescriptionOpen} {...analysis}/>;
        case Enums.AnalysisOrigin.TextGain:
            return <TextGainAnalysisContentListRow isDescriptionOpen={isDescriptionOpen} {...analysis}/>;
        case Enums.AnalysisOrigin.Neutral:
            return <NeutralAnalysisContentListRow isDescriptionOpen={isDescriptionOpen} {...analysis}/>;
        case Enums.AnalysisOrigin.Lynx:
            return <LynxAnalysisContentListRow isDescriptionOpen={isDescriptionOpen} {...analysis}/>;
        default:
            return null;
    }
};

AnalysisContentListRow.propTypes = {
    isDescriptionOpen: PropTypes.bool,
    analysis: PropTypes.object,
};

export default AnalysisContentListRow;
