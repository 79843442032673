import React from 'react';
import noImage from "../../assets/images/noimage-ico.svg";
import {useTranslation} from "react-i18next";

const NoImage = () => {
    const {t} = useTranslation();
    return (
        <div className="no-image">
            <img src={noImage} alt=""/>
            <span>{t('no_image.text')}</span>
        </div>
    );
};

export default NoImage;