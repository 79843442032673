import React from 'react';
import {useTranslation} from "react-i18next";
import {RouteService} from "../../services";
import {Enums} from "../../core";
import {useNavigate} from "react-router-dom";
import {setInstallPromptClosed} from "../../actions/installPromptActions";
import {connect} from "react-redux";
import {INSTALL_PROMPT_OPEN_CLASS} from "../../core/Constants";

const InstallPromptFAQContent = ({setInstallPromptClosed}) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const handleOnClick = event => {
        event.preventDefault();
        RouteService.redirect(Enums.Routes.Fakyzation, "", i18n.language, navigate, t);
        setInstallPromptClosed(true);
        document.body.classList.remove(INSTALL_PROMPT_OPEN_CLASS);
    };

    return (
        <div className="contact">
            <p className="white-subtitle bold-text">
                {t("install_prompt.faq_content.title")}
                <span className="green-text" onClick={handleOnClick}>
                    {t("install_prompt.faq_content.subtitle")}
                </span>
            </p>
        </div>
    );
};

const mapDispatchToProps = {setInstallPromptClosed}

export default connect(null, mapDispatchToProps)(InstallPromptFAQContent);