import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "../../hooks/useWithRouter";
import {SearchFieldArticleForm, SearchFieldImageForm, SearchFieldKeywordForm} from "./index";
import {UrlHelpers} from "../../helpers";
import {GoogleService, RouteService, ScrollService, SearchService} from "../../services";
import {Enums} from "../../core";
import {updateSearchParams} from "../../actions/searchParamsActions";
import {updateSearchOptions} from "../../actions/searchOptionsActions";
import {cleanSearchFields, searchSubjects} from "../../actions/subjectActions";
import {setSearchButtonDisabled, triggerImportSubject, updateImportSubject} from "../../actions/importSubjectActions";
import {deburr, isEqual, snakeCase} from "lodash";
import {getEmbed} from "../../actions/linkActions";
import {updateSearchTags} from "../../actions/tagActions";
import {triggerImage, updateImportImage, uploadImage, uploadImageFromUrl} from "../../actions/importImageActions";
import {TooManyRequest} from "../common";
import {setIsTooManyRequestError} from "../../actions/ErrorActions";
import * as ReactGA from "react-ga";
import {FORM_TABS, MAX_LENGTH_SEARCH_TEXT} from "../../core/Constants";
import {getActiveTab} from "../../utils/searchfield-utils";

class SearchField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchParams: {},
            activeTab: FORM_TABS.ARTICLE_FORM_TAB,
            loading: false,
            errors: "",
            text: '',
            needToScroll: true,
            hasBeenRedirected: false,
            hasBeenTriggered: false,
            percentCompleted: 0
        };

        this.search = this.search.bind(this);
        this.defaultSearchOptions = {pageNumber: 1, pageSize: 3};
        this.updateSearchOptionsAndSearchParams = this.updateSearchOptionsAndSearchParams.bind(this);
        this.getInformationFromSearch = this.getInformationFromSearch.bind(this);
        this.handleOnClickClipboard = this.handleOnClickClipboard.bind(this);

    }

    static getDerivedStateFromProps(props, state) {
        if (props.searchParams.text !== '' && props.searchParams.text !== state.searchParams.text && (!state.searchParams.url || state.searchParams.url === '')) {
            let _text = props.searchParams.text ? props.searchParams.text : props.searchParams.url;
            props.updateSearchParams({...props.searchParams});

            let _defaultSearchOptions = {pageNumber: 1, pageSize: 3};

            GoogleService.getToken('searchSubjects', (token) => {
                let _request = {...props.searchParams, searchOptions: _defaultSearchOptions, recaptchaToken: token};
                props.searchSubjects(_request);
            }, props.dispatch);

            return {
                searchParams: {...props.searchParams},
                text: _text,
                hasBeenRedirected: true,
                activeTab: getActiveTab(_text)
            };
        }

        if (props.searchParams.url !== '' && props.searchParams.url !== state.searchParams.url && !state.loading) {
            props.updateSearchParams({...props.searchParams});
            props.getEmbed(encodeURIComponent(props.searchParams.url));
            return {searchParams: {...props.searchParams}, text: props.searchParams.url, hasBeenRedirected: true};
        }

        return null;
    }

    hasSearchLinkSuccess() {
        const url = encodeURIComponent(this.props.searchParams.url);
        const scope = Enums.Scopes.SearchLink + '_' + url;

        return this.props.searches[scope] && this.props.searches[scope][1];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {i18n, router, t, recaptcha} = this.props;
        const {scope} = SearchService.getScope(this.state);
        const _search = this.props.searches && this.props.searches.hasOwnProperty(scope) && this.props.searches[scope].hasOwnProperty(1) ? this.props.searches[scope][1] : {};
        const {needToTrigger, needToRedirect, subjectId, subjectTitle} = this.getInformationFromSearch(_search);

        if (needToTrigger && !this.state.hasBeenRedirected && !this.state.hasBeenTriggered && !recaptcha.isFetching) {
            this.setState({hasBeenTriggered: true});
            this.props.triggerImportSubject({url: this.state.searchParams.url});
        }

        if (needToRedirect && !this.state.hasBeenRedirected) {
            ReactGA.event({
                category: 'Trigger',
                action: 'UrlExist',
                label: this.state.searchParams.url
            });
            this.updateSearchOptionsAndSearchParams();
            this.props.getEmbed(encodeURIComponent(this.state.searchParams.url));
            let processedTitle = deburr(subjectTitle);
            processedTitle = snakeCase(processedTitle);
            if (processedTitle == null || processedTitle === '') {
                RouteService.redirect(Enums.Routes.SubjectDetail, `${subjectId}?searchUrl=${encodeURIComponent(this.state.searchParams.url)}`, i18n.language, router.navigate, t);
            } else {
                RouteService.redirect(Enums.Routes.SubjectDetail, `${subjectId}/${processedTitle}?searchUrl=${encodeURIComponent(this.state.searchParams.url)}`, i18n.language, router.navigate, t);
            }
        }

        if (prevProps.triggeredImage.correlationId !== this.props.triggeredImage.correlationId) {
            this.setState({hasBeenRedirected: true});
            RouteService.redirect(Enums.Routes.ImportImage, `${this.props.triggeredImage.correlationId}`, i18n.language, router.navigate, t);
        }

        if (prevProps.importSubject.correlationId !== this.props.importSubject.correlationId) {
            ReactGA.event({
                category: 'Trigger',
                action: 'UrlNew',
                label: this.state.searchParams.url
            });
            this.updateSearchOptionsAndSearchParams();
            this.props.updateImportSubject({
                correlationId: this.props.importSubject.correlationId,
                status: [],
                disableSearchButton: this.props.importSubject.disableSearchButton
            });
            RouteService.redirect(Enums.Routes.ImportStatus, `${this.props.importSubject.correlationId}?searchUrl=${encodeURIComponent(this.state.searchParams.url)}`, i18n.language, router.navigate, t);
        }

        if (!isEqual(prevProps.searchParams, this.props.searchParams)) {
            let _text = this.props.searchParams.text ? this.props.searchParams.text : this.props.searchParams.url;
            this.setState({searchParams: {...this.props.searchParams}, text: _text});
        }

        if (this.state.needToScroll && (_search.isSuccess || this.hasSearchLinkSuccess()) && this.state.hasBeenRedirected) {
            this.setState({needToScroll: false});
            ScrollService.scrollToElement("finder-results");
        }

        const {triggeredImage} = this.props;

        if (this.state.needToScroll && this.state.hasBeenRedirected && triggeredImage.correlationId !== 0) {
            this.setState({needToScroll: false, loading: false});
            ScrollService.scrollToElement("finder-results");
        }


        if (this.props.isTooManyRequestError !== prevProps.isTooManyRequestError && this.props.isTooManyRequestError) {
            if (RouteService.getCurrentRouteName(router.location, i18n.language) !== i18n.language) {
                setSearchButtonDisabled(false);
                RouteService.redirect(Enums.Routes.Home, "", i18n.language, router.navigate, t);
            }
        }
    }

    getInformationFromSearch(_search) {
        const needToTrigger = this.state.searchParams.url !== '' && _search.isSuccess && _search.elements.length === 0;
        const needToRedirect = this.state.searchParams.url !== '' && _search.isSuccess && _search.elements.length > 0;
        const subjectId = this.state.searchParams.url !== '' && _search.isSuccess && _search.elements.length > 0 ? _search.elements[0] : 0;
        const subjectTitle = this.state.searchParams.url !== '' && _search.isSuccess && _search.elements.length > 0 ? this.props.subjects.byId[_search.elements[0]].title : '';
        return {needToTrigger, needToRedirect, subjectId, subjectTitle};
    }

    updateSearchOptionsAndSearchParams() {
        this.setState({loading: false, hasBeenRedirected: true, disableSearch: false});
        this.props.updateSearchParams({...this.state.searchParams});
        this.props.updateSearchOptions(this.defaultSearchOptions);
        this.props.setSearchButtonDisabled(false);
    }

    handleOnClickClipboard = (event) => {
        event.preventDefault();
        let paste = event.clipboardData && event.clipboardData.getData ?
            event.clipboardData.getData("text/plain") :
            window.clipboardData && window.clipboardData.getData ?
                window.clipboardData.getData("Text") :
                false;

        if (paste === false && navigator.clipboard && navigator.clipboard.readText) {
            navigator.clipboard.readText().then(text => {
                ReactGA.event({
                    category: 'Search',
                    action: 'PasteFromClipboard',
                    label: text
                });
                this.setState({text: text, activeTab: getActiveTab(text)})
            });
        } else if (paste !== false) {
            ReactGA.event({
                category: 'Search',
                action: 'PasteFromClipboard',
                label: paste,
                activeTab: getActiveTab(paste)
            });
            this.setState({text: paste});
        }
    };

    handleOnclick = (event) => {
        event.preventDefault();
        this.props.cleanSearchFields();
        this.props.setSearchButtonDisabled(true);
        this.search();
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            this.search();
        }
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            let text = this.state.text;
            text += '\n';
            this.setState({text: text});
        }
    };

    search = () => {
        this.setState({needToScroll: true, hasBeenRedirected: false, loading: true, hasBeenTriggered: false});

        const {i18n, router, t} = this.props;

        if (!this.state.text) {
            this.setState({errors: t('search_field.errors.message'), loading: false});
            this.props.setSearchButtonDisabled(false);
            return;
        }

        let updatedText = this.state.text.length > MAX_LENGTH_SEARCH_TEXT ? this.state.text.substring(0, MAX_LENGTH_SEARCH_TEXT) : this.state.text;
        let _defaultSearchOptions = {pageNumber: 1, pageSize: 3};
        let _searchParams = {text: updatedText, url: ''};

        if (UrlHelpers.validUrl(_searchParams.text)) {
            _searchParams = {..._searchParams, text: '', url: _searchParams.text};
            this.setState({searchParams: {..._searchParams}, activeTab: FORM_TABS.ARTICLE_FORM_TAB});
        }

        const urls = UrlHelpers.detectURLs(_searchParams.text);
        if (urls && urls.length > 0) {
            _searchParams = {..._searchParams, text: '', url: urls[0]};
            this.setState({searchParams: {..._searchParams}, activeTab: FORM_TABS.ARTICLE_FORM_TAB});
        }

        if (_searchParams.text !== '') {
            this.props.updateSearchParams(_searchParams);
            this.props.updateSearchOptions(_defaultSearchOptions);
            this.setState({
                hasBeenRedirected: true,
                loading: false,
                searchParams: {..._searchParams},
                activeTab: FORM_TABS.KEYWORD_FORM_TAB
            });
            GoogleService.getToken('searchSubjects', (token) => {
                let _request = {..._searchParams, searchOptions: _defaultSearchOptions, recaptchaToken: token};
                this.props.searchSubjects(_request);
            }, this.props.dispatch);
            this.props.updateSearchTags([]);
            let processedText = snakeCase(_searchParams.text);
            RouteService.redirect(Enums.Routes.Subject, processedText, i18n.language, router.navigate, t);
            this.props.setSearchButtonDisabled(false);
            ReactGA.event({
                category: 'Search',
                action: 'Click',
                label: _searchParams.text
            });
            return;
        }

        GoogleService.getToken('searchSubjects', (token) => {
            this.props.searchSubjects({..._searchParams, searchOptions: _defaultSearchOptions, recaptchaToken: token});
        }, this.props.dispatch);
    };

    handleOnChange = (event) => {
        this.setState({
            text: event.target.value,
            errors: "",
            activeTab: getActiveTab(event.target.value)
        });
    };

    handTabsOnclick = (event) => {
        event.preventDefault();
        const activeTab = event.target.getAttribute("data-id");
        this.setState({activeTab});

        this.handleResetUploadImage();
    };

    handleRedirectKeywordButton = (event) => {
        event.preventDefault();

        this.setState({text: '', errors: '', activeTab: FORM_TABS.ARTICLE_FORM_TAB, loading: false});
        this.props.setIsTooManyRequestError(false);

        const {i18n, router, t} = this.props;

        if (RouteService.getCurrentRouteName(router.location, i18n.language) !== i18n.language) {
            RouteService.redirect(Enums.Routes.Home, "", i18n.language, router.navigate, t);
        }

        this.props.updateImportImage({uploadedImage: {correlationId: 0}});
        this.props.setSearchButtonDisabled(false);
    };

    handleRetryOnClick = (event) => {
        event.preventDefault();
        this.setState({loading: false});
        this.props.setIsTooManyRequestError(false);
        this.props.setSearchButtonDisabled(false);
    };

    handleUploadImage = (formData, file, token) => {
        this.props.uploadImage(formData, file, token, (percentCompleted) => {
            this.setState({percentCompleted});
        });
    };

    handleUploadImageFromUrl = (url, token) => {
        this.props.uploadImageFromUrl(url, token, (percentCompleted) => {
            this.setState({percentCompleted});
        });
    };

    triggerImage = (event) => {
        event.preventDefault();
        const {name, size, url, correlationId} = this.props.uploadedImage;
        this.setState({needToScroll: true, hasBeenRedirected: false, loading: true});
        this.props.setSearchButtonDisabled(true);
        this.props.triggerImage(correlationId, url, name, size);
        ReactGA.event({
            category: 'Trigger',
            action: 'Image',
            label: name
        });
    };

    handleImageOnClickClipboard = (event) => {
        event.preventDefault();
        let paste = event.clipboardData && event.clipboardData.getData ?
            event.clipboardData.getData("text/plain") :
            window.clipboardData && window.clipboardData.getData ?
                window.clipboardData.getData("Text") :
                false;

        if (paste === false && navigator.clipboard && navigator.clipboard.readText) {
            navigator.clipboard.readText().then(text => {
                GoogleService.getToken('uploadImage', (token) => {
                    ReactGA.event({
                        category: 'ImportImage',
                        action: 'PasteFromClipboard',
                        label: text
                    });
                    this.props.uploadImageFromUrl(text, token, (percentCompleted) => {
                        this.setState({percentCompleted});
                    });
                }, this.props.dispatch);
            });

        } else if (paste !== false) {
            GoogleService.getToken('uploadImage', (token) => {
                ReactGA.event({
                    category: 'ImportImage',
                    action: 'PasteFromClipboard',
                    label: paste
                });
                this.props.uploadImageFromUrl(paste, token, (percentCompleted) => {
                    this.setState({percentCompleted});
                });
            }, this.props.dispatch);
        }
    };

    handleResetUploadImage = () => {
        const {i18n, router, t} = this.props;
        if (RouteService.getCurrentRouteName(router.location, i18n.language) !== i18n.language) {
            this.props.setSearchButtonDisabled(false);
            RouteService.redirect(Enums.Routes.Home, "", i18n.language, router.navigate, t);
        } else if (document.getElementById("search-field")) {
            document.getElementById("search-field").focus({preventScroll: true});
            this.setState({text: '', errors: ''});
        } else if (document.getElementById("image-search-field")) {
            this.props.updateImportImage({uploadedImage: {correlationId: 0}});
        }
    };

    render() {
        const {t, uploadedImage, isTooManyRequestError} = this.props;
        const {disableSearchButton} = this.props.importSubject;
        const {text, activeTab, errors, loading, percentCompleted} = this.state;

        return (
            <section id="finder" className="grey-section">
                <h1>{t('search_field.title')}</h1>
                <div className="container">
                    <div className="finder-forms">
                        <div className="scroll-indicator">
                            <span>Scroll</span>
                        </div>
                        {!isTooManyRequestError &&
                        <ul className="form-tabs">
                            {Object.values(FORM_TABS).map((tab, index) =>
                                <li key={index} className={activeTab === tab ? "active" : ""}>
                                    <a href={`#${tab}-form`} onClick={this.handTabsOnclick} data-id={tab}>
                                        <div className={`form-tabs-${tab}`} data-id={tab}/>
                                        <span data-id={tab}>{t(`search_field.tab_${tab}`)}</span>
                                    </a>
                                </li>
                            )}
                        </ul>}
                        {(!isTooManyRequestError && activeTab === FORM_TABS.KEYWORD_FORM_TAB) &&
                        <SearchFieldKeywordForm handleOnclick={this.handleOnclick} text={text}
                                                handleOnChange={this.handleOnChange}
                                                className="active"
                                                handleKeyDown={this.handleKeyDown}
                                                handleOnClickClipboard={this.handleOnClickClipboard}
                                                errors={errors}
                                                loading={loading}
                                                disableSearch={disableSearchButton}
                        />}
                        {(!isTooManyRequestError && activeTab === FORM_TABS.ARTICLE_FORM_TAB) &&
                        <SearchFieldArticleForm handleOnclick={this.handleOnclick} text={text}
                                                handleOnChange={this.handleOnChange}
                                                className="active"
                                                handleKeyDown={this.handleKeyDown}
                                                handleOnClickClipboard={this.handleOnClickClipboard}
                                                errors={errors}
                                                loading={loading}
                                                disableSearch={disableSearchButton}
                        />}

                        {(!isTooManyRequestError && activeTab === FORM_TABS.IMAGE_FORM_TAB) &&
                        <SearchFieldImageForm className="active"
                                              handleUploadImage={this.handleUploadImage}
                                              handleUploadImageFromUrl={this.handleUploadImageFromUrl}
                                              handleOnClick={this.triggerImage}
                                              uploadedImage={uploadedImage}
                                              percentCompleted={percentCompleted}
                                              handleOnClickClipboard={this.handleImageOnClickClipboard}
                                              loading={loading}
                                              handleResetUploadImage={this.handleResetUploadImage}
                                              disableSearch={disableSearchButton}
                                              dispatch={this.props.dispatch}/>}

                        {isTooManyRequestError &&
                        <TooManyRequest handleRedirectKeywordButton={this.handleRedirectKeywordButton}
                                        handleRetryOnClick={this.handleRetryOnClick}/>
                        }
                    </div>
                </div>
            </section>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSearchParams: bindActionCreators(updateSearchParams, dispatch),
        updateSearchOptions: bindActionCreators(updateSearchOptions, dispatch),
        searchSubjects: bindActionCreators(searchSubjects, dispatch),
        triggerImportSubject: bindActionCreators(triggerImportSubject, dispatch),
        updateImportSubject: bindActionCreators(updateImportSubject, dispatch),
        getEmbed: bindActionCreators(getEmbed, dispatch),
        updateSearchTags: bindActionCreators(updateSearchTags, dispatch),
        uploadImage: bindActionCreators(uploadImage, dispatch),
        triggerImage: bindActionCreators(triggerImage, dispatch),
        updateImportImage: bindActionCreators(updateImportImage, dispatch),
        uploadImageFromUrl: bindActionCreators(uploadImageFromUrl, dispatch),
        setSearchButtonDisabled: bindActionCreators(setSearchButtonDisabled, dispatch),
        dispatch,
        setIsTooManyRequestError: bindActionCreators(setIsTooManyRequestError, dispatch),
        cleanSearchFields: bindActionCreators(cleanSearchFields, dispatch)
    };
};

const mapStateToProps = (state, ownProps) => {
    let text = state.searchParams.text ? state.searchParams.text : state.searchParams.url ? state.searchParams.url : '';
    let searchParamsFromUrl = RouteService.getSearchParamsInUrl(ownProps.router.location, ownProps.i18n);
    let _searchParams = state.searchParams;
    let currentRouteName = RouteService.getCurrentRouteName(ownProps.router.location, ownProps.i18n.language);

    const urlFromQuery = RouteService.getParameterByName("searchUrl");

    const {t} = ownProps;
    if (text === '' && searchParamsFromUrl && currentRouteName === t('routes.subject_route')) {
        _searchParams = {text: searchParamsFromUrl.replace(/_/g, ' '), url: ''};
    }

    if (urlFromQuery && (currentRouteName === t('routes.subject_detail_route') || currentRouteName === t('routes.import_status_route'))) {
        _searchParams = {text: '', url: urlFromQuery};
    }

    const {uploadedImage, triggeredImage} = state.importImage;
    const {recaptcha} = state.google;

    const {isTooManyRequestError} = state.errors;

    return {
        searchParams: _searchParams,
        importSubject: state.importSubject,
        searches: state.searches,
        subjects: state.subjects,
        uploadedImage,
        triggeredImage,
        importImage: state.importImage,
        isTooManyRequestError,
        recaptcha
    }
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SearchField)));
